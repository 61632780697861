import { Box, IconButton, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { ReactComponent as ArrowBack } from "assets/svg/back-btn-icon.svg";
import classNames from "classnames/bind";
import { forwardRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";

import styles from "./BackBtn.module.scss";

const BackBtn = styled(IconButton)((props) => {
  return {
    color: "#FFFFFF",
    marginBottom: props.mb || 0,
    marginTop: props.mt || 0,
    padding: 0,
  };
});
const StyledCustomization = forwardRef(
  (
    {
      link,
      children,
      onClick,
      replace = true,
      title,
      className,
      isPlain,
      state = {},
      ...rest
    },
    ref
  ) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useContext(LocalizationContext);
    const cx = classNames.bind();
    const isBig = title || isPlain;
    const isHistoryExist = location.key !== "default" || link;

    const handleClick = onClick
      ? onClick
      : (e) => {
          e.stopPropagation();
          navigate(link || -1, { replace: replace, state });
        };

    const isAlwaysPlain = !title && isPlain;

    const text = title ? (
      <Typography
        variant="h2"
        className={cx(styles.text, styles.title, isPlain && styles.hideDesktop)}
        style={{ color: theme.palette.backBtn.color }}
      >
        {title}
      </Typography>
    ) : (
      <Typography
        variant="body4"
        className={cx(
          styles.text,
          styles.textNoTitle,
          isPlain && styles.hideDesktop
        )}
        onClick={isHistoryExist ? handleClick : null}
        style={{ color: theme.palette.backBtn.color }}
      >
        {t("default.back")}
      </Typography>
    );

    if (!isHistoryExist) {
      return title ? text : null;
    }

    return (
      <Box className={cx(className, styles.wrap)} {...rest}>
        <BackBtn
          ref={ref}
          onClick={handleClick}
          className={cx(styles.btn, isBig && styles.btnBig)}
        >
          <ArrowBack className={styles.arrow} />
        </BackBtn>
        <Box
          component="span"
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          {isAlwaysPlain ? "" : text}
        </Box>
      </Box>
    );
  }
);

export default StyledCustomization;
