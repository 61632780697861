import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
// import { useLocation } from "react-router-dom";
import { fetchUserZendeskJwt } from "redux/usersSlice";
import { setZendeskToken } from "services/auth.service";

// const ZENDESK_AVAILABLE_PAGE = ["/settings/help"];

const zendeskKey = process.env.REACT_APP_ZENDESK_KEY;
const ZE_SCRIPT_EL_ID = "ze-snippet";

function loadScript(src, position, onLoaded) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("id", ZE_SCRIPT_EL_ID);
  script.src = src;
  script.addEventListener("load", onLoaded);
  position.appendChild(script);
}
const hideWidget = () => {
  window.zE("messenger", "close");
};

const loginUser = async (jwtToken) => {
  try {
    window.zE("messenger", "loginUser", function (callback) {
      callback(jwtToken);
    });
  } catch (e) {
    console.log("Cannot loogin into Zendesk", e);
  }
};
const hideWidgetButton = () => {
  if (window.zE) {
    // if (ZENDESK_AVAILABLE_PAGE.includes(location.pathname)) {
    window.zE("messenger", "show");
    window.zE("messenger", "close");
    window.zE("messenger:set", "zIndex", -99999);
    // } else {
    //   window.zE("messenger", "hide");
    //   window.zE("messenger", "close");
    //   window.zE("messenger:set", "zIndex", -99999);
    // }
  }
};

export default function ZendeskWidget({ onLoaded }) {
  const dispatch = useDispatch();
  const loaded = React.useRef(false);
  // let location = useLocation();

  useEffect(() => {
    if (typeof window !== "undefined" && !loaded.current) {
      if (!document.querySelector(ZE_SCRIPT_EL_ID)) {
        const initZendesk = () => {
          hideWidget();
          window.zE("messenger:set", "cookies", true);
          dispatch(fetchUserZendeskJwt())
            .unwrap()
            .then((data) => {
              setZendeskToken(data?.token);
              loginUser(data?.token);
              hideWidgetButton();
              !!onLoaded && onLoaded();
            })
            .catch((err) => {
              console.log("Cannot get Zendesk jwt", err);
            });
        };
        loadScript(
          `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`,
          document.head,
          initZendesk
        );
      }
      loaded.current = true;
    }
    return () => {
      loaded.current = false;
      var script = document.getElementById("ze-snippet");
      if (script != null && window.zE) {
        window.zE("messenger:set", "cookies", false);
        document.head.removeChild(script);
        let el = document.getElementById("launcher");
        if (el != null) {
          el.remove();
        }
      }
    };
  }, []);

  // useEffect(() => {
  //   if (!loaded.current) return;

  //   if (!isAuthenticated()) {
  //     disableZendesk();
  //   } else {
  //     enableZendesk();
  //   }
  // }, [isAuthenticated()]);

  // const disableZendesk = () => {
  //   if (document.querySelector("#ze-snippet")) {
  //     window.zE("messenger:set", "cookies", false);
  //   }
  // };

  // const enableZendesk = () => {
  //   if (typeof window !== "undefined" && loaded.current && window.zE) {
  //     if (document.querySelector("#ze-snippet")) {
  //       initZendesk();
  //     }
  //   }
  // };

  return <></>;
}
