import { BUY_EDITION, errorResponseMsgs, errorResponseTypes } from "constants/";
import { useContext } from "react";

import { LocalizationContext } from "./localizationContext";

const printMessage = (e: String) => {
  return e.replace("_", " ");
};
export const errorResponseMessages = (e: any, t: any) => {
  if (e.code) {
    switch (e.code) {
      case errorResponseTypes.LimitExceededException:
        if (e.message === errorResponseMsgs.LimitExceededTryLater) {
          return t("auth.attemptLimitExceededTryLater");
        }
        return t("auth.smsLimitExceeded");
      case errorResponseTypes.UsernameExistsException:
        return t("auth.userExists");
      case errorResponseTypes.AliasExistsException:
        return t("auth.userExists");
      case errorResponseTypes.CodeMismatchException:
      case errorResponseTypes.CodeExpiredException:
        if (e.message === errorResponseMsgs.InvalidVerificationCode) {
          return t("auth.invalidVerificationCode");
        }
        return t("auth.verificationCodeInvalid");
      case errorResponseTypes.UserNotFoundException:
      case errorResponseTypes.InvalidPasswordException:
      case errorResponseTypes.NotAuthorizedException:
        if (e.message === errorResponseMsgs.PasswordAttemptsExceeded) {
          return t("auth.passwordAttemptsExceeded");
        }
        if (e.message === errorResponseMsgs.IncorrectUsernameOrPassword) {
          return t("error.incorrectUsernameOrPassword");
        }
        if (e.message === errorResponseMsgs.PasswordExpired) {
          return t("error.passwordExpired");
        }
        return t("auth.invalidCredentials");
      case errorResponseTypes.SessionExpiredException:
        return t("auth.sessionExpired");
      case errorResponseTypes.SessionUnavailableOfflineException:
        return t("auth.offline");
      case errorResponseTypes.SignedOutException:
        return t("auth.signedOut");
      case errorResponseTypes.UserNotConfirmedException:
        return t("auth.unconfirmed");
      default:
        return e.message || t("auth.unknownError");
    }
  }

  const errorCode = e?.response?.data?.errorDetails?.code;
  if (errorCode) {
    switch (errorCode) {
      case errorResponseTypes.IncorrectPaymentMethodForZoopBalanceFund:
        return t("payments.incorrectPaymentMethodForZoopBalanceFund");
      default:
        return (
          e?.response?.data?.errorDetails?.message || t("error.unknownError")
        );
    }
  }

  if (e?.error) {
    switch (e.error) {
      case errorResponseTypes.RequestIsOpen:
        return t("edition.requestIsOpen");
      case errorResponseTypes.Exception:
        return e.message || t("error.unknownError");
      case errorResponseTypes.Error:
        return e.message || t("error.unknownError");
      default:
        return e.message || t("error.unknownError");
    }
  }

  if (e?.response?.data?.validationErrors) {
    const errors = e.response?.data?.validationErrors;
    const values = Object.values(errors);
    const res = [];
    values.forEach((item) => {
      Array.isArray(item) &&
        item.forEach((element) => {
          res.push(element);
        });
    });
    return printMessage(res[0]);
  } else {
    return printMessage(e.message || t("auth.unknownError"));
  }
};

export const TradingErrorResponseMessages = () => {
  const { t }: any = useContext(LocalizationContext);
  const getResult = (e: any, tradeType: String) => {
    const errorCode = e?.code || e?.errorDetails?.code || e?.error;
    switch (errorCode) {
      case errorResponseTypes.InDroppingProcess:
        return {
          title: t("error.inDroppingProcessTitle"),
          subtitle: t("error.inDroppingProcessDesc"),
        };
      case errorResponseTypes.ZoopBalanceInsufficientFunds:
        return {
          title: t("trading.finalizePurchaseErrorTitle"),
          subtitle: t("trading.sufficientFundsError"),
          actions: "AddFundsDlgActions",
        };
      case errorResponseTypes.NoEditionsLeft:
        if (tradeType === BUY_EDITION) {
          return {
            title: t("error.outOfCardTitle"),
            subtitle: t("error.outOfCardDescription"),
          };
        }
        return {
          title: t("trading.dang"),
          subtitle: t("trading.errorSoldOut"),
        };
      default:
        return {
          title: t("error.error"),
          subtitle:
            e?.errorDetails?.message || e.message || t("error.unknownError"),
        };
    }
  };

  return getResult;
};
