import { Box, Typography } from "@mui/material";
import classNames from "classnames/bind";
import React from "react";
import { useBreakpoint } from "utils/hooks";

import styles from "./ReactionItem.module.scss";

export const ReactionItem = ({
  data,
  selectedReaction,
  handleSelect,
  notLast,
  openPopover,
  isShorten,
}) => {
  const isSelectedCurrent = selectedReaction.find(
    (reaction) => reaction?.name === data.name
  );
  const isSm = useBreakpoint("sm");

  const toggleReaction = (e) => {
    isSm ? openPopover(e) : handleSelect(e, data, isSelectedCurrent);
  };
  const cx = classNames.bind(styles);

  if (!data?.count) {
    return null;
  }

  return (
    <Box
      className={cx(
        "reactionItem",
        isSelectedCurrent && "reactionItemActive",
        notLast && "reactionItemNotLast",
        isShorten && "reactionItemShorten"
      )}
      onClick={toggleReaction}
    >
      <img src={data.imageUrl} alt={data.name} />
      <Typography
        variant="body5"
        component="span"
        className={styles.reactionItemCount}
      >
        {data.count}
      </Typography>
    </Box>
  );
};
