import { ReactComponent as LogoDark } from "assets/svg/logo.svg";
import { ReactComponent as LogoLight } from "assets/svg/logo-light.svg";
import { useSelector } from "react-redux";
import { getTheme } from "redux/selectors/appSelectors";
export const Logo = () => {
  const activeTheme = useSelector(getTheme);

  const logos = {
    dark: <LogoDark />,
    light: <LogoLight />,
  };

  return logos[activeTheme];
};
