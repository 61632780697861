import { Box, Skeleton } from "@mui/material";
import { ChatWidget } from "components/ChatWidget/ChatWidget";
import { REWARD_STATUS, REWARDS_ACTION_TYPE } from "constants/index";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "redux/appSlice";
import { fetchCard, updateCardTokens } from "redux/cardSlice";
import { fetchDrop } from "redux/dropsSlice";
import {
  getCardSelectorId,
  getCardTokens,
} from "redux/selectors/cardSelectors";
import { postUnlockReward } from "services/api/rewardsApi";
import { LocalizationContext } from "services/localizationContext";

import styles from "./CardRewards.module.scss";
import { CardRewardItem } from "./components/CardRewardItem/CardRewardItem";
import { HiddenContent } from "./components/HiddenContent/HiddenContent";
import { RewardModal } from "./components/RewardModal/RewardModal";

export const CardRewards = ({
  rewardType,
  media,
  isLoading,
  mediaSummary,
  holdings,
  influencer,
  marketTokens,
  data,
}) => {
  const { t } = useContext(LocalizationContext);
  const cardId = useSelector(getCardSelectorId);
  const dispatch = useDispatch();
  const cardTokens = useSelector(getCardTokens);

  const [isUnlockModal, setUnlockModal] = useState(false);
  const [rewardModalLoading, setRewardModalLoading] = useState(false);
  const [rewardError, setRewardError] = useState(null);
  const [currentToken, setCurrentToken] = useState(null);
  const tokensCount = cardTokens?.tokens?.length;
  const availableLockedToken = cardTokens?.tokens?.find(
    (item) => item.rewardStatus === REWARD_STATUS.LOCKED
  );

  const unlockedToken = cardTokens?.tokens?.filter(
    (item) => item.rewardStatus === REWARD_STATUS.UNLOCKED
  )[0];

  const unlockedTokenWithReward = cardTokens?.tokens
    ?.filter((item) => item.rewardStatus === REWARD_STATUS.UNLOCKED)
    .find((item) => item.rewardOrder?.status);

  const unlockModalHandler = (token) => {
    setUnlockModal(true);
    if (token) {
      setCurrentToken(token);
    }
  };

  const postUnlockRewardHandler = () => {
    setRewardModalLoading(true);
    postUnlockReward({
      cardId,
      serial: currentToken.tokenSerial,
    })
      .then((res) => {
        dispatch(fetchDrop(cardId, true));
        dispatch(fetchCard({ id: cardId }));
        const newData = JSON.parse(JSON.stringify(cardTokens));
        const tokenIndex = newData?.tokens.findIndex(
          (item) => +item.id === +currentToken.id
        );
        if (newData?.tokens[tokenIndex]) {
          newData.tokens[tokenIndex].rewardStatus = res.data.status;
          newData.tokens[tokenIndex].rewardOrder = res.data.rewardOrder;
          dispatch(updateCardTokens(newData));
        }
        dispatch(
          setSnackbar({
            open: true,
            message: t("drops.unlockedSuccess"),
          })
        );
        setUnlockModal(false);
      })
      .catch((err) => {
        setRewardError(err?.data);
      })
      .finally(() => {
        setRewardModalLoading(false);
        setCurrentToken(null);
      });
  };

  const isMultipleRewards =
    (rewardType === REWARDS_ACTION_TYPE.FOLLOW_BACK ||
      rewardType === REWARDS_ACTION_TYPE.VIDEO_CALL) &&
    tokensCount > 1;

  const callDuration = data?.duration || 15; // 15 for old cards

  if (!isLoading && !rewardType) {
    return <></>;
  }

  if (isLoading) {
    return <Skeleton variant="rectangular" className={styles.skeleton} />;
  }

  return (
    <Box
      className={`${styles.wrapper} ${
        isMultipleRewards ? styles.wrapperMultiple : ""
      }`}
    >
      {rewardType === REWARDS_ACTION_TYPE.HIDDEN_CONTENT ? (
        <HiddenContent
          media={media}
          mediaSummary={mediaSummary}
          holdings={holdings}
          unlockModalHandler={() => unlockModalHandler(availableLockedToken)}
          cardId={cardId}
          cardTokens={cardTokens}
          marketTokens={marketTokens}
          selectedToken={
            unlockedTokenWithReward || availableLockedToken || unlockedToken
          }
        />
      ) : null}
      {rewardType === REWARDS_ACTION_TYPE.PREMIUM_CHAT ? (
        <ChatWidget
          holdings={holdings}
          unlockModalHandler={() => unlockModalHandler(availableLockedToken)}
          selectedToken={
            unlockedTokenWithReward || availableLockedToken || unlockedToken
          }
          influencer={influencer}
          cardId={cardId}
          isStatic={true}
          title={t("default.premiumChat")}
          rewardType={rewardType}
          marketTokens={marketTokens}
        />
      ) : null}
      {(rewardType === REWARDS_ACTION_TYPE.FOLLOW_BACK ||
        rewardType === REWARDS_ACTION_TYPE.VIDEO_CALL) &&
      (tokensCount === 1 || !tokensCount) ? (
        <ChatWidget
          holdings={holdings}
          unlockModalHandler={() => unlockModalHandler(availableLockedToken)}
          selectedToken={unlockedToken || availableLockedToken}
          influencer={influencer}
          cardId={cardId}
          title={
            rewardType === REWARDS_ACTION_TYPE.FOLLOW_BACK
              ? t("default.followBack")
              : rewardType === REWARDS_ACTION_TYPE.VIDEO_CALL
              ? t("default.privateVideoCall", { value: callDuration })
              : null
          }
          rewardType={rewardType}
          marketTokens={marketTokens}
          duration={callDuration}
        />
      ) : (rewardType === REWARDS_ACTION_TYPE.FOLLOW_BACK ||
          rewardType === REWARDS_ACTION_TYPE.VIDEO_CALL) &&
        tokensCount > 1 ? (
        <Box className={styles.rewards}>
          {cardTokens.tokens.map((item) => (
            <CardRewardItem
              {...item}
              influencer={influencer}
              cardId={cardId}
              holdings={holdings}
              cardTokens={cardTokens}
              rewardType={rewardType}
              unlockModalHandler={() => unlockModalHandler(item)}
              duration={callDuration}
            />
          ))}
        </Box>
      ) : null}
      <RewardModal
        open={isUnlockModal}
        setOpen={setUnlockModal}
        submit={postUnlockRewardHandler}
        loading={rewardModalLoading}
        error={rewardError}
        influencer={influencer}
      />
    </Box>
  );
};
