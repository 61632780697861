import { Box, Button, Skeleton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DOMPurify from "dompurify";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { fetchBanners } from "redux/bannersSlice";
import { getBanners, getBannersLoader } from "redux/selectors/bannersSelectors";
import { getUserLocale } from "utils/langHelper";

import styles from "./BannersCarousel.module.scss";

const BtnIcon = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.2078 7.46516L7.85701 0.217369C7.79291 0.16211 7.71112 0.131165 7.62492 0.131165H5.66875C5.50518 0.131165 5.43003 0.334518 5.55381 0.440616L13.2945 7.16013H0.359446C0.26219 7.16013 0.182617 7.2397 0.182617 7.33696V8.66318C0.182617 8.76043 0.26219 8.84001 0.359446 8.84001H13.2923L5.5516 15.5595C5.42782 15.6678 5.50297 15.869 5.66654 15.869H7.68902C7.73102 15.869 7.77301 15.8535 7.80396 15.8248L16.2078 8.53498C16.2843 8.46844 16.3456 8.38625 16.3877 8.29397C16.4297 8.2017 16.4515 8.10147 16.4515 8.00007C16.4515 7.89866 16.4297 7.79844 16.3877 7.70616C16.3456 7.61388 16.2843 7.5317 16.2078 7.46516Z"
      fill="#111321"
    />
  </svg>
);

const formatData = (data, lang, isMd) => {
  return data
    .map((banner) => {
      const mobileContent = banner.contentItems.find(
        (content) =>
          content.platform === "mobile" && content.languageCode === lang
      );
      const desktopContent = banner.contentItems.find(
        (content) =>
          content.platform === "desktop" && content.languageCode === lang
      );

      return {
        name: banner.name,
        path: banner.path,
        mobile: mobileContent
          ? {
              headline: mobileContent.headline,
              body: mobileContent.body,
              cta: mobileContent.cta,
              src: banner.srcMobile,
              completed: true,
            }
          : {
              src: banner.srcMobile,
              completed: false,
            },
        desktop: desktopContent
          ? {
              headline: desktopContent.headline,
              body: desktopContent.body,
              cta: desktopContent.cta,
              src: banner.src,
              completed: true,
            }
          : {
              src: banner.src,
              completed: false,
            },
      };
    })
    .filter((banner) => {
      if (isMd) {
        return banner.desktop.completed || banner.mobile.completed;
      }
      return banner.desktop.completed;
    });
};

export const BannersCarousel = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigation = useNavigate();
  const slider = useRef(null);
  const userLang = useSelector((state) => state.users.entities?.language);
  const banners = useSelector(getBanners);
  const isLoading = useSelector(getBannersLoader);
  const localeLang = getUserLocale();

  const selectedLang =
    localStorage.getItem("selected-language") || userLang || localeLang;
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const fomattedData = formatData(banners, selectedLang, isMd);

  const sliderSettings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  const getImagePath = (mobile, desktop) => {
    if (isMd && mobile.completed && mobile.src) {
      return mobile.src;
    }
    if (isMd && mobile.completed && !mobile.src) {
      return desktop.src;
    }
    if (!isMd && desktop.completed) {
      return desktop.src;
    }
    if (isMd && mobile.src) {
      return mobile.src;
    }
    if (isMd && !mobile.completed && desktop.completed) {
      return desktop.src;
    }
    return undefined;
  };

  useEffect(() => {
    if (!banners?.length) {
      dispatch(fetchBanners());
    }
  }, []);

  if (isLoading) {
    return (
      <Box className={styles.slider}>
        <Skeleton variant="rectangular" className={styles.slide} />
      </Box>
    );
  }
  return (
    <Slider ref={slider} {...sliderSettings} className={styles.slider}>
      {fomattedData.map(({ path, name, id, mobile, desktop }) => (
        <Box className={`${styles.slide} ${name}`} key={id}>
          <img src={getImagePath(mobile, desktop)} alt="banner" />
          <Box className={styles.slideTextContainer}>
            {isMd && mobile.completed ? (
              <>
                <Typography
                  className={styles.slideTitle}
                  variant="h2_32"
                  component="h2"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      mobile?.headline.replace(/\n/g, "<br/>"),
                      {
                        USE_PROFILES: { html: true },
                      }
                    ),
                  }}
                  style={{ color: theme.palette.secondary.main }}
                />
                <Typography
                  className={styles.slideDescription}
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      mobile?.body.replace(/\n/g, "<br/>"),
                      {
                        USE_PROFILES: { html: true },
                      }
                    ),
                  }}
                  style={{ color: theme.palette.secondary.main }}
                />
                {path ? (
                  <Box className={styles.slideActions}>
                    <Button
                      variant="secondary"
                      onClick={() => navigation(path)}
                    >
                      <Typography
                        variant="h5_medium"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            mobile?.cta.replace(/\n/g, "<br/>"),
                            {
                              USE_PROFILES: { html: true },
                            }
                          ),
                        }}
                      />
                      <BtnIcon />
                    </Button>
                  </Box>
                ) : null}
              </>
            ) : (
              <>
                <Typography
                  className={styles.slideTitle}
                  variant="h2_32"
                  component="h2"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      desktop?.headline.replace(/\n/g, "<br/>"),
                      {
                        USE_PROFILES: { html: true },
                      }
                    ),
                  }}
                  style={{ color: theme.palette.secondary.main }}
                />
                <Typography
                  className={styles.slideDescription}
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      desktop?.body.replace(/\n/g, "<br/>"),
                      {
                        USE_PROFILES: { html: true },
                      }
                    ),
                  }}
                  style={{ color: theme.palette.secondary.main }}
                />
                {path ? (
                  <Box className={styles.slideActions}>
                    <Button
                      variant="secondary"
                      onClick={() => navigation(path)}
                    >
                      <Typography
                        variant="h5_medium"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            desktop?.cta.replace(/\n/g, "<br/>"),
                            {
                              USE_PROFILES: { html: true },
                            }
                          ),
                        }}
                      />
                      <BtnIcon />
                    </Button>
                  </Box>
                ) : null}
              </>
            )}
          </Box>
        </Box>
      ))}
    </Slider>
  );
};
