import { Box, Button } from "@mui/material";
import { ReactComponent as ExitFullScreen } from "assets/svg/exit-full-screen-icon.svg";
import { ReactComponent as FullScreen } from "assets/svg/full-screen-icon.svg";
import { ReactComponent as PauseIcon } from "assets/svg/pause-icon.svg";
import { ReactComponent as PlayBigIcon } from "assets/svg/play-big-icon.svg";
import { ReactComponent as PlayIcon } from "assets/svg/play-icon.svg";
import { ReactComponent as VolumeOff } from "assets/svg/volume-off-icon.svg";
import { ReactComponent as VolumeOn } from "assets/svg/volume-on-icon.svg";
import classNames from "classnames/bind";
import React, { forwardRef, useEffect, useRef, useState } from "react";

import styles from "./VideoPlayer.module.scss";

let volumeValue = 0; // Default volume is 0%

export const VideoPlayer = forwardRef(({ src, poster, loop, muted }, ref) => {
  const containerRef = useRef(null); // Reference for the full-screen container
  const playerControlsRef = useRef(null);

  const isPlaying = !ref.current?.paused;

  const [progress, setProgress] = useState(0);
  const [volume, setVolume] = useState(volumeValue);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const togglePlayPause = () => {
    const video = ref.current;
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
    }
  };

  const toggleVolume = () => {
    if (ref.current) {
      if (ref.current.muted || volume === 0) {
        // Restore volume to the last known value or a default if none exists
        const restoredVolume = volumeValue || 0.5;
        setVolume(restoredVolume);
        ref.current.volume = restoredVolume;
        ref.current.muted = false;
      } else {
        // Save current volume and mute
        volumeValue = ref.current.volume;
        setVolume(0);
        ref.current.volume = 0;
        ref.current.muted = true;
      }
    }
  };

  const handleVolumeChange = (e) => {
    const newVolume = +e.target.value;
    setVolume(newVolume);
    if (ref.current) {
      ref.current.volume = newVolume;
      ref.current.muted = newVolume === 0; // Mute if volume is zero, otherwise unmute
    }
  };

  const handleTimeUpdate = () => {
    const video = ref.current;
    if (video) {
      setProgress((video.currentTime / video.duration) * 100);
    }
  };

  const handleProgressChange = (e) => {
    const video = ref.current;
    if (video) {
      const newTime = (e.target.value / 100) * video.duration;
      video.currentTime = newTime;
      setProgress(e.target.value);
    }
  };

  function formatTime(seconds) {
    // Ensure the input is a valid number
    if (isNaN(seconds) || seconds < 0) {
      throw new Error("Invalid input: seconds must be a non-negative number.");
    }

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    // Format each unit to be two digits
    const formatUnit = (unit) => unit.toString().padStart(2, "0");

    return `${hours > 0 ? formatUnit(hours) + ":" : ""}${formatUnit(
      minutes
    )}:${formatUnit(remainingSeconds)}`;
  }

  const toggleFullScreen = () => {
    const container = containerRef.current;
    const video = ref.current;

    if (!container || !video) return;

    // Check if the browser supports standard fullscreen API
    if (document.fullscreenElement || document.webkitFullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    } else {
      // iOS Safari-specific handling
      if (video.webkitEnterFullscreen) {
        video.webkitEnterFullscreen();
      } else if (container.requestFullscreen) {
        container.requestFullscreen();
      } else if (container.webkitRequestFullscreen) {
        container.webkitRequestFullscreen();
      }
    }
  };

  const currentTime = ref.current?.currentTime || 0;
  const duration = ref.current?.duration || 0;
  const currentTimeFormatted = formatTime(currentTime);
  const durationRoundFormatted = formatTime(duration);

  useEffect(() => {
    const handleFullscreenChange = () => {
      // Check for fullscreen state using cross-browser methods
      const isInFullScreen =
        !!document.fullscreenElement || // Standard
        !!document.webkitFullscreenElement; // Safari

      setIsFullScreen(isInFullScreen);
    };

    // Add event listeners for fullscreen changes
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange); // Safari

    return () => {
      // Cleanup event listeners
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
    };
  }, []);

  useEffect(() => {
    const video = ref.current;
    const playerControls = playerControlsRef.current;

    if (video && playerControls) {
      const resizeObserver = new ResizeObserver(() => {
        playerControls.style.width = `${video.offsetWidth}px`;
      });

      resizeObserver.observe(video);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  const playerClass = classNames(styles.player, {
    [styles.playerFullScreen]: isFullScreen,
  });

  return (
    <Box className={playerClass} ref={containerRef}>
      <video
        ref={ref}
        src={src}
        poster={poster}
        onTimeUpdate={handleTimeUpdate}
        muted={muted}
        loop={loop}
        onClick={togglePlayPause}
        playsInline
      />
      {!isPlaying && progress < 0.1 ? (
        <Button className={styles.playerBigPlay}>
          <PlayBigIcon />
        </Button>
      ) : null}
      <Box
        className={styles.playerControls}
        ref={playerControlsRef}
        style={{ width: ref.current?.offsetWidth || "100%" }}
      >
        <Button onClick={togglePlayPause} className={styles.playerBtn}>
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </Button>
        <Box className={styles.volume}>
          <Button className={styles.playerBtn} onClick={toggleVolume}>
            {ref.current?.muted ? <VolumeOff /> : <VolumeOn />}
          </Button>
          <Box className={styles.rangeWrapper}>
            <Box
              className={styles.progress}
              style={{ width: `${volume * 100}%` }}
            />
            <input
              type="range"
              className="volume-slider"
              min="0"
              max="1"
              step="0.1"
              value={volume}
              onChange={handleVolumeChange}
            />
          </Box>
        </Box>
        <Button
          className={styles.playerProgressValue}
        >{`${currentTimeFormatted} / ${durationRoundFormatted}`}</Button>
        <Box className={`${styles.rangeWrapper} ${styles.rangeWrapperMain}`}>
          <Box className={styles.progress} style={{ width: `${progress}%` }} />
          <input
            type="range"
            className={styles.playerProgress}
            value={progress}
            onChange={handleProgressChange}
          />
        </Box>
        <Button onClick={toggleFullScreen} className={styles.playerBtn}>
          {isFullScreen ? <ExitFullScreen /> : <FullScreen />}
        </Button>
      </Box>
    </Box>
  );
});
