import { Box } from "@mui/system";
import classNames from "classnames/bind";
import styles from "components/NewCard/Card.module.scss";

import { Price } from "./Price/Price";

export const LiveDrop = ({
  className,
  currencySymbol,
  priceMovement,
  price,
}) => {
  const cx = classNames.bind(styles);

  return (
    <Box className={cx(className, styles.liveDrop)}>
      <Price
        currencySymbol={currencySymbol}
        priceMovement={priceMovement}
        price={price}
      />
    </Box>
  );
};
