import { useTheme } from "@emotion/react";
import { Box, Button, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as DeliveredIcon } from "assets/svg/checked-icon-green.svg";
import { ReactComponent as LockedIcon } from "assets/svg/locked-icon.svg";
import { ReactComponent as UnlockedIcon } from "assets/svg/unlocked-icon.svg";
import {
  EDITION_STATUSES,
  PATHS,
  REWARD_ORDER_STATUS,
  REWARD_STATUS,
  REWARDS_ACTION_TYPE,
} from "constants/index";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";

import { getCardStatusSelector } from "../../redux/selectors/cardSelectors";
import styles from "./RewardHeader.module.scss";

export const RewardHeader = ({
  children,
  holdings,
  selectedToken,
  unlockModalHandler,
  title,
  type,
  marketTokens,
  cardId,
}) => {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const cardStatus = useSelector(getCardStatusSelector);

  const isMarketTokenAvailable = marketTokens?.tokens?.find(
    (item) => item.rewardStatus === REWARD_STATUS.LOCKED
  );

  const isMarketLock =
    isMarketTokenAvailable &&
    !holdings &&
    cardStatus === EDITION_STATUSES.complete;

  const isMarketUnLock =
    !isMarketTokenAvailable &&
    marketTokens?.tokens?.length &&
    !holdings &&
    cardStatus === EDITION_STATUSES.complete;

  const isEmpty =
    cardStatus === EDITION_STATUSES.complete &&
    !holdings &&
    !marketTokens?.tokens?.length;

  const isUnlocked = selectedToken?.rewardStatus === REWARD_STATUS.UNLOCKED;
  const isLocked = selectedToken?.rewardStatus === REWARD_STATUS.LOCKED;

  const isDelivered =
    selectedToken?.rewardOrder?.status === REWARD_ORDER_STATUS.DELIVERED;
  const navigate = useNavigate();
  const clickHandler = (e) => {
    if (!holdings || (holdings && !selectedToken)) {
      navigate(PATHS.DROPS_ID_BUY.replace(":id", cardId));
    } else if (holdings && isLocked) {
      unlockModalHandler(e);
    }
  };

  return (
    <Box>
      <Box
        className={`${styles.header} ${
          isSm && type !== REWARDS_ACTION_TYPE.HIDDEN_CONTENT
            ? styles.headerCenter
            : ""
        }`}
        onClick={clickHandler}
      >
        <Box
          className={`${styles.headerMain} ${
            isSm && type !== REWARDS_ACTION_TYPE.HIDDEN_CONTENT
              ? styles.headerMainColumn
              : ""
          }`}
        >
          <Typography variant="textMediumBold2" className={styles.title}>
            {title}
          </Typography>
          {isSm && type !== REWARDS_ACTION_TYPE.HIDDEN_CONTENT ? (
            <>
              {isEmpty ? null : isDelivered && isUnlocked ? (
                <Button>
                  <Typography variant="body2" className="opacity_07">
                    {t("default.rewardDelivered")}
                  </Typography>
                </Button>
              ) : isMarketLock ? (
                <Button className={styles.lockButtonPrevent}>
                  <Typography variant="body2" className="opacity_07">
                    {t("default.cardRewardLocked")}
                  </Typography>
                </Button>
              ) : isMarketUnLock ? (
                <Button className={styles.lockButtonPrevent}>
                  <Typography variant="body2" className="opacity_07">
                    {t("default.allCardRewardUnlocked")}
                  </Typography>
                </Button>
              ) : !holdings || (holdings && !selectedToken) ? (
                <Button>
                  <Typography variant="body2" className="opacity_07">
                    {t("default.buyToUnlock")}
                  </Typography>
                </Button>
              ) : holdings && isLocked ? (
                <Button>
                  <Typography variant="body2" className="opacity_07">
                    {t("default.clickToUnlock")}
                  </Typography>
                </Button>
              ) : holdings && isUnlocked && selectedToken?.rewardOrder?.id ? (
                <Button className={styles.lockButtonPrevent}>
                  <Typography variant="body2" className="opacity_07">
                    {t("default.rewardUnlocked")}
                  </Typography>
                </Button>
              ) : (
                <Button className={styles.lockButtonPrevent}>
                  <Typography variant="body2" className="opacity_07">
                    {t("default.cardRewardUnlocked")}
                  </Typography>
                </Button>
              )}
            </>
          ) : null}
          {!isSm ? <Box className={styles.headerInfo}>{children}</Box> : null}
        </Box>
        {type === REWARDS_ACTION_TYPE.HIDDEN_CONTENT || !isSm ? (
          <>
            {isEmpty ? null : isDelivered && isUnlocked ? (
              <Button className={styles.lockButton}>
                <Typography variant="body5" className={styles.lockButtonText}>
                  {t("default.rewardDelivered")}
                </Typography>
                <DeliveredIcon />
              </Button>
            ) : isMarketLock ? (
              <Button
                className={`${styles.lockButton} ${styles.lockButtonPrevent}`}
              >
                <Typography variant="body5" className={styles.lockButtonText}>
                  {t("default.cardRewardLocked")}
                </Typography>
                <LockedIcon />
              </Button>
            ) : isMarketUnLock ? (
              <Button
                className={`${styles.lockButton} ${styles.lockButtonPrevent}`}
              >
                <Typography variant="body5" className={styles.lockButtonText}>
                  {t("default.allCardRewardUnlocked")}
                </Typography>
                <UnlockedIcon />
              </Button>
            ) : !holdings || (holdings && !selectedToken) ? (
              <Button className={styles.lockButton}>
                <Typography variant="body5" className={styles.lockButtonText}>
                  {t("default.buyToUnlock")}
                </Typography>
                <LockedIcon />
              </Button>
            ) : holdings && isLocked ? (
              <Button className={styles.lockButton}>
                <Typography variant="body5" className={styles.lockButtonText}>
                  {t("default.clickToUnlock")}
                </Typography>
                <LockedIcon />
              </Button>
            ) : holdings && isUnlocked && selectedToken?.rewardOrder?.id ? (
              <Button
                className={`${styles.lockButton} ${styles.lockButtonPrevent}`}
              >
                <Typography variant="body5" className={styles.lockButtonText}>
                  {t("default.rewardUnlocked")}
                </Typography>
                <UnlockedIcon />
              </Button>
            ) : (
              <Button
                className={`${styles.lockButton} ${styles.lockButtonPrevent}`}
              >
                <Typography variant="body5" className={styles.lockButtonText}>
                  {t("default.cardRewardUnlocked")}
                </Typography>
                <UnlockedIcon />
              </Button>
            )}
          </>
        ) : (
          <>
            {isEmpty ? null : isDelivered && isUnlocked ? (
              <DeliveredIcon />
            ) : isMarketLock ? (
              <LockedIcon />
            ) : isMarketUnLock ? (
              <UnlockedIcon />
            ) : !holdings || (holdings && !selectedToken) ? (
              <LockedIcon />
            ) : holdings && isLocked ? (
              <LockedIcon />
            ) : holdings && isUnlocked && selectedToken?.rewardOrder?.id ? (
              <UnlockedIcon />
            ) : (
              <UnlockedIcon />
            )}
          </>
        )}
      </Box>
      {isSm ? <Box className={styles.headerInfo}>{children}</Box> : null}
    </Box>
  );
};
