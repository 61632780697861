import "styles/slider/index.scss";

import { useTheme } from "@emotion/react";
import { Box, Button, Skeleton, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DescriptionCardHeader } from "components/DescriptionCard/components/DescriptionCardHeader";
import { DescriptionCard } from "components/DescriptionCard/DescriptionCard";
import { SeasonSlider } from "components/SeasonSlider/SeasonSlider";
import { CHANNELS, EVENTS } from "constants/index";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateCardTokens } from "redux/cardSlice";
import { fetchEditionData } from "redux/editionSlice";
import { getCardSelector } from "redux/selectors/cardSelectors";
import { fetchUserEditions } from "redux/usersSlice";
//TODO::commented code related to Barter
// import { getEditionOwners } from "services/api/publicApi";
import { LocalizationContext } from "services/localizationContext";
import PusherService from "services/Pusher.service";
import { getUserCard } from "services/userApi";
import { formatEditionOrders } from "utils";

import { CARD_TYPES } from "../../constants/card";
// import BarterEdition from "./BarterEdition";
import styles from "./edition.module.scss";
import CardSeason from "./Season";

const Edition = ({ status }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const influencer = status?.card?.influencer;
  const [currencySymbol, setCurrensySymbol] = useState(null);
  const [seasonNum, setSeasonNum] = useState(null);
  const [season, setSeason] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const editionId = +params.id;
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("editionMobile"));
  const isInCollection = status?.assetHoldings && status?.assetHoldings > 0;

  const card = useSelector(getCardSelector);
  const unmounted = useRef(false);

  useEffect(() => {
    const channelCards = PusherService.subscribe(CHANNELS.CARDS);
    channelCards.bind(EVENTS.PRICE_CHANGED, (data) => {
      dispatch(fetchUserEditions());
    });
    channelCards.bind(EVENTS.BUY, (data) => {
      dispatch(fetchUserEditions());
    });
  }, []);

  useEffect(() => {
    if (!editionId) return;
    setIsLoading(true);
    dispatch(fetchEditionData(editionId))
      .unwrap()
      .then((d) => {
        if (unmounted.current) return;
        setSeasonNum(d.seasonNum);
        setCurrensySymbol(d?.influencer?.currencySymbol);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        !unmounted.current && setIsLoading(false);
      });

    return () => {
      unmounted.current = true;
    };
  }, [editionId]);

  useEffect(() => {
    const getMyCardData = async () => {
      try {
        const res = await getUserCard({ params: { cardId: editionId } });
        const formatRes = formatEditionOrders(
          res?.data,
          status?.card?.quantityAllocated
        );
        dispatch(updateCardTokens(formatRes));
      } catch (e) {
        console.error(e);
      }
    };
    isInCollection ? getMyCardData() : dispatch(updateCardTokens(null));
  }, [isInCollection, editionId]);

  const MainInfo = () => {
    return !status?.card ? (
      <Skeleton
        className={`${styles.mainInfo} ${styles.skeletonBg}`}
        variant="rectangular"
      />
    ) : (
      <Box className={styles.mainInfo}>
        <img
          className={styles.mainImage}
          src={status?.card?.imageUrl}
          alt={influencer?.name}
        />
      </Box>
    );
  };

  const descriptionEl = (
    <DescriptionCard
      className={styles.seasonDescription}
      title={season?.title || card?.name}
      {...card}
      currencySymbol={currencySymbol}
      latestPrice={status?.card?.latestPrice}
      isMobile={isMobile}
    />
  );
  return (
    <>
      <Box className={styles.container}>
        <>
          <Box className={styles.content}>
            {isMobile ? (
              <DescriptionCardHeader
                title={card.name}
                seasonNum={seasonNum}
                influencer={influencer}
                className={styles.cardHeader}
                isMobile={true}
              />
            ) : null}
            <Box className={styles.leftSide}>
              {<MainInfo />}
              {!status?.card || isLoading ? (
                <Skeleton
                  className={`${styles.buttonsBlock} ${styles.skeletonBg}`}
                  variant="rectangular"
                  sx={{
                    height: 54,
                  }}
                />
              ) : (
                <Box className={styles.buttonsBlock}>
                  <Button
                    variant="outlined"
                    disabled={true}
                    className={styles.buttonsBlockBtn}
                  >
                    {t("edition.soldOut")}
                  </Button>
                </Box>
              )}
            </Box>
            <Box className={styles.rightSide}>
              {card?.type === CARD_TYPES.CUSTOM ? (
                <>
                  {descriptionEl}
                  {card.otherCards?.length ? (
                    <Box className={styles.usersSwapBlock}>
                      <Typography
                        variant="h5_medium"
                        className={styles.title}
                        component="h2"
                      >
                        {`${t("drops.otherCardsFrom")} ${card.influencer.name}`}
                      </Typography>
                      <SeasonSlider
                        seasonCards={card.otherCards}
                        cardProps={{ description: null }}
                      />
                    </Box>
                  ) : null}
                </>
              ) : null}
              {card?.type !== CARD_TYPES.CUSTOM ? (
                <>
                  <CardSeason
                    seasonNum={seasonNum}
                    editionId={editionId}
                    influencer={influencer}
                    currencySymbol={currencySymbol}
                    latestPrice={status?.card?.latestPrice}
                    isMobile={isMobile}
                    onGetSeason={(val) => setSeason(val)}
                  />
                </>
              ) : null}
              {/*{!isInCollection ? (*/}
              {/*  <BarterEdition*/}
              {/*    editionId={editionId}*/}
              {/*    holders={editionOwners}*/}
              {/*    isLoading={isOwnersLoading}*/}
              {/*  />*/}
              {/*) : null}*/}
            </Box>
          </Box>
        </>
      </Box>
    </>
  );
};

export default Edition;
