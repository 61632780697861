import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  TextField,
  ToggleButton,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { ReactComponent as Icon } from "assets/svg/close-btn.svg";
import { ReactComponent as ShareIcon } from "assets/svg/copy-share-icon.svg";
import { dateLocales } from "constants/";
import { format } from "date-fns";
import DOMPurify from "dompurify";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoader, setSnackbar } from "redux/appSlice";
import { getMFACode } from "redux/usersSlice";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { withdrawCard } from "services/editionApi";
import { LocalizationContext } from "services/localizationContext";
import roundedInputStyles from "styles/input/rounded.module.scss";
import { useIsMobile } from "utils/hooks";
import VerificationCode from "views/Settings/security/MFA/VerificationCode";
import * as Yup from "yup";

import styles from "./WithdrawCardDlg.module.scss";

const DLG_STATES = {
  chooseToken: 0,
  walletAddress: 1,
  review: 2,
  success: 3,
};

const prepareToken = (token) => {
  return token.replace(/0\.0\.\d{8}-/i, "");
};

const WithdrawCardDlg = ({ close, card, ...rest }) => {
  const isMobile = useIsMobile();

  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.me.entities);
  const loading = useSelector((state) => state.app.loader);
  const is2faEnabled = useSelector(
    (state) => state.users.entities.is2faEnabled
  );

  const [dialogState, setDialogState] = useState(
    card.tokens.length > 1 ? DLG_STATES.chooseToken : DLG_STATES.walletAddress
  );
  const [selectedToken, setSelectedToken] = useState(
    card.tokens.length > 1 ? null : card.tokens[0]
  );
  const [showWalletAddressTooltip, setShowWalletAddressTooltip] =
    useState(false);
  const [walletAddressValue, setWalletAddressValue] = useState("");
  const [currentCodeValue, setCurrentCodeValue] = useState("");
  const [enteringCode, setEnteringCode] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(0);

  const validationSchema = Yup.object().shape({
    wallet: Yup.string().required(t("error.required")),
  });

  const onClose = () => {
    close();
    setEnteringCode(false);
  };

  const WalletAddressTipTooltipList = [
    t("edition.associateTokenToWalletStep1"),
    t("edition.associateTokenToWalletStep2"),
    t("edition.associateTokenToWalletStep3"),
    t("edition.associateTokenToWalletStep4"),
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const sendCode = () => {
    dispatch(setLoader(true));

    dispatch(getMFACode())
      .unwrap()
      .then(() => {
        dispatch(
          setSnackbar({
            open: true,
            message: t("settings.newVerificationCodeSent"),
          })
        );
      })
      .catch((e) =>
        dispatch(
          setError({
            open: true,
            title: t("default.oops"),
            subtitle: e?.message || e,
          })
        )
      )
      .finally(() => dispatch(setLoader(false)));
  };

  const handleContinue = () => {
    setEnteringCode(true);
    sendCode();
  };

  const onFormSubmit = async (data) => {
    dispatch(setLoader(true));
    try {
      let payload = {
        walletAddress: data.wallet,
        tokenSerial: selectedToken,
      };
      if (is2faEnabled) {
        payload = { ...payload, authCode: +currentCodeValue };
      }

      await withdrawCard(payload);
      dispatch(setLoader(false));
      setDialogState(DLG_STATES.success);
    } catch (e) {
      dispatch(setLoader(false));
      dispatch(
        setError({
          open: true,
          title: t("error.errorTitle"),
          subtitle: errorResponseMessages(e.data || e, t),
        })
      );
      setDialogState(DLG_STATES.chooseToken);
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(selectedToken);

    dispatch(
      setSnackbar({
        open: true,
        message: t("drops.copied"),
      })
    );
  };

  const Title = () => {
    const text = (() => {
      switch (dialogState) {
        case DLG_STATES.chooseToken:
          return t("edition.chooseTokenTitle");
        case DLG_STATES.walletAddress:
          return t("edition.walletAddressTitle");
        case DLG_STATES.review:
          return t("edition.withdrawCardReviewTitle");
        case DLG_STATES.success:
          return t("default.success");
        default:
          return null;
      }
    })();

    const isSuccess = dialogState === DLG_STATES.success;

    return (
      <Box className={styles.titleWrap}>
        <Typography
          variant={isSuccess ? "h1" : "h5"}
          className={`${styles.title} ${isSuccess ? styles.uppercase : ""}`}
        >
          {text}
        </Typography>
        {isSuccess ? (
          <Typography variant="body1" className={styles.subtitle}>
            {t("edition.withdrawSuccessSubtitle")}
          </Typography>
        ) : null}
      </Box>
    );
  };

  const CardBLock = () => {
    return (
      <Box className={styles.cardBlock}>
        {isMobile ? <Title /> : null}
        <Box className={styles.cardWrap}>
          <img
            className={styles.cardImage}
            src={card.imageUrl}
            alt={card.influencerName}
            loading="lazy"
          />
          <Box className={styles.imageOverlay} />
          <Box className={styles.cardTextBlock}>
            <Typography variant="body2" className={styles.cardInfluencerName}>
              {card.influencerName}
            </Typography>
          </Box>
        </Box>
        {selectedToken && dialogState !== DLG_STATES.chooseToken ? (
          <TokenItem
            token={prepareToken(selectedToken)}
            quantityAllocated={card?.quantityAllocated}
            className={styles.disabled}
          />
        ) : null}
      </Box>
    );
  };

  const CancelBtn = () => (
    <Button
      variant="outlined"
      size="small"
      onClick={onClose}
      className={styles.cancelBtn}
    >
      {t("default.cancel")}
    </Button>
  );

  const TokenItem = ({ token, quantityAllocated, className }) => (
    <ToggleButton
      className={`${styles.tokenListItem} ${className ? className : ""}`}
      value={token}
      selected={token === selectedToken}
      onChange={(_ev, t) => setSelectedToken(t)}
    >
      <Typography
        variant="body3"
        component="p"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            t("edition.tokenItem", {
              token: prepareToken(token),
              quantityAllocated: quantityAllocated,
            }),
            { USE_PROFILES: { html: true } }
          ),
        }}
      />
    </ToggleButton>
  );

  const ChooseToken = () => (
    <>
      <Box className={styles.contentBLock}>
        {!isMobile ? <Title /> : null}
        <Box className={styles.tokensListWrap}>
          <Box className={styles.tokensList}>
            {card.tokens.map((t) => (
              <TokenItem
                key={t}
                token={t}
                quantityAllocated={card?.quantityAllocated}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Box className={styles.buttonsBlock}>
        <CancelBtn />
        <Button
          variant="contained"
          size="small"
          onClick={() => setDialogState(DLG_STATES.walletAddress)}
          disabled={!selectedToken}
        >
          {t("default.continueBtn")}
        </Button>
      </Box>
    </>
  );

  const Row = ({ label, value }) => (
    <Box className={styles.spaceBetweenRow}>
      <Typography variant="h6" component="span" className={styles.rowText}>
        {label}
      </Typography>
      <Typography
        variant="h6"
        component="span"
        className={`${styles.rowText} ${styles.accent}`}
      >
        {value}
      </Typography>
    </Box>
  );

  const MFAInfoBlock = () => (
    <Box className={styles.mfaInfo}>
      <Typography variant="body1" className={styles.mfaInfoTitle}>
        {t("settings.twoFABannerTitle")}
      </Typography>
      <Typography variant="body1">{t("settings.twoFABannerText")}</Typography>
    </Box>
  );

  const getReviewDetails = () => {
    return (
      <>
        <Box className={styles.contentBLock}>
          {!isMobile ? <Title /> : null}
          <Box className={styles.rowsBlock}>
            <Row label={t("edition.to")} value={walletAddressValue} />
            <Row
              label={t("edition.transactionType")}
              value={t("edition.cardWithdraw")}
            />
            <Row
              label={t("default.card")}
              value={t("edition.tokenOfQuantity", {
                token: prepareToken(selectedToken),
                quantity: card?.quantityAllocated,
              })}
            />
            <Box className={styles.mfaBlock}>
              {!is2faEnabled ? <MFAInfoBlock /> : null}
              {is2faEnabled && enteringCode ? (
                <VerificationCode
                  onResend={sendCode}
                  title={t("settings.enterAuthenticationCode")}
                  resendCountdown={resendCountdown}
                  setResendCountdown={setResendCountdown}
                  phone={user?.phoneNumber}
                  setCurrentCodeValue={setCurrentCodeValue}
                  noSubmit
                  autoFocus
                />
              ) : null}
            </Box>
          </Box>
        </Box>
        <Box className={styles.buttonsBlock}>
          <CancelBtn />
          {!is2faEnabled || enteringCode ? (
            <LoadingButton
              variant="contained"
              size="small"
              loading={loading}
              disabled={is2faEnabled && !currentCodeValue.length}
              onClick={handleSubmit(onFormSubmit)}
            >
              {t("default.submit")}
            </LoadingButton>
          ) : (
            <LoadingButton
              variant="contained"
              size="small"
              loading={loading}
              onClick={handleContinue}
            >
              {t("default.continueBtn")}
            </LoadingButton>
          )}
        </Box>
      </>
    );
  };

  const Success = () => {
    return (
      <>
        <Box className={styles.contentBLock}>
          {!isMobile ? <Title /> : null}
          <Box className={styles.rowsBlock}>
            <Row label={t("edition.to")} value={walletAddressValue} />
            <Row
              label={t("edition.transactionType")}
              value={t("edition.cardWithdraw")}
            />
            <Row
              label={t("default.card")}
              value={t("edition.tokenOfQuantity", {
                token: prepareToken(selectedToken),
                quantity: card?.quantityAllocated,
              })}
            />
            <Row
              label={t("edition.date")}
              value={format(new Date(), "MM.dd.yyyy", {
                locale: !!user?.language ? dateLocales[user.language] : null,
              })}
            />
          </Box>
        </Box>
        <Box className={styles.buttonsBlock}>
          <Button variant="contained" size="small" onClick={onClose}>
            {t("default.done")}
          </Button>
        </Box>
      </>
    );
  };

  const getWalletAddress = () => (
    <>
      <Box className={styles.contentBLock}>
        {!isMobile ? <Title /> : null}
        <Box className={styles.copyInputWrap}>
          <TextField
            className={styles.copyLinkInput}
            value={selectedToken}
            InputProps={{
              readOnly: true,
            }}
          />
          <IconButton
            className={`${styles.copyBtn} ${styles.btnTitle}`}
            onClick={handleCopyLink}
          >
            <ShareIcon className={styles.copyBtnIcon} />
          </IconButton>
        </Box>
        <Box className={styles.walletAddressTip}>
          <Typography
            variant="body2"
            component="p"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(t("edition.walletAddressTip"), {
                USE_PROFILES: { html: true },
              }),
            }}
            className={styles.walletAddressTipText}
          />
          <Typography
            variant="body2"
            className={styles.walletAddressTipLink}
            onMouseEnter={() => setShowWalletAddressTooltip(true)}
            onMouseLeave={() => setShowWalletAddressTooltip(false)}
          >
            {t("edition.walletAddressTipLink")}
          </Typography>
          {showWalletAddressTooltip ? (
            <Box className={styles.walletAddressTipTooltip}>
              <Typography
                variant="body2"
                className={styles.walletAddressTipTooltipTitle}
              >
                {t("edition.walletAddressTipTooltipTitle")}
              </Typography>
              <List className={styles.walletAddressTipTooltipList}>
                {WalletAddressTipTooltipList.map((s, i) => (
                  <ListItem variant="body3" key={i}>
                    <Typography variant="body3">{`${i + 1}. ${s}`}</Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
          ) : null}
        </Box>
        <Typography
          variant="h5"
          className={`${styles.title} ${styles.walletAddressTitle}`}
        >
          {t("edition.hederaWalletAddress")}
        </Typography>
        <form autoComplete="off">
          <Box className={styles.inputContainer}>
            <TextField
              error={!!errors.wallet}
              label={t("edition.widthdrawCardInputLabel")}
              inputProps={{
                onChange: (ev) => setWalletAddressValue(ev.target?.value),
              }}
              className={`${roundedInputStyles.input} ${
                roundedInputStyles.cyan
              } ${
                !!walletAddressValue.length ? roundedInputStyles.filled : ""
              } ${styles.walletAddressInput}`}
              type="text"
              {...register("wallet")}
            />
            {errors.wallet && (
              <Typography variant="error">{errors.wallet?.message}</Typography>
            )}
          </Box>
        </form>
      </Box>
      <Box className={styles.buttonsBlock}>
        <CancelBtn />
        <Button
          variant="contained"
          size="small"
          onClick={() => setDialogState(DLG_STATES.review)}
          disabled={errors.wallet || !walletAddressValue.length}
        >
          {t("edition.withdrawBtn")}
        </Button>
      </Box>
    </>
  );

  const getContent = () => {
    switch (dialogState) {
      case DLG_STATES.chooseToken:
        return <ChooseToken />;
      case DLG_STATES.walletAddress:
        return getWalletAddress();
      case DLG_STATES.review:
        return getReviewDetails();
      case DLG_STATES.success:
        return <Success />;

      default:
        return null;
    }
  };

  return (
    <Dialog
      fullScreen={isMobile}
      onClose={onClose}
      open={true}
      {...rest}
      classes={{
        paper: "withdrawCardPaper",
        container: "withdrawCardDlgContainer",
        root: "withdrawCardDlg",
      }}
    >
      <Box className={styles.container}>
        <CardBLock />
        {getContent()}
        <Icon
          onClick={onClose}
          width={15}
          height={15}
          className={styles.close}
        />
      </Box>
    </Dialog>
  );
};

export default WithdrawCardDlg;
