import { Box, Skeleton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CountdownDate from "components/CountdownDate/CountdownDate";
import CountdownProgress from "components/CountdownProgress";
import { DescriptionCard } from "components/DescriptionCard/DescriptionCard";
import { CARD_TYPES } from "constants/card";
import { CHANNELS, EVENTS, ORDER_TYPES } from "constants/index";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updateDrop } from "redux/dropsSlice";
import { LocalizationContext } from "services/localizationContext";
import PusherService from "services/Pusher.service";
import { useIsMobile } from "utils/hooks";

import { fetchEditionMarketStatus } from "../../../redux/editionSlice";
import { CardsList } from "./components/CardsLIst/CardsList";
import styles from "./DropDetail.module.scss";

const Phase0DropDetail = ({
  card,
  className,
  seasonCards,
  seasonLoader,
  seasonData,
  descriptionProps,
}) => {
  const params = useParams();
  const id = params.id;
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useIsMobile();

  useEffect(() => {
    const channelDrop = PusherService.subscribe(CHANNELS.DROPS);

    channelDrop.bind(EVENTS.STATUS_CHANGED, (data) => {
      dispatch(updateDrop(data));
    });
    dispatch(fetchEditionMarketStatus({ id, type: ORDER_TYPES.sell }));

    return () => {
      PusherService.unsubscribe(CHANNELS.DROPS);
    };
  }, []);

  const descriptionEl = (
    <DescriptionCard
      className={styles.seasonDescription}
      isMobile={isMobile}
      {...descriptionProps}
    />
  );
  return (
    <Box className={className}>
      <Box className={styles.framedBox}>
        <Box className="App-flexCenter App-flexCenter-between" mb={1}>
          <Typography variant="textMediumBold" className={styles.title}>
            {t("drops.droppingIn")}
          </Typography>
          <Typography
            variant="textLime"
            className={`${styles.timer} ${styles.timerPhase0}`}
          >
            <CountdownDate endDate={card.dropStartDate} isShowDays={true} />
          </Typography>
        </Box>
        <CountdownProgress
          startDate={card.createdAt}
          endDate={card.dropStartDate}
          color={theme.palette.lime}
        />

        <Box className={styles.description}>
          {t("drops.remainingDropZeroSubTitle", {
            dropAllocation: card.quantityAllocated,
          })}
        </Box>
      </Box>
      {seasonLoader ? (
        <Skeleton
          className={styles.seasonDescriptionSkeleton}
          variant="rectangular"
        />
      ) : null}
      {card.type === CARD_TYPES.CUSTOM ? (
        <>
          {descriptionEl}
          <CardsList
            seasonCards={card.otherCards}
            title={`${t("drops.otherCardsFrom")} ${card.influencer.name}`}
          />
        </>
      ) : null}
      {card.type !== CARD_TYPES.CUSTOM ? (
        <>
          {descriptionEl}
          {seasonLoader ? (
            <Skeleton
              className={styles.cardsListSkeleton}
              variant="rectangular"
            />
          ) : null}
          <CardsList
            seasonCards={seasonCards}
            seasonNum={seasonData?.season.seasonNum}
          />
        </>
      ) : null}
    </Box>
  );
};
export default Phase0DropDetail;
