import { celebrityDomain } from "../constants";
import {
  getAuthUsername,
  getGoogleAccessToken,
  getGoogleRefreshToken,
  getRefreshToken,
} from "../services/auth.service";

export const setPayloadToDashboard = (data) => {
  const lang = localStorage.getItem("selected-language");
  let url = `${celebrityDomain}/signin`;
  if (data.accessToken.payload.google) {
    url += `?googleRefreshToken=${data.refreshToken.google}&googleAccessToken=${data.accessToken.payload.google}`;
  } else {
    url += `?refreshToken=${data.refreshToken.token}&username=${data.accessToken.payload.username}`;
  }
  if (lang) {
    url += `&lang=${lang}`;
  }
  // direct path on dashboard
  if (data.pathname) {
    url += `&pathname=${data.pathname}`;
  }
  window.location.href = url;
};

export const loginAsCreator = ({ pathname }) => {
  const data = {
    refreshToken: {
      token: getRefreshToken(),
      google: getGoogleRefreshToken(),
    },
    accessToken: {
      payload: {
        username: getAuthUsername(),
        google: getGoogleAccessToken(),
      },
    },
    ...(pathname && { pathname }),
  };
  setPayloadToDashboard(data);
};

export const goToDashboard = ({ pathname }) => {
  if (getAuthUsername() || getGoogleRefreshToken()) {
    loginAsCreator({ pathname });
  }
};
