export const CARD_WIDTH = 250;
export const CARD_IMAGE_HEIGHT = 350;
export const CARD_PADDING = 26;
export const CARD_SLIDES_GAP = 11;

export const CARD_VARIANTS = {
  SMALL: Symbol("small"),
  FULL: Symbol("full"),
};
export const SMALL_CARD_WIDTH = 167;
export const SMALL_CARD_IMAGE_HEIGHT = 233;
export const SMALL_CARD_HEIGHT = 368;

export const CARD = {
  [CARD_VARIANTS.SMALL]: {
    width: 191,
    height: 368,
    imgHeight: 233,
    slidesGap: 11,
    mobileSlidesGap: 11,
    padding: 0,
  },
  [CARD_VARIANTS.FULL]: {
    width: 250,
    imgHeight: 350,
    slidesGap: 15,
    mobileSlidesGap: 8,
    padding: 26,
  },
};

export const CARD_TYPES = {
  CUSTOM: "custom",
};
