import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import bookmark from "assets/bookmark.json";
import AnimatedCardIcon from "components/AnimatedCardIcon";
import { ANIMATION_DIRECTION } from "constants/";
import Proptypes from "prop-types";
import React, { useCallback, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addEditionToWatchList,
  removeEditionFromWatchList,
} from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";
import { roundToTwo } from "utils/math";

import styles from "./card.module.scss";
import CardLevelIcon from "./CardLevelIcon";
import { FireIcon } from "./icons";

export const cardSizes = {
  large: "large",
  small: "small",
  extraSmall: "extraSmall",
};

const Card = ({
  id,
  name,
  imageUrl,
  latestPrice,
  priceMovement,
  priceMovementPct,
  currencySymbol,
  className,
  priceClassName,
  isWatchlisted,
  onClick,
  size,
  holdings,
  influencerLevel,
  isHot,
}) => {
  const theme = useTheme();
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [watchListed, setWatchListed] = useState(isWatchlisted);
  const [processing, setProcessing] = useState(false);

  const calculatePriceMovement = () => {
    return !priceMovementPct
      ? ` (${roundToTwo(Math.abs((priceMovement / latestPrice) * 100))}%)`
      : ` (${roundToTwo(Math.abs(priceMovementPct))}%)`;
  };

  const toggleWatchlisted = useCallback(
    (e) => {
      e.stopPropagation();
      if (processing) {
        return;
      }

      setProcessing(true);

      if (watchListed) {
        dispatch(removeEditionFromWatchList(id))
          .then(() => {
            setWatchListed(false);
          })
          .catch((err) => {
            console.log("Cannot remove the item from watchlist", err);
          });
      } else {
        dispatch(addEditionToWatchList(id))
          .then(() => {
            setWatchListed(true);
          })
          .catch((err) => {
            console.log("Cannot add the item to watchlist", err);
          });
      }
    },
    [watchListed, processing]
  );

  const onCompleteProcessing = () => {
    setProcessing(false);
  };

  const getSizeClassName = () => {
    switch (size) {
      case cardSizes.large:
        return styles.largeCard;
      case cardSizes.small:
        return styles.smallCard;
      case cardSizes.extraSmall:
        return styles.extraSmallCard;
      default:
        return "";
    }
  };

  return (
    <Box
      className={`${styles.container} ${getSizeClassName()} ${
        className ? className : ""
      }`}
      onClick={onClick}
      sx={{ cursor: onClick ? "pointer" : "default !important" }}
    >
      <Box className={styles.overlay} />
      <img className={styles.image} src={imageUrl} alt={name} />
      <Box className={styles.cardHeader}>
        {!!holdings && (
          <Box className={styles.ownText}>
            <Typography
              component="span"
              variant="body3"
              className={styles.ownTextText}
            >
              {t("default.own")} {holdings}{" "}
              {holdings > 1
                ? t("default.cards").toLowerCase()
                : t("default.card").toLowerCase()}
            </Typography>
          </Box>
        )}
        {!holdings && !!latestPrice && (
          <Box className={styles.cardHeaderRight}>
            <Typography
              variant="body3"
              component="span"
              className={`${styles.price} ${
                priceClassName ? priceClassName : ""
              }`}
            >
              {currencySymbol || "$"}
              {latestPrice}
            </Typography>
            {priceMovement !== 0 && (
              <Box className={styles.priceMovement}>
                <Typography
                  variant="body3"
                  component="span"
                  sx={{
                    color:
                      priceMovement > 0
                        ? theme.palette.tertiary.main
                        : theme.palette.coral.main,
                  }}
                  className={styles.priceMovementText}
                >
                  {calculatePriceMovement()}
                </Typography>
                <Box
                  className={styles.diffTriangle}
                  style={{
                    borderWidth:
                      priceMovement >= 0 ? "0 2px 8px 2px" : "8px 2px 0 2px",
                    borderColor:
                      priceMovement >= 0
                        ? `transparent transparent ${theme.palette.tertiary.main} transparent`
                        : `${theme.palette.coral.main} transparent transparent transparent`,
                  }}
                />
              </Box>
            )}
          </Box>
        )}
        {watchListed !== undefined && (
          <Box
            className={styles.pinnedIcon}
            onClick={(e) => toggleWatchlisted(e)}
          >
            <AnimatedCardIcon
              play={processing}
              handleComplete={onCompleteProcessing}
              direction={
                watchListed
                  ? ANIMATION_DIRECTION.backward
                  : ANIMATION_DIRECTION.forward
              }
              animationData={bookmark}
              className={styles.bookmarkIconAnimated}
              changeAnimationDirection
            />
          </Box>
        )}
      </Box>
      {isHot ? (
        <Box className={styles.fireIconWrap}>
          <FireIcon />
        </Box>
      ) : null}
      {name && (
        <Box className={styles.wrapName}>
          <Typography variant="h5" className={styles.title}>
            {name}
          </Typography>
          <CardLevelIcon level={influencerLevel} />
        </Box>
      )}
    </Box>
  );
};

Card.defaultProps = {
  name: "",
  imageUrl: null,
  currencySymbol: "$",
  latestPrice: 0,
  priceMovement: 0,
  holdings: 0,
};

Card.propTypes = {
  name: Proptypes.string,
  imageUrl: Proptypes.string,
  currencySymbol: Proptypes.string,
  latestPrice: Proptypes.number,
  priceMovement: Proptypes.number,
  holdings: Proptypes.number,
};

export default Card;
