import { Box, Skeleton } from "@mui/material";
import EditionCard from "components/Card/Edition/EditionCard";
import UnfriendModal from "components/Modal/UnfriendModal";
import { PATHS } from "constants/index";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setError, setLoader, setOnfidoToken } from "redux/appSlice";
import { closeModal, openModal } from "redux/modalSlice";
import {
  fetchUser,
  toggleCardBarter,
  updateCard,
  updateTrophyCard,
} from "redux/usersSlice";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { LocalizationContext } from "services/localizationContext";
import { CheckKycStatus } from "utils/hooks/kycCheck";

import ModalChooseTrophyCard from "../../../../components/Modal/ModalChooseTrophyCard";
import styles from "./CollectionTab.module.scss";
import { EmptyTrophyCard } from "./components/EmptyTrophyCard/EmptyTrophyCard";

export const CollectionTab = ({
  isCompleted,
  trophyCardId,
  editions,
  loader,
  isPublic,
}) => {
  const location = useLocation();
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [withdrawDlgId, seWithdrawDlgId] = useState(null);
  const [trophyCard, setTrophyCard] = useState(null);
  const [openTrophyModal, setOpenTrophyModal] = useState(false);

  const handleOpen = () => setOpenTrophyModal(true);
  const handleClose = () => setOpenTrophyModal(false);

  const checkStatus = CheckKycStatus();

  const handleTrophyCard = (cardId) => {
    dispatch(setLoader(true));
    dispatch(updateTrophyCard({ cardId })).then(() => {
      dispatch(fetchUser());
      dispatch(setLoader(false));
    });
  };

  const handleUpdateCard = (payload) => {
    const handleConfirm = () => {
      dispatch(setLoader(true));
      dispatch(updateCard(payload))
        .then(() => {
          dispatch(toggleCardBarter(payload));
          dispatch(closeModal());
        })
        .finally(() => dispatch(setLoader(false)));
    };

    if ("isSwappable" in payload.props && !payload.props?.isSwappable) {
      dispatch(
        openModal({
          children: (
            <UnfriendModal
              description={t("account.toggleSwappableModalText")}
              onConfirm={handleConfirm}
              onCancel={() => dispatch(closeModal())}
              cancelBtnText={t("default.cancel")}
              confirmBtnText={t("default.proceed")}
            />
          ),
        })
      );
    } else {
      handleConfirm();
    }
  };

  const goToCompleteProfile = () => {
    localStorage.setItem("completeProfileBackRoute", location.pathname);
    navigate(PATHS.COMPLETE_PROFILE);
  };

  const handleWithdrawNft = (id) => {
    dispatch(setLoader(true));
    checkStatus()
      .then((status) => {
        if (status?.sdkToken) {
          dispatch(setLoader(true));
          return dispatch(
            setOnfidoToken({
              sdkToken: status?.sdkToken,
            })
          );
        }
        if (status !== 1) {
          return;
        }

        if (isCompleted) {
          seWithdrawDlgId(id);
        } else {
          goToCompleteProfile();
        }
      })
      .catch((err) => {
        if (err?.status !== 401) {
          dispatch(
            setError({
              open: true,
              subtitle: errorResponseMessages(err?.data || err, t),
            })
          );
        }
        console.log(err?.data || err);
      })
      .finally(() => dispatch(setLoader(false)));
  };

  useEffect(() => {
    setTrophyCard(editions.find((card) => card.id === trophyCardId));
  }, [editions, trophyCardId]);

  return (
    <Box className={styles.list}>
      {(!trophyCardId || !trophyCard) && editions.length && !isPublic ? (
        <EmptyTrophyCard className={styles.card} onClick={handleOpen} />
      ) : null}
      {!editions.length && loader ? (
        <>
          <Skeleton variant="rectabgular" style={{ height: "330px" }} />
          <Skeleton variant="rectabgular" style={{ height: "330px" }} />
          <Skeleton variant="rectabgular" style={{ height: "330px" }} />
          <Skeleton variant="rectabgular" style={{ height: "330px" }} />
          <Skeleton variant="rectabgular" style={{ height: "330px" }} />
        </>
      ) : null}
      {trophyCard ? (
        <EditionCard
          wrapClassName={styles.card}
          key={`edition-${trophyCard.id}`}
          className={styles.cardContainer}
          {...trophyCard}
          id={trophyCard.id}
          cardImage={trophyCard.imageUrl}
          isTrophy
          makeTrophyCard={handleOpen}
          onClick={handleOpen}
          toggleBarterEnabled={() =>
            handleUpdateCard({
              cardId: trophyCard.id,
              props: { isSwappable: !trophyCard.isSwappable },
            })
          }
          showOptsBtn={!isPublic}
          isSwappable={trophyCard?.isSwappable}
          inBarter={false}
          handleWithdrawNft={handleWithdrawNft}
          seWithdrawDlgId={seWithdrawDlgId}
          withdrawDlgId={withdrawDlgId}
        />
      ) : null}
      {editions.map((card) =>
        card.id !== trophyCardId ? (
          <EditionCard
            wrapClassName={styles.card}
            className={styles.cardContainer}
            key={`edition-${card.id}`}
            {...card}
            id={card.id}
            cardImage={card.imageUrl}
            onClick={() =>
              navigate(PATHS.CARD_ID.replace(":id", card.id), {
                state: { backRoute: location.pathname + location.search },
              })
            }
            makeTrophyCard={() => handleTrophyCard(card.id)}
            toggleBarterEnabled={() =>
              handleUpdateCard({
                cardId: card.id,
                props: { isSwappable: !card.isSwappable },
              })
            }
            showOptsBtn={!isPublic}
            isSwappable={card?.isSwappable}
            inBarter={false}
            handleWithdrawNft={handleWithdrawNft}
            seWithdrawDlgId={seWithdrawDlgId}
            withdrawDlgId={withdrawDlgId}
          />
        ) : null
      )}
      <ModalChooseTrophyCard
        open={openTrophyModal}
        cards={editions}
        trophyCard={trophyCard}
        handleClose={handleClose}
        handleClick={handleTrophyCard}
      />
    </Box>
  );
};
