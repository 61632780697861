import { Box, Typography } from "@mui/material";
import { ReactComponent as DeliveredIcon } from "assets/svg/checked-icon-green.svg";
import { ReactComponent as LockedIcon } from "assets/svg/locked-icon.svg";
import { ReactComponent as UnlockedIcon } from "assets/svg/unlocked-icon.svg";
import {
  REWARD_ORDER_STATUS,
  REWARD_STATUS,
  REWARDS_ACTION_TYPE,
} from "constants/index";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { LocalizationContext } from "services/localizationContext";

import { setError } from "../../../../redux/appSlice";
import { ChatWidgetModal } from "../../../Modal/ChatWidgetModal/ChatWidgetModal";
import styles from "./CardRewardItem.module.scss";

export const CardRewardItem = ({
  rewardType,
  id,
  rewardOrder,
  rewardStatus,
  unlockModalHandler,
  influencer,
  cardId,
  holdings,
  cardTokens,
  duration,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);

  const [isOpenChatWidget, setOpenChatWidget] = useState(false);

  const clickHandler = () => {
    if (rewardStatus === REWARD_STATUS.LOCKED) {
      unlockModalHandler();
    }
    if (rewardStatus === REWARD_STATUS.UNLOCKED && rewardOrder?.id) {
      setOpenChatWidget(true);
    } else if (rewardStatus === REWARD_STATUS.UNLOCKED) {
      dispatch(
        setError({
          open: true,
          title: t("default.rewardUnlockedAlertTitle"),
          subtitle: t("default.rewardUnlockedAlertSubtitle"),
          isAlertIcon: true,
          isInfoAlert: true,
        })
      );
    }
  };

  const title =
    rewardType === REWARDS_ACTION_TYPE.FOLLOW_BACK
      ? t("default.followBack")
      : rewardType === REWARDS_ACTION_TYPE.VIDEO_CALL
      ? t("default.privateVideoCall", { value: duration })
      : null;

  return (
    <>
      <Box
        className={`${styles.reward} ${
          rewardOrder?.status === REWARD_ORDER_STATUS.DELIVERED
            ? styles.rewardNonAction
            : rewardOrder?.status === REWARD_ORDER_STATUS.PENDING_REPLY
            ? styles.rewardAnimation
            : ""
        }`}
        onClick={clickHandler}
      >
        <Box>
          <Box>
            <Typography
              className={styles.rewardLabel}
              variant="body3_grotesk_medium"
            >
              <Box className="opacity_07">
                {t("default.editionEdition")} #{id}
              </Box>
            </Typography>
          </Box>
          <Typography
            className={styles.rewardTitle}
            variant="textMediumBold2"
            component="p"
          >
            {title}
          </Typography>
        </Box>
        {rewardOrder?.status === REWARD_ORDER_STATUS.DELIVERED ? (
          <DeliveredIcon />
        ) : rewardStatus === REWARD_STATUS.UNLOCKED ? (
          <UnlockedIcon />
        ) : (
          <LockedIcon />
        )}
      </Box>
      <ChatWidgetModal
        isOpen={isOpenChatWidget}
        setOpen={setOpenChatWidget}
        selectedToken={{ id, rewardOrder, rewardStatus, ...rest }}
        cardId={cardId}
        holdings={holdings}
        influencer={influencer}
        rewardType={rewardType}
        cardTokens={cardTokens}
        title={
          rewardType === REWARDS_ACTION_TYPE.FOLLOW_BACK
            ? t("default.followBack")
            : rewardType === REWARDS_ACTION_TYPE.VIDEO_CALL
            ? t("default.privateVideoCall", { value: duration })
            : null
        }
        duration={duration}
      />
    </>
  );
};
