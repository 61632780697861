import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { SeasonSlider } from "components/SeasonSlider/SeasonSlider";
import { useContext, useEffect, useState } from "react";
import { getSeason } from "services/editionApi";
import { LocalizationContext } from "services/localizationContext";

import { DescriptionCard } from "../../components/DescriptionCard/DescriptionCard";
import styles from "./edition.module.scss";

const CardSeason = ({
  editionId,
  seasonNum,
  isMobile,
  currencySymbol,
  influencer,
  latestPrice,
  marketTokens,
  onGetSeason,
}) => {
  const influencerId = influencer?.id;
  const [seasonLoading, setSeasonLoading] = useState(false);
  const [seasonData, setSeasonData] = useState(null);
  const seasonCards = (seasonData?.seasonCards || []).filter(
    (item) => item?.id !== editionId
  );
  const season = seasonData?.season || null;
  const { t } = useContext(LocalizationContext);

  useEffect(() => {
    const getSeasonData = async () => {
      try {
        setSeasonLoading(true);
        const res = await getSeason({ influencerId, seasonNum });
        setSeasonData(res);
        onGetSeason(res);
      } catch (e) {
        console.error(e);
      } finally {
        setSeasonLoading(false);
      }
    };
    if (seasonNum && influencerId) {
      getSeasonData();
    }
  }, [seasonNum, influencerId]);
  if (seasonLoading)
    return <Skeleton className={styles.seasonSkeleton} variant="rectangular" />;
  if (!season) return null;
  return (
    <>
      <DescriptionCard
        title={season?.title}
        seasonNum={seasonNum}
        description={season?.description}
        influencer={influencer}
        currencySymbol={currencySymbol}
        latestPrice={latestPrice}
        marketTokens={marketTokens}
        isMobile={isMobile}
      />
      <Box className={`${styles.season} ${styles.seasonSliderWrap}`}>
        <Typography variant="h5" className={`${styles.title}`}>
          {t("edition.cardsSeasonTitle", { number: seasonNum })}
        </Typography>
        <SeasonSlider
          seasonCards={seasonCards}
          spaceBetweenSlides={11}
          cardWidth={177}
        />
      </Box>
    </>
  );
};
export default CardSeason;
