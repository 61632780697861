import { Box, Button, Skeleton, Typography } from "@mui/material";
import { ReactComponent as Send } from "assets/svg/feed/send.svg";
import { ReactComponent as QuestionIcon } from "assets/svg/question-icon.svg";
import Editor from "components/QuillEditor/Editor";
import CustomTooltip from "components/Tooltip";
import { REWARD_ORDER_STATUS } from "constants/index";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import { isMobileDevice } from "utils/index";

import styles from "../../ChatWidget.module.scss";

export const Footer = ({
  isStatic,
  submit,
  goToMessenger,
  lastMessage,
  loader,
  name,
  reactQuillRef,
  selectedToken,
}) => {
  const { t } = useContext(LocalizationContext);
  const isPhone = isMobileDevice();

  const keyDownHandler = (e) => {
    if (e.key === "Enter" && !e.shiftKey && !isPhone) {
      e.preventDefault();
      submit();
    }
  };

  return (
    <>
      {isStatic ? (
        <Button
          variant="contained"
          className={styles.button}
          onClick={goToMessenger}
        >
          <Typography variant="body4_groteskMedium">
            {t("default.goToMessagesText")}
          </Typography>
        </Button>
      ) : selectedToken?.rewardOrder?.status ? (
        <>
          {loader ? (
            <Skeleton variant="rectangular" className={styles.footerSkeleton} />
          ) : selectedToken?.rewardOrder?.status ===
            REWARD_ORDER_STATUS.DELIVERED ? (
            <Typography variant="body1" className={styles.status}>
              {t("default.rewardDeliveredTitle")}
              <CustomTooltip
                title={
                  <Typography variant="body3">
                    {t("default.rewardDeliveredTitleByCeleb", {
                      name,
                    })}
                    ,{" "}
                    <Box
                      component="span"
                      className={styles.statusBtn}
                      onClick={() => {
                        window.zE("messenger", "show");
                        window.zE("messenger", "open");
                      }}
                    >
                      {t("default.contactUs")}
                    </Box>
                    .
                  </Typography>
                }
                isCustomTitleLayout={true}
              >
                <QuestionIcon />
              </CustomTooltip>
            </Typography>
          ) : !lastMessage?.isIncoming ? (
            <Typography variant="body1" className={styles.status}>
              {t("default.chatWidgetWaitReply", {
                name,
              })}
            </Typography>
          ) : (
            <Box className={styles.footer}>
              <Box className={`${styles.footerMain}`}>
                <Editor
                  reactQuillRef={reactQuillRef}
                  customFormats={[]}
                  editorToolbarId={"toolbar-chat"}
                  className={`${styles.editor}`}
                  placeholder={t("default.chatWidgetMessagePrompt")}
                  onKeyDown={keyDownHandler}
                />
              </Box>
              <Button
                variant="containedDark"
                className={styles.sendBtn}
                onClick={submit}
              >
                <Send />
              </Button>
            </Box>
          )}
        </>
      ) : loader ? (
        <Skeleton variant="rectangular" className={styles.footerSkeleton} />
      ) : null}
    </>
  );
};
