import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import theme from "theme";

import { AppUserWallet } from "./AppUserWallet";
import { Telegram } from "./components/Telegram/Telegram";
import { DEFAULT_TITLE_TAG } from "./constants";
import Pusher from "./modules/Pusher.module";
import { Pixel } from "./Pixel";
import { Prompt } from "./Prompt";
import { getTheme } from "./redux/selectors/appSelectors";
import Router from "./Router";
import LangService from "./services/LangService";
import ResponseInterceptor from "./services/ResponseInterceptor";
import { ViewportService } from "./services/ViewportService";
import { Verification3ds } from "./Verification3ds";
import { YupCustomMethods } from "./YupCustomMethods";
export const ThemeWrapper = () => {
  const activeTheme = useSelector(getTheme);

  const currentTheme = createTheme(theme[activeTheme]);

  return (
    <ThemeProvider theme={currentTheme}>
      <Helmet>
        <title>{DEFAULT_TITLE_TAG}</title>
      </Helmet>
      <Telegram />
      <YupCustomMethods />
      <ViewportService />
      <LangService />
      <ResponseInterceptor />
      <Pusher />
      <Router />
      <AppUserWallet />
      <Pixel />
      <Prompt />
      <Verification3ds />
    </ThemeProvider>
  );
};
