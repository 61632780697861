import { Box, Button, CircularProgress } from "@mui/material";
import { ReactComponent as Send } from "assets/svg/feed/send.svg";
import Editor from "components/QuillEditor/Editor";
import { CHATS_TYPE, FULL_COMMENT_TEXT_LIMIT } from "constants/index";
import React, { lazy, useContext, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setSnackbar } from "redux/appSlice";
import { LocalizationContext } from "services/localizationContext";
import { isMobileDevice } from "utils/index";

import styles from "./Footer.module.scss";

const EmojiPicker = lazy(() =>
  import("components/EmojiPicker/EmojiPicker.jsx")
);

// let channel = null;

export const Footer = ({ submitHandler, activeChatId, chatsType }) => {
  const dispatch = useDispatch();
  const isPhone = isMobileDevice();
  const reactQuillRef = useRef(null);
  const { t } = useContext(LocalizationContext);

  const submit = () => {
    const txtLength = reactQuillRef.current?.value?.length;
    if (txtLength > FULL_COMMENT_TEXT_LIMIT) {
      dispatch(
        setSnackbar({
          open: true,
          message: t("error.maxCharacters"),
          severity: "error",
        })
      );
      return;
    }
    if (activeChatId) {
      if (reactQuillRef.current.editor?.getText().trim()?.length) {
        submitHandler({ messageContent: reactQuillRef.current.value });
        reactQuillRef.current.editor.setText("");
      }
    }
  };

  const keyDownHandler = (e) => {
    if (e.key === "Enter" && !e.shiftKey && !isPhone) {
      e.preventDefault();
      submit();
    }
  };

  const onEmojiClick = ({ emoji }) => {
    const quill = reactQuillRef.current;
    const editor = quill.getEditor();
    const range = editor.getSelection();
    const position = range ? range.index : editor.getLength() - 1;
    editor.insertText(position, emoji);
  };

  useEffect(() => {
    // channel = PusherService.subscribe(`presence-chat-${activeChatId}`);
    // channel.bind(EVENTS.CHAT_TYPING, function (data) {
    //   console.log(data);
    // });
    // return () => {
    //   PusherService.unsubscribe(`private-chat-${activeChatId}`);
    // };
  }, []);

  return (
    <Box
      className={`${styles.footer} ${
        chatsType === CHATS_TYPE.REQUESTS ? styles.footerRequests : ""
      }`}
    >
      <Box
        className={`${styles.footerMain} ${
          isPhone ? styles.footerMainNonSmile : null
        }`}
      >
        {!isPhone ? (
          <React.Suspense
            fallback={
              <Box>
                <CircularProgress size={18} />
              </Box>
            }
          >
            <EmojiPicker
              onEmojiClick={onEmojiClick}
              className={!activeChatId ? styles.nonActive : ""}
            />
          </React.Suspense>
        ) : null}
        <Editor
          reactQuillRef={reactQuillRef}
          customFormats={[]}
          editorToolbarId={"toolbar-chat"}
          className={`${styles.editor} ${isPhone ? styles.editorMobile : ""}`}
          placeholder={t("discover.writeMessagePrompt")}
          onKeyDown={keyDownHandler}
          readOnly={!activeChatId}
        />
        {/*<Button variant="text">*/}
        {/*  <AssetsIcon />*/}
        {/*</Button>*/}
      </Box>
      <Button
        variant="containedDark"
        className={styles.sendBtn}
        onClick={submit}
        disabled={!activeChatId}
      >
        <Send />
      </Button>
    </Box>
  );
};
