import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Divider, Typography } from "@mui/material";
import Card from "components/Card";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import { roundToTwo } from "utils/math";

import styles from "./buy-edition.module.scss";

const ReviewDetails = ({
  edition,
  priceValue,
  platformFee,
  submitOrder,
  loading,
  title,
  buttonText,
}) => {
  const { t } = useContext(LocalizationContext);
  return (
    <Box className={styles.reviewDetailsContainer}>
      <Card
        className={styles.mainCard}
        imageUrl={edition.card.imageUrl}
        name={edition.card.influencer.name}
        size="large"
      />
      <Box className={styles.reviewDetailsInfo}>
        <Typography variant="h3" className={styles.reviewDetailsTitle}>
          {title}
        </Typography>
        <Box className={styles.reviewDetailsRow}>
          <Typography variant="h6" className={styles.reviewDetailsRowKey}>
            {t("default.influencer")}
          </Typography>
          <Typography variant="h6" className={styles.reviewDetailsRowValue}>
            {edition.card.influencer.name}
          </Typography>
        </Box>
        {/*{edition.card.cardMetadata?.cardSeason?.seasonNum ? (*/}
        {/*  <Box className={styles.reviewDetailsRow}>*/}
        {/*    <Typography variant="h6" className={styles.reviewDetailsRowKey}>*/}
        {/*      {t("discover.season")}*/}
        {/*    </Typography>*/}
        {/*    <Typography variant="h6" className={styles.reviewDetailsRowValue}>*/}
        {/*      {t("drops.seasonTitleAbbr", {*/}
        {/*        seasonNumber: edition.card.cardMetadata?.cardSeason.seasonNum,*/}
        {/*        curdNumber: edition.card.cardMetadata?.cardNum,*/}
        {/*      })}*/}
        {/*    </Typography>*/}
        {/*  </Box>*/}
        {/*) : (*/}
        {/*  <Box className={styles.reviewDetailsRow}>*/}
        {/*    <Typography variant="h6" className={styles.reviewDetailsRowKey}>*/}
        {/*      {t("default.edition")}*/}
        {/*    </Typography>*/}
        {/*    <Typography variant="h6" className={styles.reviewDetailsRowValue}>*/}
        {/*      {tokenNumber !== null || tokenNumber !== undefined*/}
        {/*        ? `#${tokenNumber}`*/}
        {/*        : edition.card.name}*/}
        {/*    </Typography>*/}
        {/*  </Box>*/}
        {/*)}*/}
        <Box className={styles.reviewDetailsRow}>
          <Typography variant="h6" className={styles.reviewDetailsRowKey}>
            {t("discover.platformFee")}
          </Typography>
          <Typography variant="h6" className={styles.reviewDetailsRowValue}>
            {edition.currencySymbol}
            {roundToTwo(+platformFee)}
          </Typography>
        </Box>
        <Divider variant="fullWidth" className={styles.reviewDetailsDivider} />
        <Box
          className={`${styles.reviewDetailsRow} ${styles.reviewDetailsPriceRow}`}
        >
          <Typography
            variant="h6"
            className={`${styles.reviewDetailsRowKey} ${styles.reviewDetailsPriceKey}`}
          >
            {t("discover.total")}
          </Typography>
          <Typography
            variant="h6"
            className={`${styles.reviewDetailsRowValue} ${styles.reviewDetailsPriceValue}`}
          >
            {edition.currencySymbol}
            {roundToTwo(+priceValue + roundToTwo(+platformFee))}
          </Typography>
        </Box>
        <LoadingButton
          variant="contained"
          className={styles.reviewDetailsBtn}
          onClick={submitOrder}
          loading={loading}
        >
          {buttonText ? buttonText : t("trading.submitOrder")}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default ReviewDetails;
