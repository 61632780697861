import { Box, Button, IconButton, Popover, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as Kebab } from "assets/svg/kebab-icon.svg";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import { getFormattedDate } from "utils/dateHelper";
import { roundToTwo } from "utils/math";

import styles from "./watchListItem.module.scss";

const WatchListItem = ({ item, handleDelete }) => {
  const navigate = useNavigate();
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { card, createdAt } = item;
  const {
    latestPrice,
    priceChangePct,
    priceChange,
    imageUrl,
    series,
    influencer,
  } = card;

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation(event);
    setAnchorEl(null);
  };

  const removeFromWatchList = async (event) => {
    event.stopPropagation();
    handleDelete();
    handleClose(event);
  };

  const calculatePriceMovement = () => {
    return !priceChangePct
      ? `(${priceChange >= 0 ? "+" : "-"}${
          influencer.currencySymbol || "$"
        }${Math.abs(
          roundToTwo(!!latestPrice ? (priceChange / latestPrice) * 100 : 0)
        )}%)`
      : `(${priceChange >= 0 ? "+" : "-"}${
          influencer.currencySymbol || "$"
        }${roundToTwo(Math.abs(priceChangePct))}%)`;
  };

  const openEditionDetails = () =>
    navigate(`/pass/${item.cardId}`, item.cardId);

  return (
    <Box className={styles.container} onClick={openEditionDetails}>
      <Box className={styles.content}>
        <img
          className={styles.image}
          src={imageUrl}
          alt={influencer.name}
          loading="lazy"
        />
        <Box className={styles.leftTextBlock}>
          <Typography variant="h5" className={styles.title}>
            {influencer.name}
          </Typography>
        </Box>
        <Box className={styles.rightTextBlock}>
          <Box className={styles.priceWrap}>
            <Typography
              variant="body1"
              component="span"
              className={styles.price}
            >
              {influencer.currencySymbol || "$"}
              {latestPrice}
            </Typography>
            <Typography
              variant="body1"
              component="span"
              className={styles.priceChange}
              sx={{
                color:
                  priceChange >= 0
                    ? theme.palette.tertiary.main
                    : theme.palette.coral.main,
              }}
            >
              &nbsp;
              {calculatePriceMovement()}
            </Typography>
          </Box>
          <Typography
            variant="body2"
            component="span"
            className={styles.dateAdded}
          >
            {t("default.dateAdded", { date: getFormattedDate(createdAt) })}
          </Typography>
        </Box>
      </Box>
      <IconButton onClick={handleClick} className={styles.optionsBtn}>
        <Kebab width={24} height={24} />
      </IconButton>
      <Popover
        id={"delete-popover"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        className={styles.deleteBtnPaper}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Button
          onClick={removeFromWatchList}
          variant="contained"
          className={styles.deleteBtn}
        >
          {t("default.delete")}
        </Button>
      </Popover>
    </Box>
  );
};

export default WatchListItem;
