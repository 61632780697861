import { ToggleButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { forwardRef } from "react";

const StyledToggleBtn = styled(ToggleButton)(({ theme }) => ({
  color: "#FFFFFF",
  padding: "8px",
  height: "32px",
  fontFamily: `"ClashGrotesk-Regular", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
  fontSize: "14px",
  lineHeight: "16px",
  marginRight: "15px",
  border: "1px solid #ffffff",
  borderRadius: "10px",
  textTransform: "none",
  "&.Mui-selected": {
    background: theme.palette.accent.main,
    color: theme.palette.main,
    border: `1px solid ${theme.palette.accent.main}`,
  },
  "&.Mui-disabled": {
    background: "inherit",
    opacity: 0.3,
    color: "#ffffff",
    border: "1px solid #ffffff",
    "&.Mui-selected": {
      opacity: 0.6,
      background: theme.palette.accent.main,
      border: `1px solid ${theme.palette.accent.main}`,
      color: theme.palette.main,
    },
  },
}));

const StyledCustomization = forwardRef(({ children, ...rest }, ref) => {
  return (
    <StyledToggleBtn ref={ref} {...rest}>
      {children}
    </StyledToggleBtn>
  );
});

export default StyledCustomization;
