import { Box, Typography } from "@mui/material";
import classNames from "classnames/bind";
import ShareBtn from "components/Buttons/ShareBtn/ShareBtn";
import DOMPurify from "dompurify";
import React, { useContext } from "react";
import NumberFormat from "react-number-format";
import { LocalizationContext } from "services/localizationContext";

import { CardRewards } from "../CardRewards/CardRewards";
import { DescriptionCardHeader } from "./components/DescriptionCardHeader";
import styles from "./DescriptionCard.module.scss";

const cx = classNames.bind(styles);

export const DescriptionCard = ({
  title, //is displayed on mobile inside header
  seasonNum, //if there is a season, title will becomposed of its number and card number
  description,
  // reward related props
  rewardType,
  media,
  mediaSummary,
  holdings,
  marketTokens,
  data,
  // --------------
  influencer,
  currencySymbol,
  currentSalePrice,
  latestPrice, //for edition when drop is finished
  className,
  isMobile,
}) => {
  const { t } = useContext(LocalizationContext);
  const shareLink = window.location.href;
  const price = currentSalePrice || latestPrice;
  //TODO::Also have this card
  return (
    <Box className={cx(className, styles.framedBox)}>
      {!isMobile ? (
        <DescriptionCardHeader
          influencer={influencer}
          seasonNum={seasonNum}
          title={title}
        />
      ) : null}
      {description ? (
        <>
          <Box className={styles.framedBoxHeader}>
            <Typography variant="body1" className={styles.label}>
              {t("default.descriptionLabel")}
            </Typography>
            <ShareBtn
              shareLink={shareLink}
              className={styles.share}
              btnClassName={styles.shareBtn}
            />
          </Box>
          <Typography
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(description, {
                USE_PROFILES: { html: true },
              }),
            }}
            className={styles.description}
            variant="subtitle1"
          />
        </>
      ) : null}

      {price ? (
        <Box className={styles.price}>
          <Typography variant="body1" className={styles.label}>
            {t("default.price")}
          </Typography>
          <Typography variant="subtitle1">
            <NumberFormat
              value={price}
              displayType={"text"}
              thousandSeparator={true}
              prefix={currencySymbol}
            />
          </Typography>
        </Box>
      ) : null}
      {rewardType ? (
        <>
          <Typography
            variant="body4"
            style={{ margin: "24px 0 8px" }}
            component="p"
            className={styles.rewardTitle}
          >
            {t("default.cardRewardSectionTitle")}
          </Typography>
          <Typography className={styles.description} variant="body1">
            {t("default.cardRewardSectionDescription")}
          </Typography>
        </>
      ) : null}
      <CardRewards
        media={media || []}
        rewardType={rewardType}
        mediaSummary={mediaSummary}
        holdings={holdings}
        influencer={influencer}
        marketTokens={marketTokens}
        data={data}
      />
    </Box>
  );
};
