import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Skeleton,
  Typography,
} from "@mui/material";
import classnames from "classnames/bind";
import Card from "components/CardId/Card";
import Countdown from "components/Countdown";
import { StripePayOrderModal } from "components/Stripe/StripeCheckoutModal/StripeCheckoutModal";
import {
  ANALYTICS_EVENTS,
  DROP_INITIAL_PRICE,
  errorResponseTypes,
  PATHS,
  PAYMENT_METHODS,
  PAYMENT_TYPE,
  PAYMENT_TYPE_BTC,
  PAYMENT_TYPE_PAYPAL,
  PAYMENT_TYPE_STRIPE,
  TRANSFER_STATUSES,
} from "constants/index";
import { useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setError, setLoader } from "redux/appSlice";
import { fetchDrop, resetDrop, setCheckoutUrl } from "redux/dropsSlice";
import { isDropFinished } from "redux/dropsSlice";
import { defaultPaymentMethod, fetchUserMe } from "redux/usersSlice";
import Analytics from "services/Analytics.service";
import { cancelOrder } from "services/api/paymentsApi";
import { TradingErrorResponseMessages } from "services/apiErrorHelper.tsx";
import { isAuthenticated } from "services/auth.service";
import { buyCard, getCard } from "services/dropsApi";
import { LocalizationContext } from "services/localizationContext";
import PaypalButton from "views/Drops/DropBuy/PaypalButton";

import { PageLayout } from "../../PageLayout/PageLayout";
import stylesMain from "../DropDetail/DropDetail.module.scss";
import styles from "./DropBuy.module.scss";

const PAYMENT_COUNTDOWN_TIME = 90;

const DropBuy = () => {
  const isAuth = isAuthenticated();
  const cx = classnames.bind(styles);
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const params = useParams();
  const card = useSelector((state) => state.drops.drop);
  const isFinished = useSelector((state) => isDropFinished(state));
  const stateStatus = useSelector((state) => state.drops.dropStatus);

  const navigate = useNavigate();
  const location = useLocation();

  const [redirectUrl, setRedirectUrl] = useState("");
  const [transfer, setTransfer] = useState(null);
  const [stripeClientSecret, setStripeClientSecret] = useState(null);
  const [paymentCountdown, setPaymentCountdown] = useState(-1);
  const [isProcessing, setIsProcessing] = useState(false);
  const backRoute = location.state?.backRoute;
  const clientSecretFromUrl = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );
  const myDefaultPaymentMethod = useSelector((state) =>
    defaultPaymentMethod(state)
  );
  const paymentMethod = location.state?.paymentMethod || myDefaultPaymentMethod;

  const paymentCountdownProgress =
    (paymentCountdown * 100) / PAYMENT_COUNTDOWN_TIME;
  const isUserLoading = useSelector((state) => state.users.me.loading);

  const isBought = [
    TRANSFER_STATUSES.processed,
    TRANSFER_STATUSES.pending,
    TRANSFER_STATUSES.succeeded,
  ].includes(card?.transferStatus);

  const goToSelectPayment = () => {
    const {
      currentSalePrice,
      id,
      influencer: { name },
    } = card || {};
    Analytics.track(ANALYTICS_EVENTS.ADD_PAYMENT_INFO, {
      value: currentSalePrice,
      items: [
        {
          id,
          price: currentSalePrice,
          celebrity: name,
          initial_price: DROP_INITIAL_PRICE,
          quantity: 1,
        },
      ],
    });

    navigate("/payment/accounts", {
      state: {
        prevRoute: location.pathname,
        paymentType: PAYMENT_TYPE.DROP,
      },
    });
  };
  const errorsHandler = TradingErrorResponseMessages();
  const onCancelOrder = () => {
    setStripeClientSecret(null);
    setTransfer(null);
    if (transfer?.orderId) {
      cancelOrder(transfer?.orderId).catch((e) => console.error(e));
    }
  };

  useEffect(() => {
    dispatch(fetchDrop(params.id, isAuth));
    if (params.id) {
      getCard(params.id);
    }
    if (!location.state?.paymentMethod && !isUserLoading)
      dispatch(fetchUserMe());

    return () => {
      dispatch(resetDrop());
      dispatch(setCheckoutUrl(null));
    };
  }, []);

  useEffect(() => {
    if (isFinished) navigate(`/pass/${params.id}`, { replace: true });
  }, [isBought, isFinished, params?.id]);

  useEffect(() => {
    if (stateStatus === "failed") {
      dispatch(setError({ open: true }));
      navigate(PATHS.DROPS);
    }
  }, [stateStatus, paymentMethod]);

  const onPaymentSuccess = (paymentMethodType, isHideLoader) => {
    let paymentMethodParam = PAYMENT_METHODS.find(
      (item) => item.type === (paymentMethod?.type || paymentMethodType)
    );
    Analytics.track("Buy Drop", {
      paymentMethod: paymentMethodParam?.id,
      cardId: card.id,
    });
    if (!isHideLoader)
      dispatch(
        setLoader({
          title: t("drops.waitingPaymentConfirmation"),
        })
      );
    navigate(`success`, {
      replace: true,
      state: { paymentMethod: paymentMethodParam, card },
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsProcessing(true);
      let params = {
        appUserPaymentMethodId: paymentMethod.id,
      };
      const PAYMENT_METHOD_WITH_ORDER = PAYMENT_METHODS.find(
        (item) => item.type === paymentMethod.type
      );
      if (PAYMENT_METHOD_WITH_ORDER) {
        params.paymentMethodId = PAYMENT_METHOD_WITH_ORDER.id;
      }
      const res = await buyCard(card.id, params);
      const details = JSON.parse(res.details);
      setIsProcessing(false);

      if (PAYMENT_METHOD_WITH_ORDER) {
        if (paymentMethod?.type === PAYMENT_TYPE_BTC) {
          setRedirectUrl(details?.hosted_checkout_url);
        } else if (paymentMethod?.type === PAYMENT_TYPE_STRIPE) {
          setStripeClientSecret(details?.client_secret);
        } else {
          setPaymentCountdown(PAYMENT_COUNTDOWN_TIME);
        }
        setTransfer(res);
      } else {
        onPaymentSuccess();
      }
    } catch (err) {
      //TODO::test errors
      if (err?.data.error === errorResponseTypes.ProfileNotCompleted) {
        dispatch(
          setError({
            open: true,
            ...errorsHandler(err?.data || err),
            isNotCompleted: true,
            actions: "BuyCardActions",
            title: t("kyc.completeKyc"),
            subtitle: t("kyc.pleaseCompleteTheWholeKyc"),
          })
        );
      } else {
        dispatch(
          setError({
            open: true,
            ...errorsHandler(err?.data || err),
          })
        );
      }
      dispatch(setLoader(false));
    }
  };

  const handleRedirect = ({ subtitleError }) => {
    if (!redirectUrl) {
      dispatch(
        setError({
          open: true,
          title: t("trading.dang"),
          subtitle: subtitleError,
        })
      );
      return;
    }
    window.open(redirectUrl);
  };

  return (
    <PageLayout
      title={transfer ? t("payments.completeOrder") : t("drops.reviewDetails")}
      isBack={true}
      isPlain
      backRoute={backRoute || `/pass/${params.id}`}
    >
      <Container className={`${stylesMain.container} ${styles.buyDetails}`}>
        {(stateStatus === "loading" && !card) || isUserLoading ? (
          <Box className={stylesMain.wrapper}>
            <Container
              className={`${stylesMain.cardContainer} ${styles.cardContainer}`}
            >
              <Skeleton
                variant="rectangular"
                className={`${stylesMain.card} ${styles.detailsCard}`}
              />
              <Box className={`${styles.details}`}>
                <Skeleton
                  variant="rectangular"
                  className={styles.skeletonDetails}
                />
                <Skeleton
                  variant="rectangular"
                  className={styles.skeletonMethod}
                />
                <Box className={[styles.dropActions]}>
                  <Skeleton
                    variant="rectangular"
                    className={styles.skeletonBtn}
                  />
                </Box>
              </Box>
            </Container>
          </Box>
        ) : (
          <Box className={`${stylesMain.wrapper} ${styles.wrapper}`}>
            {card && (
              <Container
                className={`${stylesMain.cardContainer} ${styles.cardContainer}`}
              >
                <Card
                  card={card}
                  className={`${stylesMain.card} ${styles.detailsCard}`}
                />
                <form onSubmit={onSubmit} className={styles.details}>
                  <Box mb="30px">
                    <Box className={cx(stylesMain.gridItem, styles.gridItem)}>
                      <Typography
                        variant="lightText"
                        className={stylesMain.gridText}
                      >
                        {t("drops.bidInfluencer")}
                      </Typography>
                      <Typography
                        variant="acentTextLighter"
                        className={cx(
                          stylesMain.acentText,
                          stylesMain.acentTextLighter,
                          stylesMain.acentTextLarge
                        )}
                      >
                        {card.influencer.name}
                      </Typography>
                    </Box>
                    <Box className={cx(stylesMain.gridItem, styles.gridItem)}>
                      <Typography
                        variant="lightText"
                        className={stylesMain.gridText}
                      >
                        {t("default.edition")}
                      </Typography>
                      <Typography
                        variant="acentTextLighter"
                        className={cx(
                          stylesMain.acentText,
                          stylesMain.acentTextLighter,
                          stylesMain.acentTextLarge
                        )}
                      >
                        {card.name}
                      </Typography>
                    </Box>
                    <Box className={styles.divider} />
                    <Box className={stylesMain.gridItem}>
                      <Typography
                        variant="lightText"
                        className={cx(
                          stylesMain.gridText,
                          stylesMain.gridTextLarge
                        )}
                      >
                        {t("drops.total")}
                      </Typography>
                      <Typography
                        variant="acentTextLighter"
                        className={cx(
                          stylesMain.acentText,
                          stylesMain.acentTextLarge
                        )}
                      >
                        <NumberFormat
                          value={card.currentSalePrice}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={card.currencySymbol}
                        />
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    className={cx(styles.info, styles.infoMain)}
                    sx={{
                      justifyContent: paymentMethod?.id
                        ? "space-between"
                        : "center",
                    }}
                  >
                    {paymentMethod?.id && (
                      <Typography variant="infoMain">
                        {t("payments.payingWith")} {paymentMethod.name}
                      </Typography>
                    )}
                    <Button
                      className={styles.changeBtn}
                      variant="contained"
                      size="xsmall"
                      color="dark"
                      onClick={goToSelectPayment}
                    >
                      {paymentMethod?.id
                        ? t("payments.change")
                        : t("payments.selectPaymentMethod")}
                    </Button>
                  </Box>

                  <Box className={styles.submitBtn}>
                    {paymentCountdown >= 0 && transfer && (
                      <Box className={styles.paymentCountdownWrap}>
                        <Typography variant="body1">
                          {t("payments.completePaymentTimerText")}
                        </Typography>
                        <Box className={styles.paymentCountdown}>
                          <Countdown
                            value={paymentCountdown}
                            onSetValue={setPaymentCountdown}
                            onEnd={onCancelOrder}
                          />
                          <Typography
                            variant="body2"
                            className={styles.paymentCountdownText}
                          >
                            {paymentCountdown}
                          </Typography>
                          <CircularProgress
                            color="lime"
                            variant="determinate"
                            value={paymentCountdownProgress}
                          />
                        </Box>
                      </Box>
                    )}

                    {transfer && paymentMethod?.type === PAYMENT_TYPE_BTC && (
                      <Button
                        variant="contained"
                        onClick={() =>
                          handleRedirect({
                            subtitleError: t("trading.urlPayWithBtcError"),
                          })
                        }
                        disabled={!paymentMethod?.id}
                      >
                        {t("trading.payWithBtcBtn")}
                      </Button>
                    )}

                    {transfer &&
                      paymentMethod?.type === PAYMENT_TYPE_PAYPAL && (
                        <PaypalButton
                          trackingId={transfer.trackingId}
                          onSuccess={onPaymentSuccess}
                          onCancel={onCancelOrder}
                        />
                      )}
                    {!transfer && (
                      <LoadingButton
                        loading={isProcessing}
                        variant="outlined"
                        onClick={onSubmit}
                        disabled={!paymentMethod?.id}
                      >
                        {t("trading.submitOrder")}
                      </LoadingButton>
                    )}
                  </Box>
                </form>
                {clientSecretFromUrl ||
                (transfer &&
                  paymentMethod?.type === PAYMENT_TYPE_STRIPE &&
                  stripeClientSecret) ? (
                  <StripePayOrderModal
                    clientSecret={stripeClientSecret}
                    onCloseModal={onCancelOrder}
                    title={t("discover.buyCard")}
                    onPaymentSuccess={() =>
                      onPaymentSuccess(PAYMENT_TYPE_STRIPE, true)
                    }
                  />
                ) : null}
              </Container>
            )}
          </Box>
        )}
      </Container>
    </PageLayout>
  );
};
export default DropBuy;
