import { Box, Button, IconButton, Skeleton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as MessageIcon } from "assets/svg/message-icon.svg";
// import { ShareDataBox } from "components/ShareDataBox/ShareDataBox";
import { MODAL_TYPE, PATHS } from "constants/index";
import { getCategories } from "constants/index";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedUser } from "redux/messengerSlice";
import { openModal } from "redux/modalSlice";
import { stripeInfluencerDonate } from "services/api/paymentsApi";
import { LocalizationContext } from "services/localizationContext";

import { HeartIcon } from "../../../../icons";
// Temporary removed SidebarMenuTooltip DEV-6335
// import { SidebarMenuTooltip } from "../../../SidebarMenuTooltip/SidebarMenuTooltip";
import { FreeButton } from "./FreeButton";
import styles from "./MainInfo.module.scss";
import { PaidButton } from "./PaidButton";

export const MainInfo = ({
  influencerData,
  handleOnSubscribe,
  subscriptionPrice,
  influencerId,
  isSubscriptionAvailable,
  subscriptionData,
  onFollowCallback,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:840px)");

  const [isHoverButton, setIsHoverButton] = useState(false);

  const shareLink = influencerData?.handle
    ? `${window.origin}/${influencerData?.handle}`
    : `${window.origin}${PATHS.DISCOVER_INFLUENCER_ID.replace(
        ":id",
        influencerId
      )}`;

  const categories = getCategories({ t });
  const category = categories[influencerData?.category];

  const onSubmit = async ({
    message,
    paymentMethodId,
    tipAmount,
    tipIsHidden,
  }) => {
    return await stripeInfluencerDonate({
      influencerId,
      paymentMethodId,
      amount: tipAmount,
      amountIsHidden: tipIsHidden,
      message,
    });
  };

  const goToMessenger = () => {
    const { appUserId, profileImageUrl, handle, name } = influencerData || {};
    dispatch(
      setSelectedUser({
        id: appUserId,
        profileImageUrl,
        username: handle || name,
      })
    );
    navigate(PATHS.CHAT_DIRECT.replace(":userId", appUserId));
  };

  const openModalHandler = () =>
    dispatch(
      openModal({
        type: MODAL_TYPE.DONATE_MODAL,
        payload: {
          message: "",
          name: influencerData.name,
          onSubmit,
          isMessageRequired: false,
          snackbarMessage: t("payments.successfulSupportMessage"),
          isTipIsHiddenField: false,
        },
      })
    );

  const onHoverButton = () => {
    setIsHoverButton(true);
  };

  const onLeaveButton = () => {
    setIsHoverButton(false);
  };

  const isDonationEnable =
    influencerData?.community?.isDonationEnabled &&
    influencerData?.community.isSubscriptionReady;

  return (
    <Box className={styles.mainInfoWrapper}>
      <Box className={styles.mainInfo}>
        {influencerData ? (
          <>
            <img
              className={styles.mainImage}
              src={influencerData.profileImageUrl}
              alt={influencerData.name}
            />
            <Box className={styles.mainInfoInnerBlock}>
              <Box className={styles.mainInfoInnerBlockTitle}>
                <Box className={styles.inflInfo}>
                  {isMobile ? null : (
                    <Typography
                      variant="textMediumBold"
                      className={styles.category}
                    >
                      {category}
                    </Typography>
                  )}
                  <Typography variant="h5" className={styles.name}>
                    {influencerData.name}
                  </Typography>
                </Box>
                <Box className={styles.innerBtnWrap}>
                  {/*<ShareDataBox*/}
                  {/*  buttonClassName={styles.innerBtn}*/}
                  {/*  url={shareLink}*/}
                  {/*  title={t("edition.share")}*/}
                  {/*/>*/}
                  <IconButton
                    className={`${styles.innerBtn} ${styles.innerBtnMessage}`}
                    onClick={goToMessenger}
                  >
                    <MessageIcon />
                  </IconButton>
                  {/* <SidebarMenuTooltip influencerData={influencerData} /> */}
                </Box>
              </Box>
              {isMobile ? (
                <Box className={styles.mainInfoInnerBlockRow}>
                  <Box className={styles.mainInfoInnerBlockCol}>
                    <Typography
                      variant="textMediumBold2"
                      className={styles.mainInfoInnerBlockColValue}
                    >
                      {influencerData?.community?.numPosts || "-"}
                    </Typography>
                    <Typography
                      variant="body3"
                      className={styles.mainInfoInnerBlockColTitle}
                    >
                      {t("default.posts")}
                    </Typography>
                  </Box>
                  <Box className={styles.mainInfoInnerBlockCol}>
                    <Typography
                      variant="textMediumBold2"
                      className={styles.mainInfoInnerBlockColValue}
                    >
                      {influencerData?.community?.numFollowers || "-"}
                    </Typography>
                    <Typography
                      variant="body3"
                      className={styles.mainInfoInnerBlockColTitle}
                    >
                      {t("discover.followers")}
                    </Typography>
                  </Box>
                  <Box className={styles.mainInfoInnerBlockCol}>
                    <Typography
                      variant="textMediumBold2"
                      className={styles.mainInfoInnerBlockColValue}
                    >
                      {influencerData?.cards?.length || "-"}
                    </Typography>
                    <Typography
                      variant="body3"
                      className={styles.mainInfoInnerBlockColTitle}
                    >
                      {t("default.passes")}
                    </Typography>
                  </Box>
                </Box>
              ) : null}
            </Box>
          </>
        ) : (
          <Skeleton
            className={styles.mainImage}
            variant="rectangular"
            sx={{ bgcolor: theme.palette.surface.main }}
          />
        )}
      </Box>
      <Box
        className={`${styles.btnGroup} ${styles.btnGroupNoWrap} ${
          !influencerData?.isFollowing ? styles.btnGroupSubscribtion : ""
        }`}
      >
        {influencerData ? (
          <>
            {isSubscriptionAvailable ? (
              <PaidButton
                subscriptionData={subscriptionData}
                isSubscribed={influencerData?.isSubscribed}
                handleOnSubscribe={handleOnSubscribe}
                subscriptionPrice={subscriptionPrice}
                currencySymbol={influencerData?.currencySymbol}
                influencerName={influencerData.name}
              />
            ) : null}
            <FreeButton
              isFollowing={influencerData?.isFollowing}
              influencerData={influencerData}
              onFollowCallback={onFollowCallback}
              influencerName={influencerData.name}
            />
          </>
        ) : null}
      </Box>

      {isDonationEnable ? (
        <Box
          className={`${styles.btnGroup} ${styles.btnGroupNoWrap} ${styles.btnGroupFull}`}
        >
          <Button
            variant={isHoverButton ? "secondary" : "outlined"}
            className={`${styles.actionBtn} ${styles.actionBtnSupport}`}
            onClick={openModalHandler}
            onMouseEnter={onHoverButton}
            onMouseLeave={onLeaveButton}
          >
            <HeartIcon />
            {t("payments.support")}
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};
