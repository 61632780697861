import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCountries } from "services/appApi";

export const fetchCountries = createAsyncThunk("app/countries", async () => {
  const response = await getCountries();
  return response.data;
});

const initialState = {
  theme: localStorage.getItem("theme") || "dark",
  snackbar: {
    open: false,
    autoHideDuration: 2000,
    message: "",
    severity: "success",
    width: "320px",
    left: "8px",
    right: "40px",
    unclosable: false,
  },
  isMobileMenuVisible: false,
  error: {
    open: false,
  },
  modal: {
    open: false,
    type: null,
    payload: null,
  },
  loader: false,
  isPixel: false,
  countries: null,
  onfidoToken: null,
  onfidoRedirectComplete: null,
  serverError: null,
  redirectUrl3ds: null,
  isPromptEvent: null,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setSnackbar: (state, action) => {
      state.snackbar = { ...initialState.snackbar, ...action.payload };
    },
    setModal: (state, action) => {
      state.modal = { ...initialState.modal, ...action.payload };
    },
    setError: (state, action) => {
      state.error = { ...initialState.error, ...action.payload };
    },
    setServerError: (state, action) => {
      state.serverError = action.payload;
    },
    toggleMobileMenu: (state) => {
      state.isMobileMenuVisible = !state.isMobileMenuVisible;
    },
    closeMobileMenu: (state) => {
      state.isMobileMenuVisible = false;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setPixel: (state, action) => {
      state.isPixel = action.payload;
    },
    setOnfidoToken: (state, action) => {
      state.onfidoToken = action.payload?.sdkToken || null;
      state.onfidoRedirectComplete =
        action.payload?.onfidoRedirectComplete || null;
    },
    setPayments3ds: (state, action) => {
      state.redirectUrl3ds = action.payload;
    },
    togglePromptEvent: (state, action) => {
      state.isPromptEvent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountries.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.loading = false;
        state.countries = action.payload;
      });
  },
});

export const {
  setSnackbar,
  setError,
  setServerError,
  toggleMobileMenu,
  closeMobileMenu,
  setLoader,
  setPixel,
  setOnfidoToken,
  setModal,
  setPayments3ds,
  togglePromptEvent,
  setTheme,
} = appSlice.actions;
export default appSlice.reducer;
