import { Box, Container } from "@mui/material";
import Card from "components/CardId/Card.js";
import { DescriptionCardHeader } from "components/DescriptionCard/components/DescriptionCardHeader";
import DropDetailSkeleton from "components/Drops/Skeletons/DropDetailSkeleton";
import { EDITION_STATUSES, PATHS } from "constants/index";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setError } from "redux/appSlice";
import { updateCardTokens } from "redux/cardSlice";
import { getCardSelector, getCardTokens } from "redux/selectors/cardSelectors";
import { getInfluencerSeason } from "services/influencerApi";
import { getUserCard } from "services/userApi";
import { formatEditionOrders, getInfluencerProfileLink } from "utils";
import { useIsMobile } from "utils/hooks";

import { CARD_TYPES } from "../../../constants/card";
import { SidebarActions } from "./components/SidebarActions/SidebarActions";
import styles from "./DropDetail.module.scss";
import Phase0Detail from "./Phase0DropDetail";
import Phase2Detail from "./Phase2DropDetail";

const STATE_STATUS = {
  failed: "failed",
  loading: "loading",
};
const seasonInitialState = {
  influencer: {},
  season: {
    title: "",
    description: "",
    seasonNum: 1,
  },
  seasonCards: [],
};
const DropDetail = ({ status, handleBuyBtnClick, checking, onSell }) => {
  const { id } = useParams();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const stateStatus = useSelector((state) => state.drops.dropStatus);
  const drop = useSelector((state) => state.drops.drop);
  const collectionCard = useSelector(getCardTokens);
  const card = useSelector(getCardSelector);
  const [seasonLoader, setSeasonLoader] = useState(false);
  const [seasonData, setSeasonData] = useState(seasonInitialState);
  const { influencer } = drop || {};

  const navigate = useNavigate();
  const influencerProfileLink = getInfluencerProfileLink({
    id: influencer?.id,
    handle: influencer?.handle,
  });

  const handleSell = () => {
    if (collectionCard && collectionCard.tokens && collectionCard.tokens[0]) {
      const token = collectionCard.tokens[0];
      onSell({
        ...token,
        price: token.purchasePrice || token.price,
        total: collectionCard?.amountCards,
        currencySymbol: drop.influencer.currencySymbol,
        allTokens: collectionCard.tokens,
      });
    } else {
      onSell();
    }
  };

  const getMyCardData = async () => {
    try {
      const res = await getUserCard({ params: { cardId: id } });
      const formatRes = formatEditionOrders(
        res?.data,
        status?.card?.quantityAllocated
      );
      dispatch(updateCardTokens(formatRes));
    } catch (e) {
      console.error(e);
    }
  };

  const seasonCards = useMemo(
    () => seasonData?.seasonCards.filter((item) => item.id !== card.id),
    [seasonData?.seasonCards, card?.id]
  );

  useEffect(() => {
    status?.assetHoldings > 0
      ? getMyCardData()
      : dispatch(updateCardTokens(null));
  }, [status?.assetHoldings, id]);

  useEffect(() => {
    if (stateStatus === STATE_STATUS.failed) {
      dispatch(setError({ open: true }));
      navigate(PATHS.DROPS);
    }
  }, [stateStatus]);

  useEffect(() => {
    if (!influencer?.id) return;
    setSeasonLoader(true);
    getInfluencerSeason({ id: influencer?.id })
      .then((data) => {
        data && setSeasonData(data);
      })
      .finally(() => setSeasonLoader(false));
  }, [influencer?.id]);

  const currencySymbol = drop?.influencer?.currencySymbol;
  const descriptionProps =
    card?.type === CARD_TYPES.CUSTOM
      ? {
          ...card,
          title: card?.name,
          currencySymbol,
        }
      : {
          title: seasonData?.season.title,
          description: seasonData?.season.description,
          seasonNum: seasonData?.season.seasonNum,
          influencer,
        };
  return (
    <Container className={styles.container}>
      <Box className={styles.wrapper}>
        {stateStatus === STATE_STATUS.loading && !drop && (
          <DropDetailSkeleton className={styles.cardContainer} />
        )}
        {isMobile ? (
          <DescriptionCardHeader
            influencer={influencer}
            {...descriptionProps}
            className={styles.cardHeader}
            isMobile={true}
          />
        ) : null}
        {drop && (
          <Container className={styles.cardContainer}>
            <Box className={styles.cardSidebar}>
              <Card
                className={styles.card}
                card={drop}
                link={influencerProfileLink}
              />
              <SidebarActions
                drop={drop}
                checking={checking}
                assetHoldings={status?.assetHoldings || 0}
                handleBuyBtnClick={handleBuyBtnClick}
                onSell={handleSell}
              />
            </Box>
            {drop?.status === EDITION_STATUSES.phase0 ? (
              <Phase0Detail
                card={{ ...drop, ...card }}
                className={styles.details}
                seasonCards={seasonCards}
                seasonData={seasonData}
                seasonLoader={seasonLoader}
                descriptionProps={descriptionProps}
              />
            ) : null}
            {drop?.status === EDITION_STATUSES.phase2 ||
            drop?.status === EDITION_STATUSES.complete ? (
              <Phase2Detail
                card={{ ...drop, ...card }}
                className={styles.details}
                seasonCards={seasonCards}
                seasonData={seasonData}
                seasonLoader={seasonLoader}
                descriptionProps={descriptionProps}
              />
            ) : null}
          </Container>
        )}
      </Box>
    </Container>
  );
};
export default DropDetail;
