import { paletteLight } from "./paletteLight";

export const components = {
  MuiContainer: {
    defaultProps: {
      maxWidth: "xl",
    },
    styleOverrides: {
      maxWidthLg: {
        paddingLeft: 24,
        paddingRight: 24,
      },
      maxWidthSm: {
        paddingLeft: 16,
        paddingRight: 16,
      },
      maxWidthXl: {
        paddingLeft: 48,
        paddingRight: 48,
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      root: {
        color: "#fff",
        fontSize: "16px",
        paddingBottom: "16px",
        fontFamily: `"ClashGrotesk-Regular", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
        "&:before": {
          borderBottom: "1px solid #FFFFFF !important",
          borderColor: "#fff",
        },
        "&:after": {
          borderColor: "#70E8FC",
        },
        "&.Mui-error": {
          fontSize: "16px",
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "20px",
        textTransform: "none",
        height: "54px",
        borderRadius: "10px",
        padding: "12px 29px",
        color: "#111322",
        background: "#70E8FC",
        border: "none",
        flexShrink: 0,
        "&.MuiButton-sizeSmall": {
          height: "44px",
        },
        "&.MuiButton-sizeXsmall": {
          height: "32px",
          fontSize: "18px",
          borderRadius: "6px",
          padding: "5px 8px",
          minWidth: "auto",
        },
        "&.MuiButton-text": {
          background: "none",
          padding: 0,
          height: "auto",
          color: "#00D3F3",
        },
        "&.MuiButton-contained": {
          background: "linear-gradient(128deg, #AD42FF 0%, #5F89F7 100%)",
          "border-radius": "8px",
          color: "#F5FAFF",
          border: "none",
          fontWeight: 500,
          fontSize: "14px",
          "&:hover": {
            background:
              "linear-gradient(128.02deg, rgba(173, 66, 255, 0.8) 30%, rgba(95, 137, 247, 0.8) 70%)",
          },
          "&Dark": {
            background: "linear-gradient(128deg, #AD42FF 0%, #5F89F7 100%)",
            color: "#F5FAFF",
            "&:hover": {
              background:
                "linear-gradient(128.02deg, rgba(173, 66, 255, 0.8) 30%, rgba(95, 137, 247, 0.8) 70%)",
            },
          },
          "&.MuiButton-containedSurface": {
            background: "#21264E",
            color: "white",
            "&:hover": {
              background: "#21264e80",
            },
            "&.MuiButton-sizeXsmall": {
              height: "27px",
              fontSize: "14px",
              borderRadius: "5px",
              color: "#70E8FC",
            },
          },
        },
        "&.MuiButton-verify": {
          background: paletteLight.coral.main,
          color: "#111322",
          border: "none",
          fontWeight: 500,
        },
        "&.MuiButton-yellow": {
          background: "#F1FC70",
          color: "#111322",
          border: "none",
          fontWeight: 500,
        },
        "&.MuiButton-secondary": {
          background:
            "linear-gradient(128deg, rgba(156, 44, 243, 0.20) 30%, rgba(58, 111, 249, 0.20) 70%)",
          color: "#082445",
          border: "none",
          fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
          fontWeight: 400,
          fontSize: "14px",
        },
        "&.MuiButton-outlined": {
          border: "2px solid #AD42FF",
          fontSize: "14px",
          color: "#9C2CF3",
          background: "transparent",
          svg: {
            path: {
              stroke: "#AD42FF",
            },
          },
          "&:hover": {
            background:
              "linear-gradient(128deg, rgba(173, 66, 255, 0.10) 30%, rgba(95, 137, 247, 0.10) 70%)",
            color: "#9C2CF3",
            svg: {
              path: {
                stroke: "#AD42FF",
              },
            },
          },
          ".MuiLoadingButton-loadingIndicator": {
            color: "#9C2CF3",
          },
        },
        "&.MuiButton-outlined-red": {
          background: "none",
          color: paletteLight.coral.main,
          border: "1px solid #EE5261",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            opacity: 0.8,
          },
          ".MuiLoadingButton-loadingIndicator": {
            color: "#FFFFFF",
          },
        },
        "&.MuiButton-contained-danger": {
          background: paletteLight.coral.main,
          color: "#111322",
          fontWeight: 500,
          "&:hover": {
            background: paletteLight.coral.hover,
          },
        },
        "&.Mui-disabled": {
          opacity: 0.5,
        },
      },
      containedPrimary: {
        "&:hover": {},
      },
      containedSecondary: {
        color: "#000646",
        "&:hover": {
          background: "#edfbff",
        },
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          color: "#111322",
          background: "#FFFFFF",
          "&:hover": {
            color: "#111322",
            background: "#edfbff",
          },
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        borderRadius: "10px",
        color: "#FFFFFF",
        ".MuiOutlinedInput-notchedOutline": {
          border: "1px solid #FFFFFF",
        },
        ".MuiOutlinedInput-notchedOutline span": {
          border: "1px solid #FFFFFF",
        },
        "&:hover": {
          ".MuiOutlinedInput-notchedOutline": {
            border: "1px solid #FFFFFF",
          },
        },
        "&.Mui-focused": {
          ".MuiOutlinedInput-notchedOutline": {
            border: "1px solid #FFFFFF",
          },
        },
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        background: "none",
        boxShadow: "none",
        border: "1px solid rgba(240, 241, 245, 0.1)",
        margin: 0,
        "@media (max-width:480px)": {
          border: "none",
        },
        "&.MuiAccordion-root": {
          borderRadius: "12px",
        },
        "&:before": {
          display: "none",
        },
        "&.Mui-expanded": {
          margin: 0,
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        padding: "0 28px",
        minHeight: "82px",
        "@media (max-width:480px)": {
          minHeight: "41px",
          padding: "0",
        },
        "&.Mui-expanded": {
          boxShadow: "0 1px 0 0 rgba(240, 241, 245, 0.1)",
          minHeight: "82px",
          "@media (max-width:480px)": {
            boxShadow: "none",
            minHeight: "41px",
          },
        },
      },
      content: {
        "&.Mui-expanded": {
          "@media (max-width:480px)": {
            margin: "0px",
          },
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: "20px 28px",
        "@media (max-width:480px)": {
          padding: "16px",
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        ".MuiTabs-indicator": {
          background: paletteLight.tab.indicator.background,
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: "none",
        font: "400 14px / 1 'ClashGrotesk-Regular'",
        "&.Mui-selected": {
          background:
            "-webkit-linear-gradient(128.02deg, #AD42FF 0%, #5F89F7 100%)",
          "-webkit-background-clip": "text",
          "-webkit-text-fill-color": "transparent",
        },
      },
    },
  },
};
