import { Box, Button, Typography } from "@mui/material";
import classNames from "classnames/bind";
import { PATHS } from "constants/index";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import { useTelegram } from "utils/hooks/useTelegram";

import styles from "./BottomMenu.module.scss";
import {
  AirdropIcon,
  ChallengesIcon,
  DiscoverIcon,
  FriendsIcon,
  HomeIcon,
  MarketIcon,
  MineIcon,
  ProfileIcon,
} from "./icons";

const menuPaths = {
  home: PATHS.HOME,
  discover: PATHS.DISCOVER,
  market: PATHS.DROPS,
  challenges: PATHS.CHALLENGES,
  profile: PATHS.PROFILE,
  referrals: PATHS.REFERRALS,
  airdrop: PATHS.AIRDROP,
};

export const BottomMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useContext(LocalizationContext);
  const { user: userTg } = useTelegram();

  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const getActiveClass = (value, activeClass) => {
    return value === activeMenuItem
      ? `${styles.menuItemActive} ${activeClass}`
      : "";
  };

  const goTo = (path) => () => navigate(path);

  const airdropClass = classNames(styles.menuItemText, {
    opacity_05: activeMenuItem !== menuPaths.airdrop,
  });

  useEffect(() => {
    const paths = Object.values(menuPaths);
    const isPath = paths.includes(location.pathname);
    if (isPath) {
      setActiveMenuItem(location.pathname);
    }
  }, [location]);

  return (
    <Box className={styles.menu}>
      <Button
        className={`${styles.menuItem} ${styles.menuItemHome} ${getActiveClass(
          menuPaths.home,
          styles.menuItemHomeActive
        )}`}
        onClick={goTo(PATHS.HOME)}
      >
        <HomeIcon />
        <Typography
          className={styles.menuItemText}
          variant="clash_display_12_500"
        >
          {t("home.home")}
        </Typography>
      </Button>
      <Button
        className={`${styles.menuItem} ${
          styles.menuItemDiscover
        } ${getActiveClass(menuPaths.discover, styles.menuItemDiscoverActive)}`}
        onClick={goTo(PATHS.DISCOVER)}
      >
        <DiscoverIcon />
        <Typography
          className={styles.menuItemText}
          variant="clash_display_12_500"
        >
          {t("discover.discover")}
        </Typography>
      </Button>
      {!userTg ? (
        <Button
          className={`${styles.menuItem} ${
            styles.menuItemDrops
          } ${getActiveClass(menuPaths.market, styles.menuItemDropsActive)}`}
          onClick={goTo(PATHS.DROPS)}
        >
          <MarketIcon />
          <Typography
            className={styles.menuItemText}
            variant="clash_display_12_500"
          >
            {t("default.bottomMenuMarket")}
          </Typography>
        </Button>
      ) : null}
      <Button
        className={`${styles.menuItem} ${
          styles.menuItemChallenges
        } ${getActiveClass(
          menuPaths.challenges,
          styles.menuItemChallengesActive
        )}`}
        onClick={goTo(PATHS.CHALLENGES)}
      >
        {userTg ? <MineIcon /> : <ChallengesIcon />}
        <Typography
          className={styles.menuItemText}
          variant="clash_display_12_500"
        >
          {userTg ? t("home.mine") : t("home.challengesMobile")}
        </Typography>
      </Button>
      {userTg ? (
        <Button
          className={`${styles.menuItem} ${
            styles.menuItemReferrals
          } ${getActiveClass(
            menuPaths.referrals,
            styles.menuItemReferralsActive
          )}`}
          onClick={goTo(PATHS.REFERRALS)}
        >
          <FriendsIcon />
          <Typography
            className={styles.menuItemText}
            variant="clash_display_12_500"
          >
            {t("account.connections")}
          </Typography>
        </Button>
      ) : null}
      {!userTg ? (
        <Button
          className={`${styles.menuItem} ${
            styles.menuItemProfile
          } ${getActiveClass(menuPaths.profile, styles.menuItemProfileActive)}`}
          onClick={goTo(PATHS.PROFILE)}
        >
          <ProfileIcon />
          <Typography
            className={styles.menuItemText}
            variant="clash_display_12_500"
          >
            {t("account.profile")}
          </Typography>
        </Button>
      ) : null}
      {userTg ? (
        <Button
          className={`${styles.menuItem} ${getActiveClass(
            menuPaths.airdrop,
            false
          )}`}
          onClick={goTo(PATHS.AIRDROP)}
        >
          <AirdropIcon />
          <Typography className={airdropClass} variant="clash_display_12_500">
            {t("default.airdrop")}
          </Typography>
        </Button>
      ) : null}
    </Box>
  );
};
