import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { ReactComponent as Visibility } from "assets/svg/visibility.svg";
import { ReactComponent as VisibilityOff } from "assets/svg/visibility-off.svg";
import {
  ANALYTICS_EVENTS,
  errorResponseMsgs,
  errorResponseTypes,
  PATHS,
} from "constants";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setLoader } from "redux/appSlice";
import Analytics from "services/Analytics.service";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { setAuth } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";
import UserPool from "services/userPool";
import styles from "styles/auth/Auth.module.scss";
import roundedInputStyles from "styles/input/rounded.module.scss";
import { useRedirectAfterAuth } from "utils/hooks";
import * as Yup from "yup";

const LoginForm = () => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location?.state || {};
  const dispatch = useDispatch();

  const [serverError, setServerError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const loading = useSelector((state) => state.app.loader);

  const validationSchema = Yup.object().shape({
    password: Yup.string().required(t("error.required")),
    email: Yup.string()
      .required(t("error.required"))
      .email(t("error.invalidEmail")),
  });

  const redirect = useRedirectAfterAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email,
    },
  });

  const setAuthUser = (data) => {
    setAuth(data);
    redirect();
    dispatch(setLoader(false));
  };

  const onSubmit = (data) => {
    setServerError(false);
    dispatch(setLoader(true));
    const user = new CognitoUser({
      Username: data.email,
      Pool: UserPool(),
    });

    const authDetails = new AuthenticationDetails({
      Username: data.email,
      Password: data.password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (d) => {
        setAuthUser(d);
        Analytics.identify({ userId: data.email });
        Analytics.track(ANALYTICS_EVENTS.LOGIN);
      },
      mfaRequired: (codeDeliveryDetails, params) => {
        console.error("mfaRequired", codeDeliveryDetails, params);
        dispatch(setLoader(false));
      },
      onFailure: (error) => {
        console.error("onFailure", error);
        setTimeout(() => {
          dispatch(setLoader(false));
          setServerError(errorResponseMessages(error, t));
        }, 1500);
      },
      newPasswordRequired: function () {
        dispatch(setLoader(false));
        const path = PATHS.FORGOT_PASSWORD;

        navigate(path, {
          state: {
            email: watchEmail,
            passwordExpired: true,
            password: data.password,
          },
        });
        setServerError(
          errorResponseMessages(
            {
              code: errorResponseTypes.NotAuthorizedException,
              message: errorResponseMsgs.PasswordExpired,
            },
            t
          )
        );
      },
    });
  };

  const watchEmail = watch("email") || "";

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      className={`${styles.form} ${styles.formSign}`}
    >
      <FormControl>
        <TextField
          label={t("kyc.email")}
          error={!!errors.email}
          inputProps={{
            onChange: (ev) => setData({ ...data, email: ev.target?.value }),
          }}
          className={`${roundedInputStyles.input} ${roundedInputStyles.cyan} ${
            watchEmail.length ? roundedInputStyles.filled : ""
          }`}
          type="text"
          autoComplete="off"
          {...register("email")}
        />
        {!!errors.email ? (
          <Typography variant="error" className={roundedInputStyles.error}>
            {errors.email?.message}
          </Typography>
        ) : null}
      </FormControl>

      <FormControl>
        <TextField
          label={t("auth.password")}
          error={!!errors.password}
          className={`${roundedInputStyles.input} ${roundedInputStyles.cyan} ${
            !!data.password.length ? roundedInputStyles.filled : null
          }`}
          inputProps={{
            enterKeyHint: "go",
            onChange: (ev) => setData({ ...data, password: ev.target?.value }),
          }}
          autoComplete="off"
          type={showPassword ? "text" : "password"}
          {...register("password")}
        />
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => setShowPassword(!showPassword)}
          className={roundedInputStyles.showPasswordToggle}
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
        {!!errors.password ? (
          <Typography variant="error" className={roundedInputStyles.error}>
            {errors.password?.message}
          </Typography>
        ) : null}
        {serverError ? (
          <Typography variant="error" className={roundedInputStyles.error}>
            {serverError}
          </Typography>
        ) : null}
        <Link
          to={PATHS.FORGOT_PASSWORD}
          state={{ email: watchEmail }}
          className={styles.forgotPasswordLink}
        >
          {t("auth.forgotYourPassword")}
        </Link>
      </FormControl>
      <Box className={styles.signSubmitContainer}>
        <FormControl>
          <LoadingButton
            variant="contained"
            type="submit"
            className={styles.submitBtn}
            loading={loading}
            disabled={!isValid}
          >
            {t("default.login")}
          </LoadingButton>
        </FormControl>
      </Box>
    </form>
  );
};
export default LoginForm;
