import { AppBar, Box, Button, Container, Toolbar } from "@mui/material";
import { ReactComponent as Logo } from "assets/svg/logo.svg";
import ActiveLink from "components/ActiveLink";
import betaStyle from "components/Layout/BetaLabel.module.scss";
import commonStyles from "components/Layout/Header/Header.module.scss";
import styles from "components/Layout/PublicHeader/PublicHeader.module.scss";
import { PATHS } from "constants/index";
import React, { useContext, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
// import { toggleMobileMenu } from "redux/appSlice";
import { isAuthenticated } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";
import { useIsMobile } from "utils/hooks";

import { getInfluencerProfileLink } from "../../../utils";
// import { LanguageSelect } from "../../LanguageSelect/LanguageSelect";

export default function AppPublicHeader() {
  const { t } = useContext(LocalizationContext);
  const influencerData = useSelector((state) => state.influencer.entities);
  const { id, handle } = influencerData || {};

  const [publicRoutes, setPublicRoutes] = useState([
    { path: PATHS.SIGN_UP, isMobile: true, isDesktop: false },
    { path: PATHS.LOGIN, isMobile: true, isDesktop: false },
  ]);

  const pages = [
    {
      link: PATHS.HOME,
      name: t("home.home"),
    },
    {
      link: PATHS.DISCOVER,
      name: t("home.discover"),
    },
    {
      link: PATHS.DROPS,
      name: t("home.marketplace"),
    },
    {
      link: "/",
      name: t("home.referrals"),
    },
  ];

  const isMobile = useIsMobile();
  // const dispatch = useDispatch();
  let location = useLocation();

  const isPublicHeaderPath = publicRoutes.some(
    (item) =>
      location.pathname.includes(item.path) &&
      ((isMobile && item.isMobile) || (!isMobile && item.isDesktop))
  );

  // const handleOpenMobileMenu = () => {
  //   document.body.classList.add("mobile-menu-open");
  //   dispatch(toggleMobileMenu());
  // };

  useLayoutEffect(() => {
    const influencerProfileLink = getInfluencerProfileLink({
      id,
      handle,
    });
    setPublicRoutes((prevState) => [
      ...prevState,
      { path: influencerProfileLink, isMobile: true, isDesktop: true },
    ]);
  }, [handle, id]);

  if (isAuthenticated() || !isPublicHeaderPath) {
    return <></>;
  }

  return (
    <Container className={styles.container}>
      <Box sx={{ flexGrow: 1 }} className={styles.header}>
        <AppBar
          position="static"
          color="dark"
          elevation={0}
          className={styles.headerAppBar}
        >
          <Toolbar className={styles.headerToolbar}>
            <Link
              to="/"
              className={`${commonStyles.logo} ${betaStyle.labelContainer}`}
            >
              <Logo />
            </Link>
            {isMobile ? (
              // <LanguageSelect className={styles.langSelect} />
              <></>
            ) : (
              // <IconButton
              //   onClick={handleOpenMobileMenu}
              //   className={styles.hamburgerMenu}
              // >
              //   <Box className={styles.line} />
              //   <Box className={styles.line} />
              //   <Box className={styles.line} />
              // </IconButton>
              <>
                <Box sx={{ flexGrow: 1 }} />
                <Box
                  sx={{
                    display: isMobile ? "none" : "flex",
                    alignItems: "center",
                  }}
                >
                  <div className={commonStyles.menu}>
                    {pages.map((page) => (
                      <ActiveLink
                        key={page.link}
                        to={page.link}
                        className={commonStyles.menuItem}
                        activeClass={commonStyles.menuItemActive}
                      >
                        <span>{page.name}</span>
                      </ActiveLink>
                    ))}
                  </div>
                  <Button
                    component={Link}
                    to={PATHS.SIGN_UP}
                    variant="outlined"
                    size="xsmall"
                    className={styles.btn}
                  >
                    {t("auth.signUp")}
                  </Button>
                </Box>
              </>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </Container>
  );
}
