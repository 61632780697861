export const breakpoints = {
  values: {
    // index.breakpoints.down("lg") for example returns 1260, that's why we need +1 here
    xs: 481,
    sm: 769,
    editionMobile: 841,
    md: 1025,
    lg_small: 1141,
    lg: 1261,
    xl: 1441,
  },
};
