import { breakpoints } from "../breakpoints";
import { typography } from "../typography";
import { components } from "./components";
import { paletteDark } from "./paletteDark";

const themeDark = {
  cssVariables: {
    colorSchemeSelector: "class",
    cssVarPrefix: "",
  },
  typography: typography({ color: paletteDark.mainTextColor }),
  breakpoints,
  components,
  palette: paletteDark,
};

export default themeDark;
