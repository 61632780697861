export const palette = {
  transparent: "transparent",
  "secondary-color": "#ffffff",
  "secondary-color-op10": "rgba(255, 255, 255, 0.1)",
  "secondary-color-op20": "rgba(255, 255, 255, 0.2)",
  "secondary-color-op40": "rgba(255, 255, 255, 0.4)",
  "secondary-color-op50": "rgba(255, 255, 255, 0.5)",
  "secondary-color-op70": "rgba(255, 255, 255, 0.7)",
  "error-color": "#e65447",
  "warning-color": "#f57c00",
  "error-block-bg": "rgba(2, 2, 2, 0.15)",
  "error-block-border": "rgba(238, 82, 97, 0.5)",
  "dark-main": "#111322",
  "dark-surface": "#21264e",
  "dark-surface-op90": "rgba(33, 38, 78, 0.9)",
  "dark-surface-op50": "rgba(33, 38, 78, 0.5)",
  "dark-surface-op35": "rgba(33, 38, 78, 0.35)",
  "dark-surface-op20": "rgba(33, 38, 78, 0.20)",
  "dark-accent": "#70e8fc",
  "dark-light-grey": "#f0f1f5",
  "dark-light-grey-op10": "rgba(240, 241, 245, 0.1)",
  "dark-light-grey-op20": "rgba(240, 241, 245, 0.2)",
  "dark-lightest-grey": "#fefefe",
  "dark-coral": "#ee5261",
  "border-color-destructive": "rgba(238, 82, 97, 0.35)",
  "dark-coral2": "#b7222c",
  "danger-btn-hover": "#c63e4b",
  "dark-green": "#27e7c4",
  "dark-green2": "#047b54",
  "base-deep-blue": "#181C38",
  "border-color": "rgba(240, 241, 245, 0.1)",
  "border-color2": "rgba(240, 241, 245, 0.2)",
  "border-color4": "rgba(240, 241, 245, 0.5)",
  "border-color3": "rgba(80, 90, 169, 0.35)",
  "border-color3-2": "rgba(80, 90, 169, 0.2)",
  "border-color3-3": "rgba(171, 179, 243, 0.35)",
  "base-dropdown-color": "#343B72",
  "base-gray": "#9293A6",
  "base-soft-grey": "#b8b9cc",
  "dark-accent200": "#00d3f3",
  "dark-kon": "#191d38",
  "border-color05": "rgba(80, 90, 169, 0.5)",
  "dark-purple": "#abb3f3",
  "dark-purple2": "#505aa9",
  "dark-violet": "#3d4bb5",
  "dark-main-op40": "rgba(17, 19, 34, 0.4)",
  "dark-main-op80": "rgba(17, 19, 34, 0.8)",
  "card-gradient":
    "linear-gradient(188.77deg,rgba(17, 19, 34, 0) 69.93%, rgba(17, 19, 34, 0.48) 92.55%)",
  "skeleton-bg":
    "linear-gradient(47.07deg, rgba(105, 121, 248, 0.25) -0.96%, rgba(171, 179, 243, 0.7) 95.16%)",
  "dark-surface-hover": "#191d3c",
  "dark-gray": "#545661",
  "dropdown-blue": "rgba(52, 59, 114, 1)",
  "dropdown-blue_05": "rgba(52, 59, 114, 0.5)",
  "secondary-text-light": "#ced7dd",
  "disabled-block-bg": "#15172b",
  "disabled-block-border": "rgba(80, 90, 169, 0.3)",
  "bg-025": "rgba(80, 90, 169, 0.25)",
  "bg-01": "rgba(80, 90, 169, 0.1)",
  "surface-border": "rgba(24, 28, 56, 0.5)",
  surface: { main: "#21264E", border: "gba(80, 90, 169, 0.5)" },
  lihtGrey: { main: "#F0F1F5", opacity20: "rgba(240, 241, 245, 0.2)" },
  accent: {
    main: "#70E8FC",
    secondary: "#00D3F3",
    third: "rgba(112, 232, 252, 0.25)",
  },
  coral: { main: "#EE5261", hover: "#c63e4b" },
  tertiary: { main: "#27E7C4", progressBg: "#737696" },
  primary: {
    main: "#4A3FCB",
  },
  secondary: {
    main: "#FFFFFF",
  },
  error: {
    main: "#E65447",
  },
  lime: {
    main: "#f1fc70",
    progressBg: "#737696",
    secondary: "#DAE41F",
  },
  purple: {
    main: "#ABB3F3",
    secondary: "#505aa9",
  },
  borderColor: {
    main: "rgba(240, 241, 245, 0.1)",
    progress: "rgba(255, 255, 255, 0.2)",
  },
};
