import { Box, CircularProgress } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Card } from "components/NewCard";
import { CARD_VARIANTS } from "constants/card";
import { PATHS } from "constants/index";
import { SCROLL_THRESHOLD } from "constants/index";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import styles from "./LimitedEdition.module.scss";

export const LimitedEdition = ({ cards, loadMore, hasMore }) => {
  const isSm = useMediaQuery("(max-width:460px)");

  return (
    <>
      <Box className={styles.box}>
        <InfiniteScroll
          dataLength={cards.length}
          next={loadMore}
          hasMore={hasMore}
          loader={
            <Box className={styles.loader}>
              <CircularProgress size={24} />
            </Box>
          }
          scrollThreshold={SCROLL_THRESHOLD}
          scrollableTarget="scrollableEl"
        >
          <Box className={styles.wrapper}>
            {cards.map((item) => {
              return (
                <Card
                  data={{
                    ...item,
                    description: null,
                    dropStartDateFormat: isSm ? "dd MMM" : null,
                  }}
                  variant={CARD_VARIANTS.SMALL}
                  key={item.id}
                  overrideStyles={{ root: styles.card, img: styles.img }}
                  isImg={true}
                  link={item?.id ? PATHS.CARD_ID.replace(":id", item.id) : null}
                />
              );
            })}
          </Box>
        </InfiniteScroll>
      </Box>
    </>
  );
};
