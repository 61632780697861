import { breakpoints } from "../breakpoints";
import { typography } from "../typography";
import { components } from "./components";
import { paletteLight } from "./paletteLight";

const themeLight = {
  cssVariables: {
    colorSchemeSelector: "class",
    cssVarPrefix: "",
  },
  typography: typography({ color: paletteLight.mainTextColor }),
  breakpoints,
  components,
  palette: paletteLight,
};

export default themeLight;
