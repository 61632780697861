import { Box, FormControlLabel, Typography } from "@mui/material";
import Switch from "components/Switch";
import Title from "components/Title";
import { PATHS } from "constants/";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "redux/appSlice";
import {
  fetchEmailNotificationSettings,
  updateEmailNotificationSettings,
} from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";

import styles from "./Settings.module.scss";

const EmailNotificationsSettings = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const { entities: defaultSettings, loading } = useSelector(
    (state) => state.users.emailNotifications
  );
  const [settings, setSettings] = useState(null);

  const onSettingsItemChanged = (val, name) => {
    let params = {
      ...settings,
      [name]: val,
    };
    setSettings(params);
    dispatch(updateEmailNotificationSettings(params));
  };

  useEffect(() => {
    dispatch(fetchEmailNotificationSettings());
  }, []);

  useEffect(() => {
    if (loading) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
  }, [loading]);

  useEffect(() => {
    setSettings(defaultSettings);
  }, [defaultSettings]);
  //TODO::those without onChange handler are not implemented on the BE
  return !loading ? (
    <Box className={styles.tabContainer}>
      <Title
        title={t("settings.emailNotifications")}
        backLink={PATHS.SETTINGS}
      />
      <Section title={t("settings.emailNotificationsTitleSubscriptions")}>
        <SettingItem
          label={t("settings.emailNotificationsLabelPosts")}
          description={t("settings.emailNotificationsDescPost")}
          name="fansOnNewPost"
          value={settings?.fansOnNewPost}
          onChange={(val) => onSettingsItemChanged(val, "fansOnNewPost")}
        />
        <SettingItem
          label={t("settings.emailNotificationsLabelSubscription")}
          description={t("settings.emailNotificationsDescSub")}
          name="fansOnSubscriptionPriceChange"
          value={settings?.fansOnSubscriptionPriceChange}
          onChange={(val) =>
            onSettingsItemChanged(val, "fansOnSubscriptionPriceChange")
          }
        />
        <SettingItem
          label={t("settings.emailNotificationsLabelReplies")}
          description={t("settings.emailNotificationsDescReply")}
          name="fanOnCommentReply"
          value={settings?.onSubscriptionPriceChange}
        />
      </Section>

      <Section title={t("settings.emailNotificationsTitleActivity")}>
        <SettingItem
          label={t("settings.emailNotificationsLabelComments")}
          name="fanCommentsOnYourPosts"
          value={settings?.commentsOnYourPosts}
        />
      </Section>

      <Section title={t("settings.emailNotificationsTitleFriends")}>
        <SettingItem
          label={t("settings.emailNotificationsLabelFriend")}
          description={t("settings.emailNotificationsDescFriend")}
          name="fanFriendRequest"
          value={settings?.friendRequest}
        />
        <SettingItem
          label={t("settings.emailNotificationsLableFriendAccepted")}
          description={t("settings.emailNotificationsDescFriendAccepted")}
          name="fanFriendRequestAccepted"
          value={settings?.friendRequestAccepted}
        />
      </Section>

      <Section title={t("settings.emailNotificationsTitleMessages")}>
        <SettingItem
          label={t("settings.emailNotificationsLabelInbox")}
          description={t("settings.emailNotificationsDescInbox")}
          name="fanInboxMessages"
          value={settings?.inboxMessages}
        />
        <SettingItem
          label={t("settings.emailNotificationsLabelMessage")}
          description={t("settings.emailNotificationsDescMessage")}
          name="fanMessageRequests"
          value={settings?.messageRequests}
        />
      </Section>

      <Section title={t("settings.emailNotificationsTitleOthers")}>
        <SettingItem
          label={t("settings.emailNotificationsLabelChallenges")}
          name="challenges"
          value={settings?.challenges}
        />
        <SettingItem
          label={t("settings.emailNotificationsLabelBarter")}
          name="barter"
          value={settings?.barter}
        />
        <SettingItem
          label={t("settings.emailNotificationsLabelReferrals")}
          name="referrals"
          value={settings?.barter}
        />
        <SettingItem
          label={t("settings.emailNotificationsLabelTransactions")}
          name="barter"
          value={settings?.barter}
        />
        <SettingItem
          label={t("settings.emailNotificationsLabelPayments")}
          name="payments"
          value={settings?.barter}
        />
        <SettingItem
          label={t("settings.emailNotificationsLabelCard")}
          name="cardSales"
          value={settings?.barter}
        />
      </Section>

      <Section title={t("settings.emailNotificationsNews")}>
        <SettingItem
          label={t("settings.emailNotificationsLabelProduct")}
          description={t("settings.emailNotificationsDescNews")}
          name="productUpdates"
          value={settings?.productUpdates}
        />
      </Section>
    </Box>
  ) : null;
};

const Section = ({ title, children }) => (
  <Box className={styles.emailNotificationSection}>
    <Typography variant="h6">{title}</Typography>
    {children}
  </Box>
);

const SettingItem = ({ label, description, name, value, onChange }) => {
  const [isChecked, setIsChecked] = React.useState(value);

  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  const disabled = value === undefined;

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  return (
    <Box className={styles.emailNotificationItem}>
      <FormControlLabel
        labelPlacement="start"
        control={
          <Switch
            className={styles.customSwitch}
            checked={isChecked}
            onChange={handleChange}
            name={name}
            color="primary"
            disabled={disabled}
          />
        }
        label={
          <Box>
            <Typography className={styles.emailNotificationItemLabel}>
              {label}
            </Typography>
            {description ? (
              <Typography className={styles.emailNotificationItemDescription}>
                {description}
              </Typography>
            ) : null}
          </Box>
        }
      />
    </Box>
  );
};

export default EmailNotificationsSettings;
