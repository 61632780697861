import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { PATHS } from "constants/index";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";

import styles from "./ShareFooter.module.scss";

export default function ShareFooter({ name }) {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const navigate = useNavigate();

  const redirectTo =
    ({ path }) =>
    () => {
      localStorage.setItem("from", window.location.pathname);
      navigate(path);
    };

  // className="meta-footer" to remove this element for meta image generation on be
  return (
    <Box className={`${styles.footer} meta-footer`}>
      <Box className={styles.textBlock}>
        <Typography variant="h4" className={styles.textTitle}>
          {t("auth.shareFooterTitle", { name })}
        </Typography>
        <Typography className={styles.textContent}>
          {t("auth.shareFooterContent", { name })}
        </Typography>
      </Box>
      <Box className={styles.loginBlock}>
        <LoadingButton
          onClick={redirectTo({ path: PATHS.SIGN_UP })}
          variant="contained"
          className={styles.submit}
        >
          {t("auth.startConnectingOnZoop")}
        </LoadingButton>
        <Box className={styles.account}>
          <Typography
            sx={{ color: theme.palette.contrastText }}
            variant="body2"
            className={styles.accountText}
          >
            {t("auth.haveAccount")}{" "}
            <Button
              className={styles.authTermsLink}
              onClick={redirectTo({ path: PATHS.LOGIN })}
            >
              {t("auth.logIn")}
            </Button>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
