import { Box, Typography } from "@mui/material";
import classNames from "classnames/bind";
import { EDITION_STATUSES } from "constants/index";
import { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import { getRewardsActionType } from "utils";

import CustomTooltip from "../../Tooltip";
import { NotReleased } from "../components/NotReleased";
import { Price } from "../components/Price/Price";
import { UpcomingDrop } from "../components/UpcomingDrop";
import styles from "./SmallCard.module.scss";

const CONTENT = {
  [EDITION_STATUSES.phase0]: {
    ContentCmpnt: UpcomingDrop,
  },
  [EDITION_STATUSES.phase2]: {
    ContentCmpnt: Price,
    showAllocation: true,
    showHoldings: true,
  },
  [EDITION_STATUSES.complete]: {
    ContentCmpnt: Price,
    showHoldings: true,
  },
  [EDITION_STATUSES.notReleased]: {
    ContentCmpnt: NotReleased,
  },
  defaultContent: {
    ContentCmpnt: Box,
  },
};

export const SmallCard = ({ className, data }) => {
  const cx = classNames.bind(styles);
  const {
    status,
    title,
    name,
    description,
    dropStartDateFormat,
    type,
    rewardType,
  } = data;
  const { ContentCmpnt } = CONTENT[status] || CONTENT.defaultContent;

  const { t } = useContext(LocalizationContext);
  return (
    <Box className={cx(className, styles.card)}>
      <Box className={`${styles.cardContent} global-small-card-content`}>
        <ContentCmpnt
          {...data}
          className={styles.cardContentBlock}
          overrideStyles={styles}
          dropStartDateFormat={dropStartDateFormat}
        />
        <Box className={styles.cardContentBlock}>
          <Typography className={styles.priceTitle} variant="textNormal_14">
            {t("discover.type")}
          </Typography>
          {type === "custom" ? (
            <Box className={styles.rewardType}>
              <Typography variant="body3_grotesk_medium">
                {rewardType
                  ? getRewardsActionType({ type: rewardType, t })
                  : t("default.limitedEdition")}
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
      {title || name || description ? (
        <Box className={styles.cardFooter}>
          {title || name ? (
            <CustomTooltip title={title || name}>
              <Typography variant="body1" component="span" noWrap>
                {title || name}
              </Typography>
            </CustomTooltip>
          ) : null}
          {description ? (
            <CustomTooltip title={description}>
              <Typography
                variant="body2"
                component="span"
                noWrap
                className={styles.cardFooterDescription}
              >
                {description}
              </Typography>
            </CustomTooltip>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};
