import { Box, Typography } from "@mui/material";
import classNames from "classnames/bind";
import { InfluencerLink } from "components/InfluencerLink/InfluencerLink";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { isDropFinished } from "redux/dropsSlice";
import { LocalizationContext } from "services/localizationContext";

import styles from "./DescriptionCardHeader.module.scss";

export const DescriptionCardHeader = ({
  title,
  seasonNum, //for title, in case if there is a season
  influencer,
  className,
  isMobile,
}) => {
  const { t } = useContext(LocalizationContext);
  const cx = classNames.bind(styles);
  const cardSeasonTitle = `${t("drops.seasonTitleAbbr", {
    curdNumber: 1,
    seasonNumber: seasonNum,
  })}: ${title}`;

  const isFinished = useSelector((state) => isDropFinished(state));
  const StatusBlock = () => (
    <Box className={styles.statuses}>
      {isFinished ? (
        <Box className={cx(styles.labelText, styles.labelTextDarkBg)}>
          {t("edition.soldOut")}
        </Box>
      ) : null}
    </Box>
  );
  return (
    <Box className={cx(className, { [styles.isMobile]: isMobile })}>
      <Box className={styles.header}>
        <Typography variant="h2_32" component="h2">
          {seasonNum ? cardSeasonTitle : title}
        </Typography>
        {!isMobile ? <StatusBlock /> : null}
        <Box className={styles.creator}>
          <InfluencerLink influencer={influencer} />
          {isMobile ? <StatusBlock /> : null}
        </Box>
      </Box>
    </Box>
  );
};
