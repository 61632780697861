import { LANGUAGES_LIST } from "constants/index";

function findDirectMatch(browserLanguage, languages) {
  return languages.find((lang) => lang === browserLanguage);
}

function findPartialMatch(browserLanguage, languages) {
  const bLangParts = browserLanguage.split("-");
  const languageLangParts = languages.map((lang) => lang.split("-"));
  const langPartsMatch = languageLangParts.find(
    (langParts) => langParts[0] === bLangParts[0]
  );
  return langPartsMatch ? langPartsMatch.join("-") : null;
}

function findMatchingLanguage(browserLanguages, languages) {
  for (const bLang of browserLanguages) {
    const directMatch = findDirectMatch(bLang, languages);
    if (directMatch) {
      return directMatch;
    }

    const partialMatch = findPartialMatch(bLang, languages);
    if (partialMatch) {
      return partialMatch;
    }
  }
  return null;
}

export function getUserLocale() {
  const defaultLanguage = LANGUAGES_LIST.english;
  // const browserLanguages = navigator.languages;
  // const languages = Object.values(LANGUAGES_LIST);

  // const foundLanguage = findMatchingLanguage(browserLanguages, languages);
  return defaultLanguage;
  // return foundLanguage || defaultLanguage;
}
