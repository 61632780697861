import { LoadingButton } from "@mui/lab";
// import { ReactComponent as IconAddFriend } from "assets/svg/icon-add-friend.svg";
// import { ReactComponent as IconAddFriendSuccess } from "assets/svg/icon-follow-success.svg";
// import { ReactComponent as IconCancelFollow } from "assets/svg/unfriend-icon.svg";
import { ANALYTICS_EVENTS, PATHS } from "constants/index";
import React, { useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSnackbar } from "redux/appSlice";
import { changeIsFollowing } from "redux/influencerSlice";
import { followInfluencer, unFollowInfluencer } from "redux/usersSlice";
import Analytics from "services/Analytics.service";
import { isAuthenticated } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";

import styles from "./MainInfo.module.scss";

export const FreeButton = ({
  isFollowing,
  influencerData,
  onFollowCallback,
  influencerName,
}) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const followersLoading = useSelector(
    (state) => state.users.followers.loading
  );
  const { id, name } = influencerData || {};
  const [isHover, setIsHover] = useState(false);
  const navigate = useNavigate();

  const handleFollow = () => {
    setIsHover(false);
    if (!isAuthenticated()) {
      navigate(PATHS.SIGN_UP);
    } else {
      if (isFollowing) {
        dispatch(unFollowInfluencer({ influencerId: id })).then(() => {
          dispatch(changeIsFollowing(false));
        });
      } else {
        Analytics.track(ANALYTICS_EVENTS.SUBSCRIBE_CELEBRITY, {
          celebrity: influencerName,
        });
        dispatch(followInfluencer({ influencerId: id })).then(() => {
          onFollowCallback && onFollowCallback();
          dispatch(changeIsFollowing(true));
          dispatch(
            setSnackbar({
              open: true,
              message: t("discover.youNowFollow", {
                name,
              }),
            })
          );
        });
      }
    }
  };

  const isFlippedSideVisible = isHover;
  const variant =
    isFollowing && !isFlippedSideVisible ? "outlined" : "secondary";
  const onHover = () => {
    setIsHover(true);
  };
  const onLeave = () => {
    setIsHover(false);
  };
  const btnText = useMemo(() => {
    if (isFollowing) {
      return isFlippedSideVisible
        ? t("subscription.unfollow")
        : t("subscription.following");
    } else {
      return t("subscription.follow");
    }
  }, [isFollowing, isFlippedSideVisible]);

  return (
    <LoadingButton
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      variant={variant}
      className={`${styles.subscribeBtn}
         ${isFollowing ? styles.subscribeBtnCancel : ""}`}
      onClick={handleFollow}
      loading={isAuthenticated() ? followersLoading : false}
    >
      {btnText}
      {/*{!isFollowing ? (*/}
      {/*  <IconAddFriend className={styles.icon} />*/}
      {/*) : (isFollowing && isHover) || (isFollowing && isMobile) ? (*/}
      {/*  <IconCancelFollow className={styles.icon} />*/}
      {/*) : (*/}
      {/*  <IconAddFriendSuccess className={styles.icon} />*/}
      {/*)}*/}
    </LoadingButton>
  );
};
