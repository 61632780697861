import { Box, Skeleton } from "@mui/material";
import { RewardHeader } from "components/RewardHeader/RewardHeader";
import {
  CHAT_CATEGORIES,
  FULL_COMMENT_TEXT_LIMIT,
  PATHS,
  REWARD_ORDER_STATUS,
  REWARDS_ACTION_TYPE,
} from "constants/index";
import React, { useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { setSnackbar } from "redux/appSlice";
import {
  addRewardMessage,
  fetchLimitedEditionRewardMessages,
  postLimitedEditionRewardMessage,
} from "redux/dropsSlice";
import { setSelectedUser } from "redux/messengerSlice";
import {
  getRewardMessages,
  getRewardMessagesLoader,
} from "redux/selectors/dropsSelectors";
import { LocalizationContext } from "services/localizationContext";

import styles from "./ChatWidget.module.scss";
import { Footer } from "./components/Footer/Footer";
import { MessageItem } from "./components/MessageItem/MessageItem";
import { Scheduled } from "./components/Scheduled/Scheduled";

export const ChatWidget = ({
  holdings,
  unlockModalHandler,
  influencer,
  cardId,
  isStatic,
  title,
  rewardType,
  selectedToken,
  customTitle,
  classWrapper,
  classList,
  marketTokens,
  duration,
}) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reactQuillRef = useRef(null);
  const scrollRefWrapper = useRef(null);

  const loader = useSelector(getRewardMessagesLoader);
  const messages = useSelector(getRewardMessages);

  const goToMessenger = () => {
    const { appUserId, profileImageUrl, handle, name } = influencer || {};
    dispatch(
      setSelectedUser({
        id: appUserId,
        profileImageUrl,
        username: handle || name,
      })
    );
    navigate({
      pathname: PATHS.CHAT_DIRECT.replace(":userId", appUserId),
      search: createSearchParams({
        category: CHAT_CATEGORIES.PREMIUM,
      }).toString(),
    });
  };

  const submit = () => {
    const txtLength = reactQuillRef.current?.value?.length;
    if (txtLength > FULL_COMMENT_TEXT_LIMIT) {
      dispatch(
        setSnackbar({
          open: true,
          message: t("error.maxCharacters"),
          severity: "error",
        })
      );
      return;
    }
    if (
      reactQuillRef.current.editor?.getText().trim()?.length &&
      selectedToken?.rewardOrder?.id
    ) {
      dispatch(
        postLimitedEditionRewardMessage({
          cardId,
          orderId: selectedToken.rewardOrder.id,
          messageContent: reactQuillRef.current.value,
        })
      );
      const value = reactQuillRef.current.value;
      dispatch(
        addRewardMessage({
          id: Date.now(),
          messageContent: value,
          date: Date.now(),
          isIncoming: false,
          rewardOrderId: selectedToken.rewardOrder.id,
        })
      );
      reactQuillRef.current.editor.setText("");
    }
  };

  const lastMessage = messages.at(-1);

  useEffect(() => {
    if (selectedToken?.rewardOrder?.id && cardId) {
      dispatch(
        fetchLimitedEditionRewardMessages({
          cardId,
          limit: 100,
          orderId: selectedToken.rewardOrder.id,
        })
      );
    }
  }, [selectedToken]);

  useEffect(() => {
    if (scrollRefWrapper.current) {
      const scrollElement = scrollRefWrapper.current;
      const isAtBottom =
        scrollElement.scrollTop + scrollElement.scrollHeight >=
        scrollElement.scrollHeight - 80;

      if (isAtBottom) {
        scrollElement.scrollTop = scrollElement.scrollHeight;
      }
    }
  }, [messages.length]);

  return (
    <Box>
      {customTitle ? (
        customTitle
      ) : (
        <RewardHeader
          title={title}
          holdings={holdings}
          unlockModalHandler={unlockModalHandler}
          selectedToken={selectedToken}
          type={rewardType}
          marketTokens={marketTokens}
          cardId={cardId}
        />
      )}
      {selectedToken?.rewardOrder?.id ? (
        <Box
          className={`${styles.wrapper} ${classWrapper ? classWrapper : ""}`}
        >
          {!loader && messages.length ? (
            <Box
              className={`${styles.list} ${classList ? classList : ""} ${
                selectedToken?.rewardOrder?.status ===
                REWARD_ORDER_STATUS.DELIVERED
                  ? "opacity_07"
                  : ""
              }`}
              ref={scrollRefWrapper}
            >
              {messages.map((item) => (
                <MessageItem
                  text={item.messageContent}
                  incoming={item.isIncoming}
                  className={styles.message}
                  key={item.id}
                />
              ))}
            </Box>
          ) : null}
          {loader && !messages.length ? (
            <Box className={`${styles.list} ${classList ? classList : ""}`}>
              <Skeleton
                variant="rectangular"
                className={styles.messageSkeleton}
              />
            </Box>
          ) : null}
          <Scheduled
            isShow={
              !loader &&
              messages.length &&
              rewardType === REWARDS_ACTION_TYPE.VIDEO_CALL
            }
            influencerId={influencer?.id}
            rewardOrderId={selectedToken?.rewardOrder.id}
            duration={duration}
          />
          <Footer
            isStatic={isStatic}
            submit={submit}
            goToMessenger={goToMessenger}
            lastMessage={lastMessage}
            loader={loader}
            name={influencer?.name}
            reactQuillRef={reactQuillRef}
            selectedToken={selectedToken}
          />
        </Box>
      ) : null}
    </Box>
  );
};
