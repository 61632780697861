import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Skeleton,
  Typography,
} from "@mui/material";
import classNames from "classnames/bind";
import Reactions from "components/Feed/Reactions/Reactions";
import BackBtn from "components/Layout/BackBtn/BackBtn";
import Header from "components/Layout/Header/Header";
import ShareFooter from "components/Layout/ShareFooter/ShareFooter";
import { MediaSlider } from "components/MediaSlider/MediaSlider";
import Poll from "components/Poll/Poll";
import { VideoBlock } from "components/VideoBlock/VideoBlock";
import { CHANNELS, EVENTS, POST_STATUS } from "constants/index";
import { PATHS } from "constants/index";
import React, { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { setError } from "redux/appSlice";
import {
  addComment,
  changePostCommentsCount,
  deletePostComment,
  fetchCommentReplies,
  fetchComments,
  fetchMoreComments,
  fetchPost,
  resetComments,
} from "redux/feedSlice";
import { postTranslate } from "services/api/feedApi";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { isAuthenticated } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";
import PusherService from "services/Pusher.service";
import {
  addBodyClass,
  BODY_CLASSES,
  formatPostMessage,
  removeBodyClass,
} from "utils";

import { ReactComponent as TranslationIcon } from "../../assets/svg/translation-icon.svg";
import { AudioBlock } from "../../components/Feed/Comment/AudioBlock/AudioBlock";
import CommentsCount from "../../components/Feed/Comment/CommentsCount";
import { ShareDataBox } from "../../components/ShareDataBox/ShareDataBox";
import { YouTubeVideoBlock } from "../../components/VideoBlock/YouTubeVideoBlock";
import { useBreakpoint } from "../../utils/hooks";
import { handleSharingPostLink } from "../../utils/sharingHelper";
import { PostHeader } from "./components";
import { CommentBox } from "./components/CommentBox/CommentBox";
import { MediaControls } from "./components/MediaControls/MediaControls";
import { PostForm } from "./components/PostForm/PostForm";
import { ShowMoreRepliesButton } from "./components/ShowMoreRepliesButton/ShowMoreRepliesButton";
import { StatusSection } from "./components/StatusSection/StatusSection";
import styles from "./post.module.scss";

const REPLIES_LIMIT = 20;
const COMMENTS_LIMIT = 20;

const Post = () => {
  const { t } = useContext(LocalizationContext);
  const isAuth = isAuthenticated();
  const params = useParams();
  const isMobile = useBreakpoint("lg_small");

  const loading = useSelector((state) => state.feed.post.loading);
  const profile = useSelector((state) => state.users.entities);
  const post = useSelector((state) => state.feed.post.entities);
  const comments = useSelector((state) => state.feed.comments.entities);
  const commentsLoading = useSelector((state) => state.feed.comments.loading);
  const hasMore = useSelector((state) => state.feed.comments.hasMore);

  const [moderationCommentData, setModerationCommentData] = useState(null);
  const [isReply, setIsReply] = useState(false);
  const [selectedReply, setSelectedReply] = useState(null);
  const [activeCommentReplyIds, setActiveCommentReplyIds] = useState([]);
  const [activeReplyId, setActiveReplyId] = useState(null);
  const [loadingReplyIds, setLoadingReplyIds] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);
  const [replyPage, setReplyPage] = useState(1);
  const [isTranslated, setIsTranslated] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [page, setPage] = useState(1);
  const thirdCommentRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useOutletContext() || {};
  const postId = id || params.postId;
  const handle = post?.influencer?.handle;
  const accessGranted = post?.accessGranted;

  const location = useLocation();
  const { imgIdx = 0, backRoute } = location.state || { backRoute: PATHS.HOME };

  const isShortened = post?.postSummary?.length > 1 && isMobile;

  const goBack = () => {
    navigate(backRoute, { replace: true });
  };

  const loadMore = () => setPage((prevState) => prevState + 1);

  useEffect(() => {
    if (
      !post?.id ||
      post?.id !== +postId ||
      (post?.id === +postId && post.isPublic) ||
      post?.poll
    ) {
      dispatch(fetchPost({ postId, isAuth }))
        .unwrap()
        .catch((e) => {
          console.error("fetch post error:", e);
        });
    }
    dispatch(
      fetchComments({
        id: postId,
        params: { limit: COMMENTS_LIMIT, page },
        isAuth,
      })
    )
      .unwrap()
      .catch((err) => {
        console.error("fetch comments error:", err);
        isAuth && navigate("/discover", { replace: true });
      });
    return () => {
      dispatch(resetComments());
    };
  }, [postId, post?.id]);

  const setLoadingReply = (activeReplyId) => {
    setLoadingReplyIds((prevState) => [...prevState, activeReplyId]);
  };

  const deleteLoadingReply = (activeReplyId) => {
    setLoadingReplyIds((prevState) =>
      prevState.filter((id) => id !== activeReplyId)
    );
  };

  useEffect(() => {
    if (activeReplyId && postId) {
      setLoadingReply(activeReplyId);
      dispatch(
        fetchCommentReplies({
          id: postId,
          params: { commentId: activeReplyId, limit: REPLIES_LIMIT, page: 1 },
          isAuth,
        })
      ).finally(() => {
        deleteLoadingReply(activeReplyId);
      });
    }
  }, [activeReplyId, postId]);

  useEffect(() => {
    if (!postId) return;

    const channelPost = PusherService.subscribe(`${CHANNELS.POST}-${postId}`);

    channelPost.bind(EVENTS.COMMENT_CREATED, (data) => {
      dispatch(addComment(data));
      dispatch(changePostCommentsCount(data));
    });
    channelPost.bind(EVENTS.COMMENT_DELETED, (data) => {
      dispatch(deletePostComment(data));
      dispatch(changePostCommentsCount(data));
    });

    return () => {
      PusherService.unsubscribe(`${CHANNELS.POST}-${postId}`);
    };
  }, [postId]);

  useEffect(() => {
    const channel = PusherService.subscribe(CHANNELS.MODERATION);

    channel.bind(
      EVENTS.MODERATION_COMMENT_UPDATED,
      ({ commentId, cleanText }) => {
        setModerationCommentData({ commentId, cleanText });
      }
    );
    addBodyClass(BODY_CLASSES.post);

    return () => {
      PusherService.unsubscribe(CHANNELS.MODERATION);
      removeBodyClass(BODY_CLASSES.post);
    };
  }, []);

  useEffect(() => {
    if (page !== 1) {
      dispatch(
        fetchMoreComments({
          id: postId,
          params: { limit: COMMENTS_LIMIT, page },
          isAuth,
        })
      )
        .unwrap()
        .catch((err) => {
          console.error("Cannot get comments", err);
        });
    }
  }, [page]);

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    goBack();
  };

  const getRepliesHandler = ({ replyPage }) => {
    setLoadingReply(selectedReply.id);
    dispatch(
      fetchCommentReplies({
        id: postId,
        params: {
          limit: REPLIES_LIMIT,
          page: replyPage,
          commentId: selectedReply.id,
        },
        isAuth,
      })
    ).finally(() => deleteLoadingReply(selectedReply.id));
  };

  const backToOriginal = () => {
    setCurrentMessage(post.message);
    setIsTranslated(false);
  };

  const translationHandler = () => {
    setLoadingMessage(true);
    postTranslate({
      type: "post",
      id: +postId,
      destinationLanguage: profile?.language || navigator.language,
    })
      .then((data) => {
        setIsTranslated(true);
        setCurrentMessage(data.message);
      })
      .catch((err) => {
        if (err?.status !== 401) {
          dispatch(
            setError({
              open: true,
              subtitle: errorResponseMessages(err.data || err, t),
            })
          );
        }
        console.log(err?.data || err);
      })
      .finally(() => setLoadingMessage(false));
  };

  useEffect(() => {
    setCurrentMessage(post?.message);
  }, [post?.message]);

  useEffect(() => {
    if (replyPage > 1) {
      getRepliesHandler({ replyPage });
    }
  }, [replyPage]);

  const handleReply = (comment, isActive = true) => {
    if (!isAuth) {
      navigate(PATHS.SIGN_UP);
      return;
    } else if (!accessGranted) {
      post?.influencer?.handle && navigate(`/${post?.influencer?.handle}`);
      return;
    }
    setIsReply(isActive);
    setSelectedReply(comment);
  };

  const cx = classNames.bind(styles);
  const { images, videos } = post || {};
  const hasImages = images?.length > 0;
  const hasVideo = videos?.length > 0;
  const hasMedia = hasImages || hasVideo || post?.data?.youtubeUrl;

  const question = post?.poll?.question;

  const renderComments = ({
    comments,
    isNested = false,
    parentId,
    parentRepliesCount,
    moderationCommentData,
  }) => {
    return (
      <Box
        className={`${styles.comments} ${
          isNested ? styles.commentsNested : null
        }`}
      >
        {comments.map((comment, index) => {
          const additionalProps = index === 2 ? { ref: thirdCommentRef } : {};
          return (
            <CommentBox
              renderComments={renderComments}
              isNested={isNested}
              comment={comment}
              handleReply={handleReply}
              loadingReplyIds={loadingReplyIds}
              parentId={parentId}
              activeCommentReplyIds={activeCommentReplyIds}
              setActiveCommentReplyIds={setActiveCommentReplyIds}
              setActiveReplyId={setActiveReplyId}
              parentRepliesCount={parentRepliesCount}
              key={comment?.id}
              moderationCommentData={moderationCommentData}
              accessGranted={accessGranted}
              {...additionalProps}
            />
          );
        })}
        <ShowMoreRepliesButton
          isNested={isNested}
          parentRepliesCount={parentRepliesCount}
          commentsLengts={comments.length}
          loadingReplyIds={loadingReplyIds}
          selectedReply={selectedReply}
          setReplyPage={setReplyPage}
        />
      </Box>
    );
  };
  const switchFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  const Message = () =>
    currentMessage ? (
      <Box className={styles.messageWrapper}>
        {loadingMessage ? (
          <CircularProgress className={styles.messageLoader} size={34} />
        ) : null}
        {question ? (
          <Typography
            variant="body4_groteskMedium"
            className={styles.pollQuestion}
          >
            {question}
          </Typography>
        ) : null}
        <Typography
          variant="subtitle1"
          className={`${styles.postMessageText} ${styles.postMessageBlured} ql-editor`}
        >
          {formatPostMessage(currentMessage)}
        </Typography>
        {!isTranslated ? (
          <Button
            variant="text"
            className={styles.messageActionBtn}
            onClick={translationHandler}
          >
            <TranslationIcon />
            <Typography valiant="body6">
              {t("discover.seeTranslation")}
            </Typography>
          </Button>
        ) : (
          <Button
            variant="text"
            className={styles.messageActionBtn}
            onClick={backToOriginal}
          >
            <TranslationIcon />
            <Typography valiant="body6">{t("discover.seeOriginal")}</Typography>
          </Button>
        )}
      </Box>
    ) : null;

  const isInViewport = (elem, footerHeight = 250) => {
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) -
          footerHeight &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const onScroll = () => {
    if (
      !isAuth &&
      thirdCommentRef.current &&
      isInViewport(thirdCommentRef.current)
    ) {
      navigate(PATHS.SIGN_UP);
    }
  };

  const loadingAllSkeletons = !post || (loading && !post.id);

  return (
    <Drawer
      anchor={"right"}
      open={true}
      onClose={toggleDrawer}
      hideBackdrop
      classes={{
        root: cx(
          styles.drawer,
          fullScreen && styles.drawerImageFullScreen,
          hasMedia && !fullScreen && styles.drawerMedia,
          hasImages && !fullScreen && styles.drawerImages
        ),
        paper: cx(
          styles.container,
          fullScreen && styles.containerFullScreen,
          post?.allowComments && styles.hasBottom
        ),
      }}
      className={!accessGranted ? styles.noAccess : ""}
    >
      {(!hasMedia || (hasMedia && isMobile && !fullScreen)) && !loading ? (
        <Header
          backBtn={<BackBtn link={backRoute} onClick={goBack} />}
          headerClass={styles.header}
        />
      ) : null}
      <Box className={styles.content} id="scrollableDiv">
        {loadingAllSkeletons ? (
          <Box className={styles.postWrapper}>
            <Skeleton className={cx(styles.post, styles.postSkeleton)} />
            <Box className={styles.comments}>
              {Array.from(new Array(2)).map((_item, i) => (
                <Skeleton key={i} className={styles.commentSkeleton} />
              ))}
            </Box>
          </Box>
        ) : (
          <>
            {hasMedia && (
              <>
                <MediaControls
                  onClose={!isMobile ? goBack : null}
                  showFullScreenBtn={hasImages}
                  isFullScreen={fullScreen}
                  onSwicthFullScreen={switchFullScreen}
                  isShare={hasMedia}
                />
                <Box className={styles.galleryWrapper}>
                  {hasImages && (
                    <MediaSlider
                      data={post?.images}
                      isFullScreen={fullScreen}
                      onSwicthFullScreen={switchFullScreen}
                      overrideStyles={{
                        sliderItem: styles.sliderItem,
                        slider: styles.slider,
                      }}
                      alt={`By ${post?.influencer?.name}`}
                      index={imgIdx}
                      accessGranted={accessGranted}
                      isDots={true}
                      isFocus={true}
                      overrideSettings={{
                        centerMode: post?.images.length > 1,
                        centerPadding: "17%",
                        responsive: [
                          {
                            breakpoint: 1024,
                            settings: {
                              centerPadding: "10%",
                            },
                          },
                          {
                            breakpoint: 768,
                            settings: {
                              centerPadding: 0,
                            },
                          },
                        ],
                      }}
                    />
                  )}
                  {hasVideo && (
                    <VideoBlock
                      videos={post?.videos}
                      overrideStyles={{
                        videoWrapper: styles.videoWrapper,
                        bluredVideo: !accessGranted ? styles.bluredVideo : {},
                      }}
                      postIndex="-1"
                      mediaIndex={0}
                    />
                  )}
                  {post?.data?.youtubeUrl ? (
                    <YouTubeVideoBlock
                      className={`${styles.youtubeVideo} ${
                        styles.videoWrapper
                      } ${!accessGranted ? styles.bluredVideo : ""}`}
                      youtubeUrl={post.data.youtubeUrl}
                      parentIndex="-2"
                    />
                  ) : null}
                </Box>
              </>
            )}
            <InfiniteScroll
              next={loadMore}
              dataLength={comments?.length}
              hasMore={hasMore}
              scrollableTarget="scrollableDiv"
              className={styles.postContent}
              onScroll={onScroll}
            >
              <Box className={styles.postWrapper}>
                <Box className={styles.post}>
                  <PostHeader
                    post={post}
                    language={profile?.language}
                    id={postId}
                  />
                  <Message />
                  {post?.poll ? (
                    <Box className={styles.pollWrapper}>
                      {loading ? (
                        <CircularProgress className={styles.pollLoader} />
                      ) : null}
                      <Poll post={post} accessGranted={accessGranted} />
                    </Box>
                  ) : null}
                  <Box className={styles.audioWrapper}>
                    <AudioBlock
                      audios={post?.audios}
                      accessGranted={accessGranted}
                      postIndex={0}
                      handle={post?.influencer?.handle}
                    />
                  </Box>
                  {post?.status === POST_STATUS.PUBLISHED ? (
                    <Box
                      className={`${styles.postsBottomFooter} ${
                        !isAuth ? styles.postBottomFooterNoAuth : ""
                      }`}
                    >
                      <Reactions
                        postId={postId}
                        userReaction={post?.appUserReactions}
                        postSummary={post?.postSummary}
                        accessGranted={accessGranted}
                        post={post}
                      />
                      <Box className={styles.postsBottomFooterRight}>
                        <ShareDataBox
                          isText={!isShortened}
                          url={handleSharingPostLink({
                            url: window.location.href,
                            postId,
                            handle,
                          })}
                        />
                        <CommentsCount
                          allowComments={post?.allowComments}
                          count={post?.commentsCount}
                          isShortened={isShortened}
                          wrapperClass={styles.postCommentsCount}
                        />
                      </Box>
                    </Box>
                  ) : null}
                </Box>
                <StatusSection
                  status={post?.status}
                  reason={post?.data?.rejectReason}
                />
                {commentsLoading && !comments.length ? (
                  <Box className={styles.comments}>
                    <Skeleton
                      variant="rectangular"
                      className={styles.commentSkeleton}
                    />
                    <Skeleton
                      variant="rectangular"
                      className={styles.commentSkeleton}
                    />
                  </Box>
                ) : null}
                {!!comments.length &&
                  renderComments({
                    comments,
                    moderationCommentData,
                  })}
              </Box>
            </InfiniteScroll>
          </>
        )}
      </Box>
      {post && post?.allowComments && post?.status === POST_STATUS.PUBLISHED ? (
        <PostForm
          isReply={isReply}
          loading={loading}
          selectedReply={selectedReply}
          setIsReply={setIsReply}
          setLoadingReply={setLoadingReply}
          deleteLoadingReply={deleteLoadingReply}
          postId={postId}
          isSubscriptionReady={post?.influencer?.isSubscriptionReady}
          isDonationEnabled={post?.influencer?.isDonationEnabled}
          influencerName={post?.influencer.name}
          accessGranted={accessGranted}
        />
      ) : null}
      {!isAuth ? <ShareFooter name={post?.influencer?.name} /> : null}
    </Drawer>
  );
};
export default Post;
