import React from "react";

import { POST_STATUS } from "../constants";

const REJECTED_REASON = {
  spam: "spam",
  bullying_or_harassment: "bullying_or_harassment",
  scams_and_fraud: "scams_and_fraud",
  hate_speech: "hate_speech",
};

export const getStatus = ({
  value,
  t,
  theme,
  reason,
  getDynamicUrl,
  callSupport,
}) => {
  const getRejectDescriptions = (reason) => {
    if (reason === REJECTED_REASON.spam) {
      return {
        description: t("default.rejectTitleSpam"),
        value: t(`default.spam`),
        bottomText: (
          <>
            {t("default.rejectStatusTextBeforeTerms")}{" "}
            <a
              href={getDynamicUrl("terms-of-service")}
              target="_blank"
              rel="noreferrer"
            >
              {t("default.zoopTermsAndConditions")}
            </a>{" "}
            {t("default.rejectStatusTextAfterTerms")}{" "}
            {callSupport ? (
              <a href="#" onClick={callSupport}>
                {t("default.supportTeam")}
              </a>
            ) : null}
          </>
        ),
      };
    }
    if (reason === REJECTED_REASON.bullying_or_harassment) {
      return {
        description: t("default.rejectTitleSpam"),
        value: t(`default.bullyingOrHarassment`),
        bottomText: (
          <>
            {t("default.rejectStatusTextBeforeTerms")}{" "}
            <a
              href={getDynamicUrl("terms-of-service")}
              target="_blank"
              rel="noreferrer"
            >
              {t("default.zoopTermsAndConditions")}
            </a>{" "}
            {t("default.rejectStatusTextAfterTerms")}{" "}
            {callSupport ? (
              <a href="#" onClick={callSupport}>
                {t("default.supportTeam")}
              </a>
            ) : null}
          </>
        ),
      };
    }
    if (reason === REJECTED_REASON.scams_and_fraud) {
      return {
        description: t("default.rejectTitleSpam"),
        value: t(`default.scamsAndFraud`),
        bottomText: (
          <>
            {t("default.rejectStatusTextBeforeTerms")}{" "}
            <a
              href={getDynamicUrl("terms-of-service")}
              target="_blank"
              rel="noreferrer"
            >
              {t("default.zoopTermsAndConditions")}
            </a>{" "}
            {t("default.rejectStatusTextAfterTerms")}{" "}
            {callSupport ? (
              <a href="#" onClick={callSupport}>
                {t("default.supportTeam")}
              </a>
            ) : null}
          </>
        ),
      };
    }
    if (reason === REJECTED_REASON.hate_speech) {
      return {
        description: t("default.rejectTitleSpam"),
        value: t(`default.hateSpeech`),
        bottomText: (
          <>
            {t("default.rejectStatusTextBeforeTerms")}{" "}
            <a
              href={getDynamicUrl("terms-of-service")}
              target="_blank"
              rel="noreferrer"
            >
              {t("default.zoopTermsAndConditions")}
            </a>{" "}
            {t("default.rejectStatusTextAfterTerms")}{" "}
            {callSupport ? (
              <a href="#" onClick={callSupport}>
                {t("default.supportTeam")}
              </a>
            ) : null}
          </>
        ),
      };
    }
    if (reason) {
      return {
        description: t("default.rejectTitleReason"),
        value: reason,
      };
    }

    return { description: t("default.rejectTitleNoReason") };
  };

  switch (value) {
    case POST_STATUS.PENDING_REVIEW:
      return {
        title: t("default.statusInReview"),
        description: t("default.statusPostInReviewDescription"),
        background: theme.palette.lime.main,
        statusColor: theme.palette.main,
        svgStrokeColor: theme.palette.main,
      };
    case POST_STATUS.REJECTED:
      return {
        title: t("default.statusPostRejected"),
        description: getRejectDescriptions(reason).description,
        value: getRejectDescriptions(reason).value,
        bottomText: getRejectDescriptions(reason).bottomText,
        background: theme.palette.coral.main,
        statusColor: theme.palette.contrastText,
        svgStrokeColor: theme.palette.contrastText,
      };
    default:
      return {
        title: t("default.statusInReview"),
        description: t("default.statusPostInReviewDescription"),
        background: theme.palette.lime.main,
        statusColor: theme.palette.main,
        svgStrokeColor: theme.palette.main,
      };
  }
};
