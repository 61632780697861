import { Box, Button, IconButton, Popover, Typography } from "@mui/material";
import { ReactComponent as Kebab } from "assets/svg/kebab-icon.svg";
import { TRANSACTIONS_STATUSES } from "constants/index";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";

import styles from "./transaction-item.module.scss";

const TransactionItem = ({
  transaction,
  handleCancelTransaction,
  showOptionsBtn,
}) => {
  const { card, currencySymbol, price, status } = transaction;
  const navigate = useNavigate();
  const { t } = useContext(LocalizationContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation(event);
    setAnchorEl(null);
  };

  const cancelTransaction = async (event) => {
    event.stopPropagation();
    handleCancelTransaction();
    handleClose(event);
  };

  const openEditionDetails = () => navigate(`/pass/${card.id}`, card.id);

  return (
    <Box className={styles.container} onClick={openEditionDetails}>
      <Box
        className={styles.content}
        sx={{
          pr: showOptionsBtn ? "64px" : "30px",
        }}
      >
        <img
          className={styles.image}
          src={card.imageUrl}
          alt={card.influencer?.name}
          loading="lazy"
        />
        <Box className={styles.leftTextBlock}>
          <Typography variant="h5" className={styles.title}>
            {card.influencer?.name}
          </Typography>
        </Box>
        <Box className={styles.rightTextBlock}>
          {status === TRANSACTIONS_STATUSES.cancelled ? (
            <Typography
              variant="subtitle1"
              component="span"
              className={styles.cancelled}
            >
              {t("tradehistory.cancelled")}
            </Typography>
          ) : (
            <Box className={styles.priceWrap}>
              <Typography
                variant="subtitle1"
                component="span"
                className={styles.price}
              >
                {currencySymbol || "$"}
                {price}
              </Typography>
              {card.status === "complete" && (
                <Typography
                  variant="body1"
                  component="span"
                  className={styles.currentPrice}
                >
                  {`${t("trading.currentEditionPrice")} ${
                    currencySymbol || "$"
                  }${card.latestPrice}`}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {showOptionsBtn && (
        <>
          <IconButton onClick={handleClick} className={styles.optionsBtn}>
            <Kebab width={24} height={24} />
          </IconButton>
          <Popover
            id={"cancel-transaction-popover"}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            className={styles.cancelBtnPaper}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Button
              onClick={cancelTransaction}
              variant="contained"
              className={styles.cancelBtn}
            >
              {t("trading.cancelTransaction")}
            </Button>
          </Popover>
        </>
      )}
    </Box>
  );
};

export default TransactionItem;
