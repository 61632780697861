import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import bookmark from "assets/bookmark.json";
import AnimatedCardIcon from "components/AnimatedCardIcon";
import { ANIMATION_DIRECTION, EDITION_STATUSES } from "constants/";
import Proptypes from "prop-types";
import React, { useCallback, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addEditionToWatchList,
  removeEditionFromWatchList,
} from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";

import styles from "./edition-card.module.scss";

const InfluencerEditionCard = ({
  id,
  name,
  imageUrl,
  latestPrice,
  priceMovement,
  currencySymbol,
  className,
  isWatchlisted,
  holdings,
  onClick,
  status,
}) => {
  const theme = useTheme();
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [watchListed, setWatchListed] = useState(isWatchlisted);
  const [processing, setProcessing] = useState(false);

  const toggleSaved = useCallback(
    async (e, id) => {
      e.stopPropagation();
      if (processing) {
        return;
      }
      setProcessing(true);
      if (watchListed) {
        await dispatch(removeEditionFromWatchList(id));
        setWatchListed(false);
      } else {
        await dispatch(addEditionToWatchList(id));
        setWatchListed(true);
      }
    },
    [watchListed, processing]
  );

  const onCompleteLoading = () => {
    setProcessing(false);
  };

  function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
  }

  const isCompleted = status === EDITION_STATUSES.complete;
  const isPhase0 = status === EDITION_STATUSES.phase0;
  const isPhase2 = status === EDITION_STATUSES.phase2;

  return (
    <Box
      className={`${styles.container} ${className ? className : ""}`}
      onClick={onClick}
      sx={{ cursor: onClick ? "pointer" : "default !important" }}
    >
      <img className={styles.image} src={imageUrl} alt={name} />
      {isCompleted && (
        <Box className={styles.cardHeader}>
          <Box className={styles.cardHeaderRight}>
            <Typography
              variant="body3"
              component="span"
              className={styles.price}
            >
              {currencySymbol || "$"}
              {latestPrice}
            </Typography>
            {priceMovement !== 0 && (
              <Box className={styles.priceMovement}>
                <Typography
                  variant="body3"
                  component="span"
                  sx={{
                    color:
                      priceMovement > 0
                        ? theme.palette.tertiary.main
                        : theme.palette.coral.main,
                  }}
                  className={styles.priceMovementText}
                >
                  {roundToTwo(priceMovement)}%
                </Typography>
                <Box
                  className={styles.diffTriangle}
                  style={{
                    borderWidth:
                      priceMovement >= 0 ? "0 2px 8px 2px" : "8px 2px 0 2px",
                    borderColor:
                      priceMovement >= 0
                        ? `transparent transparent ${theme.palette.tertiary.main} transparent`
                        : `${theme.palette.coral.main} transparent transparent transparent`,
                  }}
                ></Box>
              </Box>
            )}
          </Box>
          {!holdings && isCompleted && (
            <Box
              className={styles.pinnedIcon}
              onClick={(e) => toggleSaved(e, id)}
            >
              <AnimatedCardIcon
                play={processing}
                handleComplete={onCompleteLoading}
                direction={
                  watchListed
                    ? ANIMATION_DIRECTION.backward
                    : ANIMATION_DIRECTION.forward
                }
                animationData={bookmark}
                className={styles.watchlistIconAnimated}
                changeAnimationDirection
              />
            </Box>
          )}
        </Box>
      )}
      {isPhase0 && (
        <Box className={styles.commingSoonWrap}>
          <Typography className={styles.commingSoonText} variant="body2">
            {t("edition.comingSoon")}
          </Typography>
        </Box>
      )}
      {isPhase2 && (
        <Box className={styles.commingSoonWrap}>
          <Typography className={styles.commingSoonText} variant="body2">
            {t("drops.auctionStarted")}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

InfluencerEditionCard.defaultProps = {
  imageUrl: null,
  currencySymbol: "$",
  latestPrice: 0,
  priceMovement: 0,
  holdings: 0,
  status: null,
};

InfluencerEditionCard.propTypes = {
  imageUrl: Proptypes.string,
  currencySymbol: Proptypes.string,
  latestPrice: Proptypes.number,
  priceMovement: Proptypes.number,
  holdings: Proptypes.number,
  status: Proptypes.string,
};

export default InfluencerEditionCard;
