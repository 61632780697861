import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { EDITION_STATUSES, PATHS } from "constants/index";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isDropFinished } from "redux/dropsSlice";
import { isAuthenticated } from "services/auth.service";
import { switchReminder } from "services/dropsApi";
import { LocalizationContext } from "services/localizationContext";

import BuyBtn from "../../../DropBuy/BuyBtn";
import styles from "./SidebarActions.module.scss";

export const SidebarActions = ({ drop, assetHoldings, handleBuyBtnClick }) => {
  const navigate = useNavigate();
  const { t } = useContext(LocalizationContext);

  const transfers = useSelector((state) => state.users.transfers.entities);
  const isFinished = useSelector((state) => isDropFinished(state));

  const [hasReminder, setReminder] = useState(drop.reminderState || false);
  const [isReminderLoading, seReminderloader] = useState(false);

  const onSwitchReminder = () => {
    if (!isAuthenticated()) {
      navigate(PATHS.SIGN_UP);
      return;
    }
    seReminderloader(true);

    switchReminder({ cardId: drop.id, state: !hasReminder })
      .then(() => setReminder((pevState) => !pevState))
      .finally(() => seReminderloader(false));
  };

  const BuyButton = () =>
    !isFinished && drop?.status === EDITION_STATUSES.phase2 ? (
      <BuyBtn
        card={drop}
        transfers={transfers}
        onClick={handleBuyBtnClick}
        variant="contained"
        className={styles.sidebarButton}
      >
        {t(assetHoldings > 0 ? "drops.buyAgain" : "drops.buyNow")}
      </BuyBtn>
    ) : null;

  const RemindMeButton = () =>
    drop?.status === EDITION_STATUSES.phase0 ? (
      <LoadingButton
        ml={1}
        onClick={onSwitchReminder}
        loading={isReminderLoading}
        variant={hasReminder ? "outlined" : "contained"}
        className={styles.sidebarButton}
      >
        {hasReminder ? t("drops.reminderSet") : t("drops.remindMe")}
      </LoadingButton>
    ) : null;

  if (!isAuthenticated()) {
    return null;
  }
  return (
    <Box>
      <RemindMeButton />
      <Box className={`${assetHoldings > 0 ? styles.multipleActions : ""}`}>
        <BuyButton />
      </Box>
    </Box>
  );
};
