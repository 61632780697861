import { Box, CircularProgress, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import { ReactComponent as AlertIcon } from "assets/svg/alert-icon.svg";
import { ReactComponent as ErrorIcon } from "assets/svg/error-icon.svg";
import { Suspense, useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./CustomErrorModal.module.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => {
  return {
    "& .MuiDialog-paper": {
      background: theme.palette.main,
      borderRadius: "10px",
      maxWidth: "420px",
    },
  };
});
export const CustomErrorModal = ({
  isOpen,
  setOpen,
  isAlertIcon,
  title,
  subtitle,
  children,
  ...rest
}) => {
  const { t } = useContext(LocalizationContext);
  const onClose = () => {
    setOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <BootstrapDialog
      onClose={onClose}
      open={isOpen}
      {...rest}
      classes={{
        paper: `errorDlgPaper`,
        container: `errorDlgContainer`,
        root: "errorDlg",
      }}
    >
      <Box className={styles.container}>
        <Box className={styles.iconWrapper}>
          {isAlertIcon ? <AlertIcon /> : <ErrorIcon />}
        </Box>
        <Box>
          <Typography className={styles.title} variant="h5" component="h5">
            {title || t("error.errorTitle")}
          </Typography>
          <Typography className={styles.subtitle} variant="body1" component="p">
            {subtitle || t("error.unknownError")}
          </Typography>
        </Box>
        <Box className={styles.actions}>
          <Suspense fallback={<CircularProgress />}>{children}</Suspense>
        </Box>
      </Box>
    </BootstrapDialog>
  );
};
