export const typography = ({ color }) => ({
  fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
  /*-------Clash Display-------*/
  textNormal: {
    fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 12,
    fontWeight: 400,
    color,
  },
  clash_display_12_500: {
    fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 12,
    fontWeight: 500,
    color,
  },
  textNormal_14: {
    fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.3,
    color,
  },
  body1Display: {
    fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.25,
    color,
  },
  h6: {
    fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 18,
    fontWeight: 400,
    lineHeight: 1,
    color,
  },
  h5: {
    fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 20,
    fontWeight: 400,
    lineHeight: 1,
    color,
  },
  h5_medium: {
    fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1,
    color,
    "@media (max-width:480px)": {
      fontSize: 16,
    },
  },
  h5_medium_20: {
    fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1,
    color,
  },
  /*-------Clash Display 500-------*/
  textMediumBold: {
    fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 14,
    fontWeight: 500,
    color,
  },
  textMediumBold1: {
    fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 1.2,
    color,
  },
  textMediumBold2: {
    fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.2,
    color,
  },
  body4: {
    fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.1,
    color,
  },
  body6: {
    fontFamily: `"ClashGrotesk-Regular", "Noto Sans JP", "Noto Sans KR", sans-serif`,
    fontSize: 10,
    fontWeight: 400,
    lineHeight: 1.2,
    color,
  },
  body6_medium_dark: {
    fontFamily: `"ClashGrotesk-Medium", "Noto Sans JP", "Noto Sans KR", sans-serif`,
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 1.2,
    color,
  },
  h4: {
    fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 1,
    color,
  },
  h4_24: {
    fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 1,
    color,
  },
  h3: {
    fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 28,
    fontWeight: 500,
    lineHeight: 1.43,
    color,
    "@media (max-width:480)": {
      fontSize: 20,
    },
  },
  h2_32: {
    fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 32,
    fontWeight: 500,
    lineHeight: 1.14,
    color,
  },
  h2: {
    fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 36,
    fontWeight: 500,
    lineHeight: 1.14,
    color,
  },
  h1: {
    fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 54,
    fontWeight: 500,
    lineHeight: 1.22,
    letterSpacing: "-0.01em",
    color,
  },
  lgTitle: {
    fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 80,
    fontWeight: 500,
    lineHeight: "72px",
    color,
    "@media (max-width:1280px)": {
      fontSize: 40,
      lineHeight: "36px",
    },
  },
  clashDisplayMedium40: {
    fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 40,
    fontWeight: 500,
    lineHeight: 1,
    color,
  },
  /*-------ClashGrotesk 400-------*/
  body3: {
    fontFamily: `"ClashGrotesk-Regular", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.33,
    color,
  },
  body2: {
    fontFamily: `"ClashGrotesk-Regular", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.14,
    color,
  },
  body1: {
    fontFamily: `"ClashGrotesk-Regular", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
    color,
  },
  subtitle1: {
    fontFamily: `"ClashGrotesk-Regular", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 18,
    fontWeight: 400,
    lineHeight: 1.33,
    color,
    "@media (max-width:480)": {
      fontSize: 16,
    },
  },
  /*-------ClashGrotesk 500-------*/
  body3_grotesk_medium: {
    fontFamily: `"ClashGrotesk-Medium", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.3,
    color,
  },
  body5: {
    fontFamily: `"ClashGrotesk-Medium", "Noto Sans JP", "Noto Sans KR", sans-serif`,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.2,
    color,
  },
  body4_groteskMedium: {
    fontFamily: `"ClashGrotesk-Medium", "Noto Sans JP", "Noto Sans KR", sans-serif`,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.2,
    color,
  },
});
