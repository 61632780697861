import { palette } from "../palette";

export const paletteLight = {
  ...palette,
  main: "#F5FBFE",
  contrastText: "#FFFFFF",
  placeholder: "rgba(255, 255, 255, 0.5)",
  /*---------------*/
  mainBackground: "#F5FBFE",
  mainTextColor: "#001833",
  containerBorderColor: "#DCEAF9",
  containerBackground: "#F5FBFE",
  header: {
    background: "#F5FAFF",
    boxShadow: "0px 4px 112px 0px rgba(80, 90, 169, 0.10)",
    backdropFilter: "blur(8px)",
    icon: "#4E5774",
  },
  searchBar: {
    button: "#F5FBFE",
    buttonIcon: "#4E5774",
  },
  post: {
    translationIcon: {
      fill: "#4E5774",
    },
    divider: {
      color: "rgba(78,87,116, 0.2)",
    },
    longTextShadow: "linear-gradient(0deg, #F5FBFE, transparent)",
  },
  commentsCount: {
    icon: {
      stroke: "#4E5774",
    },
  },
  share: {
    button: {
      icon: {
        stroke: "#4E5774",
      },
    },
  },
  reactionsInfo: {
    icon: {
      stroke: "#4E5774",
      rectFill: "#fff",
    },
  },
  tab: {
    panel: {
      background:
        "linear-gradient(128deg, rgba(173, 66, 255, 0.10) 0%, rgba(95, 137, 247, 0.10) 100%)",
    },
    button: {
      color: "#4E5774",
    },
    indicator: {
      background: "linear-gradient(128.02deg, #AD42FF 0%, #5F89F7 100%)",
    },
  },
  viewAll: {
    color: "#742cf3",
  },
  customTooltip: {
    background: "linear-gradient(128deg, #AD42FF 0%, #5F89F7 100%)",
    boxShadow: "0 4px 24px 0 rgba(173, 66, 255, 0.20)",
    arrowColor: "#5F89F7",
    color: "#fff",
  },
  backBtn: {
    color: "#4E5774",
  },
  activeText: {
    background: "linear-gradient(128deg, #AD42FF 0%, #5F89F7 100%)",
    backgroundClip: "text",
    textFillColor: "transparent",
  },
  surface: {
    background:
      "linear-gradient(128deg, rgba(173, 66, 255, 0.10) 0%, rgba(95, 137, 247, 0.10) 100%)",
    backgroundDarker:
      "linear-gradient(128deg, rgba(173, 66, 255, 0.10) 0%, rgba(95, 137, 247, 0.10) 100%)",
    border: "rgba(80, 90, 169, 0.35)",
  },
  badge: {
    background: "rgba(80, 90, 169, 0.25)",
    border: "rgba(80, 90, 169, 0.20)",
  },
  text: {
    secondary: "#7681A3",
  },
  profileNav: {
    paper: "white",
    border: "#7681A3",
    icons: "#001833",
    info: {
      icon: "#5F89F7",
    },
    avatar: {
      background: "#7681A3",
    },
  },
  accent: "#AD42FF",
  newCard: {
    background:
      "linear-gradient(128deg, rgba(173, 66, 255, 0.10) 0%, rgba(95, 137, 247, 0.10) 100%)!important",
  },
  fullCard: {
    border: "#9C2CF3",
    button: {
      background:
        "linear-gradient(128deg, rgba(173, 66, 255, 0.80) 0%, rgba(95, 137, 247, 0.80) 100%)",
      color: "#FFF",
    },
  },
  cardProgress: {
    main: "linear-gradient(128deg, #AD42FF 0%, #5F89F7 100%)",
    progressBg: "rgba(115, 118, 150, 0.20)",
  },
  progress: {
    background: "rgba(165, 172, 192, 0.50)",
    color:
      "linear-gradient(128deg, #AD42FF 0%, #5F89F7 100%), linear-gradient(128deg, #9C2CF3 0%, #3A6FF9 100%)",
    textColor: "#4E5774",
  },
  bottomMenu: {
    background: "#F5FAFF",
    boxShadow: "0px -4px 112px 0px rgba(80, 90, 169, 0.10)",
    item: {
      color: "#7882A1",
      colorActive: "#AD42FF",
      svg: {
        path: {
          stroke: "#7882A1",
          strokeActive: "#AD42FF",
        },
      },
      activeIndicator: {
        background: "#AD42FF",
        boxShadow: "0px 2px 4px 1px rgba(72, 141, 196, 0.25)",
      },
    },
  },
  challenge: {
    lightColor: "#001833",
  },
  chat: {
    hoverBackground:
      "linear-gradient(128deg, rgba(173, 66, 255, 0.10) 0%, rgba(95, 137, 247, 0.10) 100%)",
    hoverShadow:
      "linear-gradient(270deg, rgba(173, 66, 255, 0.10) 53.08%, rgba(95, 137, 247, 0) 100%)",
  },
};
