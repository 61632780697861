import { Box } from "@mui/material";
import classNames from "classnames/bind";
import { PLAYING_FEED_VIDEO } from "constants/index";
import { throttle } from "lodash";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveMedia } from "redux/mediaSlice";
import { getMediaParams } from "redux/selectors/mediaSelectors";

import { VideoPlayer } from "../VideoPlayer/VideoPlayer";
import styles from "./VideoBlock.module.scss";

export const VideoBlock = ({
  videos,
  overrideStyles,
  postIndex,
  mediaIndex = 0,
}) => {
  const dispatch = useDispatch();
  const refVideoWrapper = useRef();
  const refVideo = useRef();

  const mediaParams = useSelector(getMediaParams);

  const cx = classNames.bind(styles);

  const videoHandler = () => {
    dispatch(setActiveMedia({ postIndex, mediaIndex }));
    sessionStorage.setItem(PLAYING_FEED_VIDEO, postIndex);
  };

  const setVideoContainerStyles = () => {
    // these styles need to prevent jumping
    if (refVideoWrapper.current?.style) {
      refVideoWrapper.current.style.position = "relative";
      refVideoWrapper.current.style.opacity = 1;
      refVideoWrapper.current.style.zIndex = 1;
    }
  };

  const setVideoParams = (video) => {
    if (video) {
      const space = 170;
      const visibleHeight =
        document.documentElement.getBoundingClientRect().height;
      function setVideoSize() {
        if (video.readyState >= 3) {
          const videoHeight = video.offsetHeight - space; // need some space below the video for the content
          if (videoHeight > visibleHeight) {
            video.style.height =
              visibleHeight > 620 ? `${visibleHeight - space}px` : "470px";
            video.style.width = "auto";
          } else {
            video.style.width = visibleHeight > 620 ? "auto" : "100%";
            video.style.maxHeight =
              visibleHeight > 620 ? `calc(100vh - ${space}px)` : "100%";
          }
          setVideoContainerStyles();
        }
      }
      video.removeEventListener("canplay", setVideoSize);
      video.addEventListener("canplay", setVideoSize);
    }
  };

  const pause = () => {
    refVideo.current.pause();
  };

  useLayoutEffect(() => {
    if (refVideoWrapper.current && refVideo.current) {
      const video = refVideoWrapper.current.getElementsByTagName("video")[0];
      setVideoParams(video);
    }
  }, []);

  useEffect(() => {
    if (
      (postIndex !== mediaParams.postIndex ||
        mediaIndex !== mediaParams.mediaIndex) &&
      refVideo.current
    ) {
      pause();
    }
  }, [mediaIndex, mediaParams.mediaIndex, mediaParams.postIndex, postIndex]);

  const handleScroll = throttle(() => {
    const videoElement = refVideo.current;
    if (!videoElement) return;

    const { top, bottom } = videoElement.getBoundingClientRect();
    const isInViewport =
      top + (bottom - top) * 0.4 >= 0 &&
      top + (bottom - top) * 0.6 <= window.innerHeight;

    const postVideoIndex = sessionStorage.getItem(PLAYING_FEED_VIDEO);

    if (!isInViewport && !videoElement.paused && postVideoIndex) {
      videoElement.pause();
      sessionStorage.removeItem(PLAYING_FEED_VIDEO);
    } else if (isInViewport && videoElement.paused && !postVideoIndex) {
      videoHandler();
      videoElement.play();
    }

    if (+postVideoIndex === +postIndex && !isInViewport) {
      sessionStorage.removeItem(PLAYING_FEED_VIDEO);
    }
  }, 500);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (!videos?.length) {
    return null;
  }

  // className="meta-video" to remove this element for meta image generation on be
  return (
    <Box
      className={cx(
        styles.videoWrapper,
        overrideStyles?.videoWrapper,
        overrideStyles?.bluredVideo,
        "meta-video"
      )}
      ref={refVideoWrapper}
      onClick={videoHandler}
    >
      <VideoPlayer src={`${videos[0]}#t=0.001`} loop muted ref={refVideo} />
    </Box>
  );
};
